import React from 'react'
import { useIntl } from 'react-intl'

import TextTooltip from 'components/Tooltip/TextTooltip'

import {
  ReadButton,
  TextWrapper,
  UnreadIndicator,
  Container,
  ContentContainer,
  ActionsContainer,
} from './styles'
import messages from '../messages'

const NotificationFeedItem = ({
  title,
  innerHtml,
  IconComponent,
  onReadClick,
  isRead,
  onClick,
}) => {
  const { formatMessage } = useIntl()

  return (
    <Container $isRead={isRead} onClick={onClick}>
      <IconComponent />
      <ContentContainer>
        <TextWrapper>
          <h4>{title}</h4>
          <span
            dangerouslySetInnerHTML={{
              __html: innerHtml,
            }}
          />
        </TextWrapper>
        <ActionsContainer>
          {!isRead && (
            <TextTooltip
              placement="left"
              disableArrow
              content={formatMessage(messages.markAsRead)}
              offsetValues={{
                main: 4,
              }}
            >
              <ReadButton onClick={onReadClick} data-test-id="mark-as-read-btn">
                <UnreadIndicator />
              </ReadButton>
            </TextTooltip>
          )}
        </ActionsContainer>
      </ContentContainer>
    </Container>
  )
}

export default NotificationFeedItem
