import { takeLatest, call, put, select, all } from 'redux-saga/effects'
import history from 'utils/history'
import { flow, map, join } from 'lodash/fp'

import { ROUTES } from 'consts'
import { cameliseDeep } from 'utils'

import { purchaseHandler } from 'services/analytics'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { genericGetDataEnhanced, crudSwitch } from 'containers/App/sagas'
import cartActions from 'containers/Cart/actions'
import { allCartItemsSelector } from 'containers/Cart/selectors'
import { hasOutOfStockProduct } from 'containers/Cart/utils'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { trackMissingItemsScreen } from 'services/analytics/missingItems'
import { rtkApi } from 'services/api/rtkApi'
import { getInvoicesSummaryActions } from 'containers/Invoices/actions'
import { ORDERS_LIST_TAG_TYPE } from './List/rtkApi'

import { placeOrder, getTradeLimits } from './api'
import { tradeLimitActions, orderActions } from './actions'
import { orderErrorSelector, orderErrorItemsSelector } from './selectors'
import { ORDER_ERRORS } from './consts'
import orderDetailsWatchers from './Details/sagas'

export function* placeOrderFlow({
  data: body,
  additionalData: { hasInactiveProducts } = {},
} = {}) {
  const { failure, ...restActions } = orderActions
  const { customerNo, storeId } = yield select(userDataSelector)

  try {
    const products = yield select(allCartItemsSelector)
    const response = yield call(genericGetDataEnhanced, {
      actions: restActions,
      request: placeOrder,
    })

    if (!response) return null

    const { orders } = response

    yield call(purchaseHandler, {
      ...body,
      customerNo,
      items: products,
      orderNumber: flow(
        map('orderNumber'),
        join('-'),
      )(orders),
      orders,
    })

    yield all([
      put(cartActions.delta()),
      put(tradeLimitActions.delta()),
      put(getInvoicesSummaryActions.delta()),

      // TODO: invalidate tag in RTK query when place order flow migrated
      put(rtkApi.util.invalidateTags([ORDERS_LIST_TAG_TYPE])),
    ])

    return history.push(ROUTES.ORDER_SUMMARY)
  } catch (error) {
    yield put(failure(cameliseDeep(error.body)))

    const orderError = yield select(orderErrorSelector)
    if (orderError === ORDER_ERRORS.POSITIONS_ERROR) {
      const errorItems = yield select(orderErrorItemsSelector)
      if (hasOutOfStockProduct(errorItems) && hasInactiveProducts) {
        return yield put(failure({ message: ORDER_ERRORS.HAS_OUT_OF_STOCKS })) // show error banner
      }

      trackMissingItemsScreen({ customerNo, storeId })
      return yield history.push(ROUTES.CART_MISSING_ITEMS)
    }

    if (orderError) return null // show error banner

    return yield put(
      openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }),
    )
  }
}

export function* tradeLimitFlow() {
  try {
    yield call(genericGetDataEnhanced, {
      actions: tradeLimitActions,
      request: getTradeLimits,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [
  takeLatest(orderActions.DELTA, crudSwitch, {
    createSaga: placeOrderFlow,
  }),
  takeLatest(tradeLimitActions.DELTA, tradeLimitFlow),
  ...orderDetailsWatchers,
]
