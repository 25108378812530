import { takeLatest, call, put } from 'redux-saga/effects'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { genericGetDataEnhanced, crudSwitch } from 'containers/App/sagas'
import createFormData from 'utils/createFormData'

import { getSettings, updateSettings, sendFeedbackForm } from './api'
import actions, { sendFeedbackFormActions } from './actions'

export function* getSettingsFlow() {
  try {
    return yield call(genericGetDataEnhanced, {
      actions,
      request: getSettings,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    return {}
  }
}

export function* updateSettingsFlow({ data: body = {} } = {}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions,
      request: updateSettings,
      params: { body },
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* sendFeedbackFormFlow({ data, additionalData }) {
  try {
    const formData = yield call(createFormData, { data })

    yield call(genericGetDataEnhanced, {
      actions: sendFeedbackFormActions,
      request: sendFeedbackForm,
      params: { body: formData },
    })

    yield call(additionalData.onSuccess)
  } catch (error) {
    console.log('Error sending feedback form', error)
    yield call(additionalData.onError, { error })
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export const settingsWatchers = [
  takeLatest(actions.DELTA, crudSwitch, {
    getSaga: getSettingsFlow,
    updateSaga: updateSettingsFlow,
  }),
  takeLatest(sendFeedbackFormActions.DELTA, sendFeedbackFormFlow),
]
