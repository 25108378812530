import styled from 'styled-components'

import theme from 'theme'

export const ContentContainer = styled.ul`
  list-style: none;
  background-color: #fff;
  padding: ${theme.spacing.sm};
  width: 231px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 14px 34px;
`

export const DotsMenuBtn = styled.button`
  padding: 0;
  border: 0;
  cursor: pointer;
  flex-shrink: 0;
  text-align: right;
  visibility: hidden;
  position: absolute;
  right: 8px;
  top: 8px;

  &:hover {
    background-color: ${theme.colors.lightGrey};
  }

  &:after {
    width: 64px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    content: ' ';
    background: linear-gradient(
      270deg,
      #f1f4f8 77.22%,
      rgba(241, 244, 248, 0) 100%
    );
  }
`

export const MenuItem = styled.button`
  display: flex;
  width: 100%;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${theme.colors.lightGrey};
  }
`

export const MenuItemContainer = styled.li``

export const MenuItemIcon = styled.span`
  margin-right: ${theme.spacing.sm};
`

export const Separator = styled.div`
  margin-top: 2px;
`

export const DotsIconContainer = styled.span`
  position: relative;
  z-index: ${theme.zIndex.base};
`
