import { matchPath } from 'react-router-dom'
import {
  takeLatest,
  takeEvery,
  call,
  put,
  all,
  select,
  take,
  race,
  fork,
} from 'redux-saga/effects'
import { omit } from 'lodash'
import { noop, isNil, identity, propOr, find, path, map } from 'lodash/fp'

import { ROUTES } from 'consts'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { notifyAction } from 'containers/App/actions'
import { openModal } from 'containers/App/actions/modal'
import history from 'utils/history'
import { orderActions, tradeLimitActions } from 'containers/Orders/actions'
import { getDeliveryDatesByProducts } from 'containers/Delivery/actions'
import {
  getDeliveryDatesByProductsFlow,
  getAllDeliveryDatesFlow,
} from 'containers/Delivery/sagas'
import { getPromoProduct } from 'containers/Promotions/Products/actions'
import { genericGetDataEnhanced, crudSwitch } from 'containers/App/sagas'
import {
  makeCartProductsMapper,
  mapCartItems,
  trackStockExceeded,
  trackRemoveFromCart,
  trackRemovedAllFromCart,
  trackChangeCartProductQty,
} from 'services/analytics'
import { ALERT_TYPE } from 'components/Alert'
import {
  findUnitOfMeasureByVariant,
  calculateAmountByQuantity,
  notifyFailure,
} from 'components/Product/utils'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { rtkApi } from 'services/api/rtkApi'

import { getResponseError, isElasticStock } from 'utils'

import getNewRouteData from './getNewRouteData'
import {
  getCart,
  emptyCart,
  addToCart,
  deleteCartItem,
  updateDelivery,
  updateCart,
} from './api'

import actions, {
  CLEAR_CART_DELTA,
  DELIVERY_ACTIONS,
  updateCartItem,
  deleteCartItem as deleteCartItemActions,
  updateDeliveryComment,
  TRACK_STOCK_EXCEEDED,
} from './actions'
import {
  cartDataSelector,
  allCartItemsSelector,
  deliveryDatesByProductIdsSelector,
  expiredProductIdsSelector,
  cartDeliveriesSelector,
  deliveryDatesSettingsByDeliveriesSelector,
  cartPromosPrizesIdsSelector,
} from './selectors'

import { mapDeliveriesToProductIds, isCartUpdateError } from './utils'
import { getDeliveriesToUpdate } from './migrationHelper'
import {
  getElasticQuantity,
  detectAffectedElasticStock,
} from './elasticStockUtils'

function* updateExpiredDeliveriesFlow(expiredProductIds) {
  const cartDeliveries = yield select(cartDeliveriesSelector)
  const deliveryDatesSettingsByDeliveries = yield select(
    deliveryDatesSettingsByDeliveriesSelector({ isNonActiveItems: true }),
  )

  const deliveriesToUpdate = yield call(getDeliveriesToUpdate, {
    expiredProductIds,
    cartDeliveries,
    deliveryDatesSettingsByDeliveries,
  })

  if (deliveriesToUpdate.length) {
    yield put(rtkApi.endpoints.reportCartStatusChange.initiate({ value: true }))

    const updateDeliveryCalls = deliveriesToUpdate.map(delivery =>
      // eslint-disable-next-line
      call(updateDeliveryFlow, {
        data: delivery,
      }),
    )
    yield all(updateDeliveryCalls)
  }
}

function* checkAndMigrateDeliveries() {
  const expiredProductIds = yield select(expiredProductIdsSelector)

  if (expiredProductIds.length) {
    yield call(updateExpiredDeliveriesFlow, expiredProductIds)

    return true
  }

  return false
}

export function* getCartInfoFlow({
  data,
  isRefetch, // to be sure we're not falling into infinite loop if smth goes wrong
  successCallback = noop,
  additionalData: {
    suppressGetDeliveryDates = false, // to not double delivery dates fetch with addToCart's one
  } = {},
} = {}) {
  try {
    yield fork(getAllDeliveryDatesFlow)

    const response = yield call(genericGetDataEnhanced, {
      actions,
      request: getCart,
      params: data,
    })

    if (!response) return
    const { deliveries } = response

    yield call(successCallback)

    const productIds = mapDeliveriesToProductIds(deliveries)
    if (productIds.length && !suppressGetDeliveryDates) {
      yield fork(getDeliveryDatesByProductsFlow, { data: { productIds } })
    }
    if (
      matchPath({ path: ROUTES.CART, end: true }, history.location.pathname)
    ) {
      const cartPromosPrizesIds = yield select(cartPromosPrizesIdsSelector)
      yield all(
        cartPromosPrizesIds.map(productId =>
          put(getPromoProduct.delta({ productId })),
        ),
      )
    }

    if (productIds.length) {
      const { deliveryDatesFetched } = yield race({
        deliveryDatesFetched: take(getDeliveryDatesByProducts.SUCCESS),
        deliveryDatesFetchFailed: take(getDeliveryDatesByProducts.FAILURE),
        getCartRequest: take(actions.DELTA),
      })

      if (path(['data', 'length'], deliveryDatesFetched)) {
        if (!isRefetch) {
          const hasMigrationPerformed = yield call(checkAndMigrateDeliveries)
          if (hasMigrationPerformed) {
            yield call(getCartInfoFlow, { data, isRefetch: true })
          }
        }
      }
    }
  } catch (err) {
    console.log('Error fetching the cart', err)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* emptyCartFlow() {
  try {
    const products = yield select(allCartItemsSelector)
    yield call(genericGetDataEnhanced, {
      actions,
      request: emptyCart,
      method: 'clear',
    })

    yield put(tradeLimitActions.delta())

    const cartProductMapper = makeCartProductsMapper()

    yield call(trackRemovedAllFromCart, {
      products: mapCartItems(products, cartProductMapper),
    })

    yield put(orderActions.clear())
  } catch (error) {
    if (isCartUpdateError(error)) {
      yield put(notifyFailure(getResponseError(error)))
    } else {
      yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
  }
}

export function* addToCartFlow({ data = {} } = {}) {
  const {
    callback = identity,
    resetAmountCallback = identity,
    changeAmountToStockCallback = identity,
    refetchDataCallback = identity,
    openStockLimitedTooltip = identity,
    itemListName,
    itemListId,
    itemModelId,
    index,
    additionalEventParams,
    attributionToken,
    suppressStockNotification,
    getMessage,
    justAddedToCart,

    product,
    unit,
    quantity: requestedQuantity,

    suppressElasticStockCheck,
    shiftOnDecreaseToClosestDate, // implmented for elastic stock products only
  } = data

  try {
    yield put(updateCartItem.request())

    const unitOfMeasureObj = find({ unitOfMeasure: unit })(
      product.unitsOfMeasure,
    )

    const checkElasticStock =
      !suppressElasticStockCheck &&
      isElasticStock({
        stock: unitOfMeasureObj.stock,
        nonStock: product.nonStock,
      })
    const quantity = yield call(getElasticQuantity, {
      checkElasticStock,
      unitOfMeasureObj,
      requestedQuantity,
      product,
      justAddedToCart,
      *onCartRefresh() {
        yield call(getCartInfoFlow)
      },
    })

    const response = yield call(genericGetDataEnhanced, {
      request: addToCart,
      params: {
        productId: product.id,
        unit,
        quantity,
      },
    })

    if (!response) {
      return
    }

    const {
      cart,
      itemsNotEnoughStock,
      cartItem,
      cartItem: { product: updatedProduct },
    } = response

    yield call(updateRtkListRequest, {
      listName: 'getProductReplacements',
      updatedProduct,
    })
    yield call(updateRtkListRequest, {
      listName: 'getProductRecommendations',
      updatedProduct,
    })
    yield call(updateRtkListRequest, {
      listName: 'getRecommendations',
      updatedProduct,
    })

    if (itemsNotEnoughStock.length) {
      refetchDataCallback()
      const {
        quantityAdded,
        unitOfMeasure,
        product: { unitsOfMeasure },
      } = itemsNotEnoughStock[0] || {}
      const unitData = find({ unitOfMeasure })(unitsOfMeasure)
      const stock = calculateAmountByQuantity(unitData, quantityAdded)

      if (!suppressStockNotification) {
        yield put(
          notifyAction({
            message: getMessage(stock),
            type: ALERT_TYPE.INFO,
          }),
        )
      }

      if (unitData.isStockLimited) {
        openStockLimitedTooltip(stock)
      }
      yield call(changeAmountToStockCallback, stock, unitData)
    } else if (cartItem) {
      const affectedElasticStock = yield call(detectAffectedElasticStock, {
        checkElasticStock,
        product: cartItem.product,
        unitOfMeasureObj,
        requestedQuantity,
        shiftOnDecreaseToClosestDate,
        *onCartRefresh() {
          yield call(getCartInfoFlow)
        },
      })

      yield call(callback, { affectedElasticStock })
    }

    if (cartItem) {
      const { customerNo } = yield select(userDataSelector)
      yield call(trackChangeCartProductQty, {
        unitData: unitOfMeasureObj,
        product,
        quantity,
        itemListName,
        itemListId,
        itemModelId,
        index,
        customerNo,
        additionalEventParams,
        attributionToken,
      })

      const allItems = yield select(allCartItemsSelector)
      const allItemsIds = map('product.id')(allItems)
      const cartItemId = cartItem.product.id
      const isCartItemInAllItems = allItemsIds.includes(cartItemId)
      if (justAddedToCart && !isCartItemInAllItems) {
        const productIds = [...allItemsIds, cartItemId]
        yield put(getDeliveryDatesByProducts.delta({ productIds }))
      }

      yield put(tradeLimitActions.delta())

      yield put(updateCartItem.success({ cart, cartItem }))
    }
  } catch (error) {
    console.log('Error adding product to cart', error)
    const omittedError = omit(error, ['headers'])
    yield put(updateCartItem.failure(omittedError))
    yield call(resetAmountCallback)

    if (isCartUpdateError(error)) {
      yield put(notifyFailure(getResponseError(error)))
    } else {
      yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
  }
}

export function* deleteCartItemFlow(action) {
  const {
    data: {
      errorCallback,
      product,
      unitOfMeasure,
      itemListName,
      itemListId,
      itemModelId,
      index,
      additionalEventParams,
    },
    data,
  } = action
  const successCallback = propOr(identity, 'successCallback', data)

  try {
    const response = yield call(genericGetDataEnhanced, {
      actions: deleteCartItemActions,
      request: deleteCartItem,
      params: data,
    })

    if (!response) return

    yield call(resetRtkUnitInCartQty, {
      listName: 'getProductReplacements',
      product,
      unitOfMeasure,
    })
    yield call(resetRtkUnitInCartQty, {
      listName: 'getProductRecommendations',
      product,
      unitOfMeasure,
    })
    yield call(resetRtkUnitInCartQty, {
      listName: 'getRecommendations',
      product,
      unitOfMeasure,
    })

    yield put(updateCartItem.success(response))
    yield put(tradeLimitActions.delta())
    yield call(successCallback)

    const unitData = findUnitOfMeasureByVariant(
      product.unitsOfMeasure,
      unitOfMeasure,
    )
    const { customerNo } = yield select(userDataSelector)
    yield call(trackRemoveFromCart, {
      unitData,
      quantity: unitData.inCartQuantity,
      product,
      itemListName,
      itemListId,
      itemModelId,
      index,
      customerNo,
      additionalEventParams,
    })
  } catch (error) {
    console.log('Error deleting cart item', error)

    if (error.status === 422) {
      yield put(actions.delta())
    }

    if (errorCallback) {
      yield call(errorCallback)
    }

    if (isCartUpdateError(error)) {
      yield put(notifyFailure(getResponseError(error)))
    } else if (!errorCallback) {
      yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
    }
  }
}

export function* updateDeliveryFlow({
  data: {
    deliveryDate,
    prevRouteId,
    routeId: routeIdPassed,
    routeName: routeNamePassed,
    routeSortingOrder: routeSortingOrderPassed,
    productIds,
  } = {},
}) {
  try {
    let routeIdFound
    let routeNameFound
    let routeSortingOrderFound

    if (!routeIdPassed) {
      const cartDeliveries = yield select(cartDataSelector)
      const availableDeliveryDates = yield select(
        deliveryDatesByProductIdsSelector(productIds, false, true),
      )
      ;({
        routeId: routeIdFound,
        routeName: routeNameFound,
        routeSortingOrder: routeSortingOrderFound,
      } = getNewRouteData({
        deliveryDate,
        prevRouteId,
        cartDeliveries,
        availableDeliveryDates,
      }))
    }

    const routeId = isNil(routeIdPassed) ? routeIdFound : routeIdPassed
    const routeName = routeNamePassed || routeNameFound
    const routeSortingOrder = isNil(routeSortingOrderPassed)
      ? routeSortingOrderFound
      : routeSortingOrderPassed

    yield call(genericGetDataEnhanced, {
      actions,
      request: updateDelivery,
      params: {
        date: deliveryDate,
        routeId,
        routeName,
        routeSortingOrder,
        productIds,
      },
      method: 'cancel', // do not change the store
    })

    yield call(getCartInfoFlow, {
      isRefetch: true,
    })
  } catch (err) {
    console.log('Error updating delivery date', err)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* updateDeliveryCommentFlow({
  data: { routeId, routeName, date, comment },
}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: updateDeliveryComment,
      request: updateDelivery,
      params: {
        date,
        routeId,
        routeName,
        comment,
      },
    })
  } catch (err) {
    console.log('Error updating delivery comment', err)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* updateCartFlow({
  data,
  additionalData: { callback, successCallback } = {},
}) {
  try {
    yield call(genericGetDataEnhanced, {
      actions,
      request: updateCart,
      params: data,
    })

    if (successCallback) yield call(successCallback)
  } catch (err) {
    console.log('Error updating the cart', err)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }

  if (callback) yield call(callback)
}

function* trackStockExceededFlow({ data: { onTrackStockExceeded } }) {
  const { customerNo, storeId } = yield select(userDataSelector)
  yield call(onTrackStockExceeded || trackStockExceeded, {
    customerNo,
    storeId,
  })
}

function* resetRtkUnitInCartQty({ product, listName, unitOfMeasure }) {
  const productIds = yield select(state =>
    rtkApi.util.selectCachedArgsForQuery(state, listName),
  )
  yield all(
    productIds.map(productId =>
      put(
        rtkApi.util.updateQueryData(listName, productId, ({ products }) =>
          products.forEach(cachedProduct => {
            if (cachedProduct.id === product.id) {
              // eslint-disable-next-line no-param-reassign
              cachedProduct.unitsOfMeasure = cachedProduct.unitsOfMeasure.map(
                cachedUnit => {
                  // when removing from cart the whole item no unitOfMeasure is provided
                  // !unitOfMeasure allows to clean up all units of measure for such case
                  if (
                    cachedUnit.unitOfMeasure === unitOfMeasure ||
                    !unitOfMeasure
                  ) {
                    return {
                      ...cachedUnit,
                      inCartQuantity: 0,
                    }
                  }
                  return cachedUnit
                },
              )
            }
          }),
        ),
      ),
    ),
  )
}

function* updateRtkListRequest({ listName, updatedProduct }) {
  const productIds = yield select(state =>
    rtkApi.util.selectCachedArgsForQuery(state, listName),
  )
  yield all(
    (productIds || []).map(productId =>
      put(
        rtkApi.util.updateQueryData(listName, productId, state =>
          state?.products.forEach(product => {
            if (product.id === updatedProduct.id) {
              // eslint-disable-next-line no-param-reassign
              product.unitsOfMeasure = updatedProduct.unitsOfMeasure
            }
          }),
        ),
      ),
    ),
  )
}

export default [
  takeEvery(actions.DELTA, crudSwitch, {
    getSaga: getCartInfoFlow,
    createSaga: addToCartFlow,
    updateSaga: updateCartFlow,
    deleteSaga: deleteCartItemFlow,
  }),
  takeLatest(CLEAR_CART_DELTA, emptyCartFlow),
  takeLatest(DELIVERY_ACTIONS.UPDATE, updateDeliveryFlow),
  takeLatest(updateDeliveryComment.DELTA, updateDeliveryCommentFlow),
  takeLatest(TRACK_STOCK_EXCEEDED, trackStockExceededFlow),
]
