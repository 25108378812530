import React from 'react'

import {
  confirmationModalStyles,
  ConfirmationModal,
  ConfirmationModalHeader,
  ModalBtnsContainer,
  ModalFooterContainer,
  DescriptionText,
  LeftBtn,
  RightBtn,
  BUTTONS_VARIANT,
} from './styles'

const Confirmation = ({
  children,
  isOpen,
  isWide,
  onClose,
  onConfirm,
  isConfirmDisabled,
  headingText,
  footerText,
  leftBtnText,
  leftBtnTestId = 'confirmation-cancel',
  rightBtnText,
  rightBtnTestId,
  btnsVariant = BUTTONS_VARIANT.DELETE,
}) => (
  <ConfirmationModal
    isOpen={isOpen}
    onClose={onClose}
    overlayStyle={confirmationModalStyles}
    $isWide={isWide}
  >
    <ConfirmationModalHeader heading={headingText} onClick={onClose} />

    {children}

    <ModalFooterContainer>
      {footerText && <DescriptionText>{footerText}</DescriptionText>}
      <ModalBtnsContainer>
        <LeftBtn onClick={onClose} data-test-id={leftBtnTestId}>
          {leftBtnText}
        </LeftBtn>
        <RightBtn
          onClick={onConfirm}
          $variant={btnsVariant}
          data-test-id={rightBtnTestId}
          disabled={isConfirmDisabled}
        >
          {rightBtnText}
        </RightBtn>
      </ModalBtnsContainer>
    </ModalFooterContainer>
  </ConfirmationModal>
)

export default Confirmation
