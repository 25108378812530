import React from 'react'

const Oil = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      className="icon-fill"
      x="9"
      y="1"
      width="5"
      height="2"
      rx="1"
      fill="#229944"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 17.3043C4 19.0704 4.69863 20.6671 5.81275 21.8704C6.60015 22.7208 7.65279 23 8.51383 23H14.4862C15.3472 23 16.3998 22.7208 17.1872 21.8704C18.3014 20.6671 19 19.0704 19 17.3043C19 15.8944 18.5611 14.6057 17.8231 13.533C18.4239 12.9499 18.7998 12.1384 18.7998 11.2321C18.7998 9.42715 17.3112 8 15.5198 8C15.3474 8 15.1778 8.01318 15.0122 8.03858V7.5C15.0122 6.11929 13.8929 5 12.5122 5H10.4878C9.10705 5 7.98776 6.11929 7.98776 7.5V11.1339C5.67748 12.2547 4 14.5481 4 17.3043ZM15.0122 10.1004V11.1339C15.5329 11.3865 16.0214 11.6986 16.4658 12.0622C16.6751 11.8403 16.7998 11.5465 16.7998 11.2321C16.7998 10.5715 16.2469 10 15.5198 10C15.3384 10 15.1671 10.036 15.0122 10.1004ZM6 17.3043C6 18.5291 6.48221 19.6496 7.28028 20.5115C7.59389 20.8502 8.05223 21 8.51383 21H14.4862C14.9478 21 15.4061 20.8502 15.7197 20.5115C16.5178 19.6496 17 18.5291 17 17.3043C17 15.2363 15.6254 13.4659 13.6769 12.7349C13.2951 12.5917 13.0122 12.2443 13.0122 11.8366V7.5C13.0122 7.22386 12.7884 7 12.5122 7H10.4878C10.2116 7 9.98776 7.22386 9.98776 7.5V11.8366C9.98776 12.2443 9.70486 12.5917 9.32314 12.7349C7.37456 13.4659 6 15.2363 6 17.3043Z"
      fill="#99D0AC"
    />
    <path
      className="icon-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28028 20.5115C6.48221 19.6496 6 18.5291 6 17.3043C6 16.4719 6.22274 15.6876 6.61599 15H16.384C16.7773 15.6876 17 16.4719 17 17.3043C17 18.5291 16.5178 19.6496 15.7197 20.5115C15.4061 20.8502 14.9478 21 14.4862 21H8.51383C8.05223 21 7.59389 20.8502 7.28028 20.5115Z"
      fill="#229944"
    />
  </svg>
)

export default Oil
