import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'

import { InteractiveWrapper, GreyWrapper } from 'components/Button/styles'
import { BaseTextV2, Link } from 'components/Text'
import { AddProductsButton } from 'components/Button'
import { AmountContainer } from 'components/Product/Amount/styles'
import { Container as FloatingPriceContainer } from 'components/FloatingPrice/FloatingPriceWrapper/styles'
import { TEMPLATE_ROW_MOBILE_MAX_WIDTH } from '../consts'
import { PriceSection, ProductSubText } from '../ProductPrices/styles'
import { variantSelectHover } from '../ProductBox/styles'
import { templateIconStyling } from '../BookmarkIcon/styles'
import {
  CatalogAddProductsButton,
  CatalogButtonsWrapper,
  CatalogProductButtonsContainer,
  CatalogAlternateBtn,
  AmountForm,
} from '../ProductButtons/styles'

export const TemplateProductLink = styled(Link)`
  width: fit-content;
`

export const TemplateProductContainer = styled(InteractiveWrapper)`
  padding: 20px 0;
  gap: 12px;
  cursor: auto;
  :hover {
    background: initial;
  }

  @media (min-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    align-items: center;
    padding: 16px;
    gap: 0;

    :hover {
      ${GreyWrapper} {
        background: ${theme.colors.white};
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      }
      ${variantSelectHover};
    }
  }
`

const ButtonsContainer = styled(Flex).attrs({
  flexDirection: ['column', 'row'],
  justifyContent: ['space-between'],
})`
  align-items: center;
`

export const AddToCartContainer = styled(Flex)`
  ${CatalogAddProductsButton} {
    min-width: 112px;
  }
  ${CatalogProductButtonsContainer} {
    position: relative;
    width: 112px;
    padding: 0;
  }
  ${CatalogButtonsWrapper} {
    margin-top: 0;
  }
  ${CatalogAlternateBtn} {
    position: absolute;
    background: linear-gradient(
      90deg,
      #f1f4f8 70.31%,
      rgba(241, 244, 248, 0) 100%
    );
    padding: 0;
    margin: 8px 4px;
    height: 24px;
    width: 24px;
    min-width: 24px;
    &:nth-child(3) {
      right: 0;
      background: linear-gradient(
        270deg,
        #f1f4f8 68.75%,
        rgba(241, 244, 248, 0) 100%
      );
    }
  }
  ${AmountForm} {
    margin: 0;
    ${AmountContainer} {
      height: 40px;
      padding: 0;
      border-radius: 8px;
    }
  }
`

export const Container = styled(ButtonsContainer)`
  flex-wrap: wrap;
  flex-flow: row;
  gap: 16px;

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    flex-flow: row-reverse;
    ${AddToCartContainer} {
      display: none;
    }
  }
`

export const InnerContainer = styled(ButtonsContainer)`
  display: flex;
  width: 100%;
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
`

export const ProductInfoContainer = styled.div`
  display: flex;
  align-self: center;
  gap: 4px;
  flex-direction: column;
  flex-grow: 1;
  padding-right: ${theme.spacing.base};

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    align-self: initial;
    padding-right: 0;
    padding-left: 4px;
  }
`

export const ProductActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
`

export const TemplateProductContent = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  flex-flow: column;

  @media (min-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    flex-flow: row;
    align-items: center;
    padding-top: 0;
    margin-left: 16px;
  }

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    gap: 16px;
  }
`

export const AddToCartIconButton = styled(AddProductsButton)`
  min-width: 0;

  @media (max-width: 639px) {
    width: 100%;
    margin-top: ${theme.spacing.sm};
  }
`

export const ProductRowPromoText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true, fontSize: true },
})`
  color: ${theme.colors.secondary};
`

export const TemplateRowPricesContainer = styled(Flex)`
  text-align: right;
  width: 100px;
  ${ProductSubText} {
    margin-bottom: 0;
  }
  > ${PriceSection} {
    padding: 0;
    align-items: end;
  }
  ${FloatingPriceContainer} {
    justify-content: right;
  }

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    display: none;
  }
`

export const TemplateRowPricesMobileContainer = styled(
  TemplateRowPricesContainer,
)`
  display: none;

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    display: flex;
    ${PriceSection} {
      padding: 0;
      margin-top: 8px;
      text-align: left;
      align-items: start;
    }
    ${FloatingPriceContainer} {
      justify-content: left;
    }
  }
`

export const TemplateRowTrashBtnContainer = styled(Flex)`
  gap: 8px;

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    width: 100%;
  }
`

export const IconContainer = styled(GreyWrapper)`
  padding: 0;
  min-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  align-self: center;
`

export const TemplateRowTrashBtn = styled(IconContainer)`
  :hover {
    background: ${theme.colors.lightGrey6} !important;
  }
`

// prettier-ignore
export const BookmarkIconContainer = styled(IconContainer)`
  ${templateIconStyling}
  :hover {
    border: 4px solid #FFFFFF;
    background: ${theme.colors.lightGrey} !important;
  }
  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    align-self: flex-start;
  }
`

export const ProductImgContainer = styled(Flex)`
  ${AddToCartContainer}, ${TemplateRowTrashBtn}, ${BookmarkIconContainer} {
    display: none;
  }

  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    min-width: 112px;
    padding-top: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    ${AddToCartContainer}, ${TemplateRowTrashBtn}, ${BookmarkIconContainer} {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
`

// prettier-ignore
export const TemplateProductActions = styled(Flex)`
  gap: 8px;
  @media (max-width: ${TEMPLATE_ROW_MOBILE_MAX_WIDTH}px) {
    gap: 12px;
    ${TemplateRowTrashBtn} {
      display: none;
    }
    ${props => props.isNonActive && `
      ${BookmarkIconContainer} {
        display: none;
      }
    `};
  }
`

// prettier-ignore
export const InactiveProductActions = styled(Flex)`
  gap: 12px;
  width: 100%;
  ${props => props.isTemplateAutomatic && `
    ${BookmarkIconContainer} {
      min-width: auto;
      width: 100%;
    }
  `};
`
