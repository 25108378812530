import { useState, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { sumBy, noop } from 'lodash/fp'

import { notifyAction } from 'containers/App/actions'
import { ALERT_TYPE } from 'components/Alert'
import {
  FILE_SIZE_LIMIT,
  DESCRIPTION_MIN_LENGTH,
  DESCRIPTION_MAX_LENGTH,
} from './consts'

import messages from './messages'

const PHONE_NO_REGEX = /^[^A-Za-z]+$/

export const useContactFormValidation = () => {
  const { formatMessage } = useIntl()

  const validate = values => {
    const errors = {}
    const phoneNo = values.phone_no || ''
    const { topic } = values
    const desc = values.description || ''
    const files = values.files || []

    if (phoneNo && !PHONE_NO_REGEX.test(phoneNo)) {
      errors.phone_no = formatMessage(messages.invalidPhoneNo)
    }

    if (topic !== null) {
      if (!topic) {
        errors.topic = formatMessage(messages.emptyField)
      }
    }

    if (!desc) {
      errors.description = formatMessage(messages.emptyField)
    } else if (desc.length < DESCRIPTION_MIN_LENGTH) {
      errors.description = formatMessage(messages.minLength, {
        minLength: DESCRIPTION_MIN_LENGTH,
      })
    } else if (desc.length > DESCRIPTION_MAX_LENGTH) {
      errors.description = formatMessage(messages.maxLength, {
        minLength: DESCRIPTION_MAX_LENGTH,
      })
    }

    if (files.length) {
      const filesSize = sumBy('size', files)
      if (filesSize > FILE_SIZE_LIMIT) {
        errors.files = formatMessage(messages.filesValidation)
      }
    }

    return errors
  }

  return validate
}

export const useClaimForm = ({
  isContactFormOpen,
  onSubmitSuccess = noop,
  onSubmitError = noop,
  actionsGroup,
  additionalData = {},
}) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  useEffect(
    () => {
      if (!isContactFormOpen) {
        setSubmitError(null)
      }
    },
    [isContactFormOpen],
  )

  const onSubmit = useCallback(formValues => {
    setIsSubmitting(true)
    dispatch(
      actionsGroup.delta(formValues, {
        ...additionalData,
        onSuccess: () => {
          onSubmitSuccess()
          setIsSubmitting(false)
          dispatch(
            notifyAction({
              message: formatMessage(messages.sendClaimSuccess),
              type: ALERT_TYPE.SUCCESS,
            }),
          )
        },
        onError: ({ error }) => {
          onSubmitError(error)
          setIsSubmitting(false)
          dispatch(
            notifyAction({
              message: formatMessage(messages.sendClaimError),
              type: ALERT_TYPE.ERROR,
            }),
          )

          if (error.body?.errors?.find(err => err.field_name === 'files')) {
            setSubmitError(formatMessage(messages.fileUploadError))
          }
        },
      }),
    )
  }, [])

  return [isSubmitting, onSubmit, submitError]
}
