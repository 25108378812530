import styled from 'styled-components'
import { Flex } from '@rebass/grid'

export const ProgressOuter = styled(Flex)`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  background-color: ${p => p.bg};
  height: ${p => p.size};
`

export const ProgressInner = styled(Flex)`
  position: absolute;
  height: 100%;
  top: 0;
  border-radius: 8px;
  background-color: ${p => p.bg};
`
