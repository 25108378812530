import React, { useState } from 'react'
import { Flex } from '@rebass/grid'

import {
  useGetStorageValue,
  CONFIG_SCHEMA_KEYS,
  CONFIG_API_PATHS_MAP,
} from 'containers/Storage'
import { useUpdateStorageMutation } from 'services/api/rtkApi'

import { HintModal, HintOverlay, ProgressBar, PROGRESS_MODE } from 'components'

import { Truck, TruckOk } from 'components/Icons'

import {
  hintModalConfig,
  otherDeliveryHintModalConfig,
} from './hintModalConfig'

const DeliveryProgress = ({
  deliveryStats,
  isFirstOtherIncoming,
  isFirstDelivery,
  CustomTruckIcon,
}) => {
  const [hintIndex, setHintIndex] = useState(-1)

  const [updateStorage] = useUpdateStorageMutation()

  const freeDeliveryHintEnabled =
    useGetStorageValue(CONFIG_SCHEMA_KEYS.CART_GUIDE.FREE_DELIVERY) &&
    isFirstDelivery
  const otherDeliveryHintEnabled =
    useGetStorageValue(CONFIG_SCHEMA_KEYS.CART_GUIDE.OTHER_DELIVERY) &&
    isFirstOtherIncoming

  const TruckIcon =
    CustomTruckIcon ||
    (deliveryStats && deliveryStats.missingAmount > 0 ? Truck : TruckOk)

  return (
    <Flex alignItems="center">
      <HintOverlay
        enabled={freeDeliveryHintEnabled || otherDeliveryHintEnabled}
      >
        <TruckIcon
          onClick={e => {
            if (freeDeliveryHintEnabled || otherDeliveryHintEnabled) {
              e.stopPropagation()
              setHintIndex(0)
            }
          }}
        />
      </HintOverlay>

      <Flex width={48} ml={16}>
        {deliveryStats && (
          <ProgressBar
            mode={
              deliveryStats.missingAmount > 0
                ? PROGRESS_MODE.DELIVERY
                : PROGRESS_MODE.DELIVERY_SUCCESS
            }
            value={deliveryStats.total}
            maxValue={deliveryStats.settings.baseLogisticLimit}
          />
        )}
      </Flex>

      {hintIndex > -1 && (
        <HintModal
          {...(otherDeliveryHintEnabled
            ? otherDeliveryHintModalConfig
            : hintModalConfig)[hintIndex]}
          onClose={() => {
            setHintIndex(-1)
            updateStorage({
              path:
                CONFIG_API_PATHS_MAP.CART_GUIDE[
                  otherDeliveryHintEnabled ? 'OTHER_DELIVERY' : 'FREE_DELIVERY'
                ],
              value: false,
            })
          }}
        />
      )}
    </Flex>
  )
}

export default DeliveryProgress
