import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { isEmpty } from 'lodash/fp'

import {
  useLazyGetProductRecommendationsQuery,
  useGetProductRecommendationsState,
} from 'containers/Products/rtkApi'
import { productDetailsActions } from 'containers/ProductDetails/actions'
import { productDetailsSelector } from 'containers/ProductDetails/selectors'
import { promoGroupFetchingSelector } from 'containers/Promotions/Groups/selectors'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { isProductOutOfStock, isOutOfStock } from 'utils'
import LoaderFullHeight from 'components/LoaderFullHeight'
import { isAvailableAfter } from 'components/Product/utils'
import { retailTrackDetailView } from 'services/analytics/retail'
import {
  findUnitData,
  ITEM_LIST_IDS,
  ITEM_LIST_NAMES,
  SCREENS,
  useViewItemTracking,
  withPageView,
} from 'services/analytics'

import { useCarouselItems } from './utils'
import { ProductDetailsTopSection, ProductDetailsContainer } from './styles'
import ProductImageSection from './ProductImageSection'
import ProductInfoSection from './ProductInfoSection'
import CartControlsSection from './CartControlsSection'
import ProductCarousel from './ProductCarousel'
import messages from './messages'

const ProductDetails = () => {
  const [selectedUnit, setSelectedUnit] = useState('')
  const { id } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const { formatMessage } = useIntl()

  const userData = useSelector(userDataSelector)
  const product = useSelector(productDetailsSelector)
  const isPromoGroupFetching = useSelector(promoGroupFetchingSelector)

  const unitData = findUnitData(product.unitsOfMeasure, selectedUnit) || {}
  const isProductInactive = !isEmpty(product) && !product.active
  const isSoonAvailable = isAvailableAfter(product, userData)
  const isProductDisabled = isProductInactive || isProductOutOfStock(product)
  const isUnitOutOfStock = isOutOfStock({
    stock: unitData.stock,
    nonStock: product.nonStock,
  })
  const isUnitDisabled =
    isProductInactive || isSoonAvailable || isUnitOutOfStock

  const [fetchProductRecommendations] = useLazyGetProductRecommendationsQuery()
  const recommendationsState = useGetProductRecommendationsState({ id })

  const {
    setCarouselElement,
    carouselItems,
    isCarouselFetching,
    itemModelId,
    attributionToken,
  } = useCarouselItems({
    isUnitDisabled,
    recommendationsState,
    fetchRecommendationsArgs: { productId: id },
    fetchRecommendations: ({ productId }) =>
      fetchProductRecommendations({ id: productId }),
  })

  const hasReplacements = isUnitDisabled && !!carouselItems.length

  useEffect(
    () => {
      // need for same route navigation (e.g. clicking on carousel item in PDP)
      window.scrollTo(0, 0)
      dispatch(productDetailsActions.delta({ id }))

      return () => {
        dispatch(productDetailsActions.clear())
      }
    },
    [id],
  )

  const trackViewItem = useViewItemTracking({
    product,
    itemListId: location.state?.itemListId,
    itemListName: location.state?.itemListName,
    itemModelId: location.state?.itemModelId,
    index: location.state?.index,
    unitOfMeasure: product.baseUnitOfMeasure,
  })

  useEffect(
    () => {
      if (product.id) {
        retailTrackDetailView({
          product,
          attributionToken: location.state?.attributionToken,
        })
      }

      trackViewItem()
      setSelectedUnit(product.baseUnitOfMeasure)
    },
    [product.id],
  )

  if (!product.id || !selectedUnit) return null

  return (
    <>
      {isPromoGroupFetching && <LoaderFullHeight />}
      <ProductDetailsContainer>
        <ProductDetailsTopSection>
          <ProductImageSection
            product={product}
            isProductDisabled={isProductDisabled}
          />
          <CartControlsSection
            product={product}
            unitData={unitData}
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            isSoonAvailable={isSoonAvailable}
            isUnitDisabled={isUnitDisabled}
            isUnitOutOfStock={isUnitOutOfStock}
            hasReplacements={hasReplacements}
          />
          <ProductInfoSection product={product} />
        </ProductDetailsTopSection>

        <ProductCarousel
          ref={setCarouselElement}
          isLoading={isCarouselFetching}
          products={carouselItems}
          itemModelId={itemModelId}
          attributionToken={attributionToken}
          itemListName={
            isUnitDisabled
              ? ITEM_LIST_NAMES.SUBSTITUTES
              : ITEM_LIST_NAMES.RECOMMENDATION_FBT
          }
          itemListId={
            isUnitDisabled
              ? ITEM_LIST_IDS.SUBSTITUTES
              : ITEM_LIST_IDS.RECOMMENDATION_FBT
          }
          title={formatMessage(
            isUnitDisabled
              ? messages.replacements
              : messages.frequentlyBoughtTogether,
          )}
        />
      </ProductDetailsContainer>
    </>
  )
}

export default withPageView(SCREENS.PDP)(ProductDetails)
