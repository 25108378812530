import React from 'react'

const Sushi = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8272 11.5586C15.8286 10.7664 15.923 9.70521 16.2315 8.67789C16.5398 7.65157 17.0434 6.71884 17.8203 6.09196C20.7232 7.47215 21.6823 9.92097 21.9641 12.0172C22.1436 13.352 21.6586 13.3318 20.3076 12.7516C19.0679 12.2192 17.5872 11.8033 15.8272 11.5586ZM14.3285 11.3979C13.6075 11.3423 12.8453 11.313 12.04 11.313C10.4702 11.313 9.05501 11.4242 7.78549 11.6251C7.72155 10.5245 7.78809 9.20247 8.17869 8.00362C8.58962 6.7424 9.34452 5.64449 10.6917 5.04391C11.1202 5.01501 11.5693 5 12.04 5C13.7044 5 15.0959 5.18763 16.2587 5.51349C15.5354 6.31891 15.0797 7.29855 14.7949 8.24637C14.4543 9.38048 14.3417 10.5231 14.3285 11.3979ZM3.69248 12.7555C4.46471 12.4269 5.33189 12.1426 6.29929 11.9153C6.20328 10.645 6.25205 9.0204 6.74526 7.50662C6.97069 6.81473 7.29175 6.14101 7.73489 5.53586C3.64357 6.71013 2.37827 9.5986 2.03855 12.0174C1.851 13.3528 2.33921 13.3313 3.69248 12.7555Z"
      fill="#229944"
      className="icon-fill"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 14.479C6.03045 13.572 8.68144 12.9999 12.04 12.9999C15.3916 12.9999 17.9954 13.5696 20 14.4733V15.4999C20 17.4329 18.433 18.9999 16.5 18.9999L7.5 18.9999C5.567 18.9999 4 17.4329 4 15.4999V14.479Z"
      fill="#99D0AC"
    />
  </svg>
)

export default Sushi
