import React from 'react'
import { useFormik } from 'formik'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Flex } from '@rebass/grid'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { LabelV2, SelectV2, TextAreaV2 } from 'components/Fields/styles'
import { BaseTextV2Medium } from 'components/Text'
import ValidationWrapper from 'components/Fields/ValidationWrapper'
import FormInputField from 'components/Fields/FormInputField'
import { CONTACT_FORM_TOPICS } from '../consts'
import { useContactFormValidation } from '../utils'
import FileSelector from './FileSelector'

import messages from '../messages'

import {
  StyledForm,
  FieldContainer,
  EmailContainer,
  EmailLabel,
  SubmitButton,
  Counter,
} from './styles'

const Form = ({
  isTopicField,
  onSubmit,
  submitDisabled,
  submitError,
  hasFileSelector,
}) => {
  const { formatMessage } = useIntl()
  const userData = useSelector(userDataSelector)

  const validateContactForm = useContactFormValidation()

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      phone_no: null,
      topic: isTopicField ? '' : null,
      description: '',
      files: [],
    },
    onSubmit,
    validate: fieldValues => validateContactForm(fieldValues),
  })

  const descriptionLabel = (
    <Flex>
      <LabelV2 htmlFor="topic">{formatMessage(messages.description)}</LabelV2>
      <Counter>{values.description.length} / 5000</Counter>
    </Flex>
  )

  return (
    <StyledForm onSubmit={handleSubmit}>
      <FieldContainer>
        <EmailContainer>
          <EmailLabel>{formatMessage(messages.emailLabel)}</EmailLabel>
          <BaseTextV2Medium>{userData.customerEmail}</BaseTextV2Medium>
        </EmailContainer>
      </FieldContainer>
      <FormInputField
        label={formatMessage(messages.phoneLabel)}
        id="phone_no"
        name="phone_no"
        type="text"
        placeholder={formatMessage(messages.phonePlaceholder)}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={values.phone_no}
        touched={touched.phone_no}
        isError={errors.phone_no && touched.phone_no}
        errorText={errors.phone_no}
      />
      {isTopicField && (
        <FieldContainer>
          {descriptionLabel}
          <ValidationWrapper
            testId="topic-field-wrapper"
            errorText={errors.topic}
            isTouched={touched.topic}
          >
            <SelectV2
              required
              id="topic"
              name="topic"
              data-test-id="topic-field"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.topic}
              isError={errors.topic && touched.topic}
            >
              <option value="" disabled selected>
                {formatMessage(messages.topicPlaceholder)}
              </option>
              {CONTACT_FORM_TOPICS.map(value => {
                const topic = formatMessage(messages[value])

                return (
                  <option key={topic} value={value}>
                    {topic}
                  </option>
                )
              })}
            </SelectV2>
          </ValidationWrapper>
        </FieldContainer>
      )}
      <FieldContainer>
        {!isTopicField && descriptionLabel}
        <ValidationWrapper
          testId="description-field-wrapper"
          errorText={errors.description}
          isTouched={touched.description}
        >
          <TextAreaV2
            id="description"
            name="description"
            type="text"
            rows={4}
            maxLength={5000}
            placeholder={formatMessage(messages.descPlaceholder)}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            isError={errors.description && touched.description}
          />
        </ValidationWrapper>
      </FieldContainer>

      {hasFileSelector && (
        <ValidationWrapper
          testId="file-selector"
          errorText={errors.files || submitError}
          isTouched
        >
          <FileSelector
            onChange={files =>
              setFieldValue('files', [...values.files, ...files])
            }
            onBlur={handleBlur}
            onRemove={fileName => {
              setFieldValue(
                'files',
                values.files.filter(file => file.name !== fileName),
              )
            }}
            files={values.files}
          />
        </ValidationWrapper>
      )}

      <SubmitButton type="submit" disabled={submitDisabled}>
        {formatMessage(messages.submit)}
      </SubmitButton>
    </StyledForm>
  )
}

export default Form
