import React from 'react'

import { ModalGlobalStyle } from 'containers/App/styledComponents'

import { APP_BREAKPOINTS } from 'consts'
import { PortalModalOverlay, ModalStyled } from './styles'

const PortalModal = ({
  isOpen,
  onClose,
  children,
  className,
  testId,
  overlayStyle,
  fullWidthBreakpoint = APP_BREAKPOINTS.DESKTOP_START,
}) => (
  <ModalStyled
    $fullWidthBreakpoint={fullWidthBreakpoint}
    isOpen={isOpen}
    onRequestClose={onClose}
    overlayElement={(props, contentElement) => (
      <>
        <ModalGlobalStyle />
        <PortalModalOverlay
          {...props}
          $overlayStyle={overlayStyle}
          $fullWidthBreakpoint={fullWidthBreakpoint}
        >
          {contentElement}
        </PortalModalOverlay>
      </>
    )}
    className={className}
    data-test-id={testId}
  >
    {children}
  </ModalStyled>
)

export default PortalModal
