import React from 'react'
import { Portal } from 'react-portal'

import { DropdownContent, DropdownOverlay } from './styles'

const DropdownBody = ({
  offset = {},
  maxWidth,
  onOverlayClick,
  children,
  portalNode,
  isMobile,
  testId,
  className,
}) => {
  const { top, left } = offset

  return (
    <Portal node={!isMobile && portalNode}>
      <DropdownOverlay onClick={onOverlayClick} {...{ isMobile }} />
      <DropdownContent
        data-test-id={testId}
        {...{ maxWidth, top, left, isMobile, className }}
      >
        {children}
      </DropdownContent>
    </Portal>
  )
}

export default DropdownBody
