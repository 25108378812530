export const makeSchemaValidator = schema => values => {
  try {
    schema.validateSync(values.toJSON(), { abortEarly: false })
    return null
  } catch (error) {
    return error.inner.reduce(
      (errors, err) => ({
        ...errors,
        [err.path]: err.message,
      }),
      {},
    )
  }
}
