import styled from 'styled-components'
import theme from 'theme'

export const CategoriesContainer = styled.div`
  display: flex;
  background-color: ${theme.colors.white};
  box-shadow: 0 12px 32px ${theme.colors.shadow.modal};
  border-radius: 16px;
  transform-origin: top left;
  z-index: ${theme.zIndex.header + 1};
  transition: all 450ms cubic-bezier(0, 0, 0, 1);
`

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 324px;
`

export const CategoriesListSection = styled.div`
  align-self: start;
  display: grid;
  grid-template-columns: repeat(${p => (p.$splitColumns ? '2' : '1')}, 324px);
  grid-column-gap: ${theme.spacing.base};
  grid-row-gap: ${theme.spacing.xxs};
  padding: ${theme.spacing.base};
`

export const CategoriesActionsSection = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${theme.colors.semiTranspBg.gray};
  justify-content: space-between;
  padding: ${theme.spacing.base};
  min-height: 440px;
  width: 360px;
`

export const DropdownItemContainer = styled.div`
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  display: flex;
  border-radius: ${theme.borderRadius.base};
  cursor: pointer;
  transition: background-color 300ms ease-out;

  &:hover {
    background-color: ${theme.colors.lightGrey};
  }
`
