import { rtkApi } from 'services/api/rtkApi'

const cartApi = rtkApi.injectEndpoints({
  endpoints: build => ({
    getIncomingOrders: build.query({
      query: () => ({
        url: 'me/incoming_orders',
      }),
      transformResponse: res => res?.incomingOrders || [],
    }),
  }),
})

export const { useGetIncomingOrdersQuery } = cartApi

export default cartApi
