import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { isAvailableAfter } from 'components/Product/utils'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { CartV2, Replace, ScenarioLocked } from 'components/Icons'
import { getReplacementsActions } from 'containers/Products/Replacements/actions'
import { useCartQuantityHandling } from 'components/ProductCartControls/utils'
import TextTooltip from 'components/Tooltip/TextTooltip'
import AddToCartBtn from 'containers/App/LoggedInLayout/Header/Search/SuggestItem/AddToCartBtn'
import ProductCartControlsTooltip from 'components/ProductCartControls/ProductCartControlsTooltip'
import { isOutOfStock } from 'utils'
import { RowIconBtn } from 'views/Orders/Details/OrderItemsList/OrderItem/styles'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'

import messages from 'views/Orders/Details/messages'
import { BasicIconBtn, InfoBtn } from './styles'

const CartActionBtn = ({
  product,
  historicalUnitData,
  unitData,
  isOutOfCatalogReward,
}) => {
  const dispatch = useDispatch()
  const userData = useSelector(userDataSelector)
  const { formatMessage } = useIntl()

  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  const [affectedElasticStock, setAffectedElasticStock] = useState(null)
  const [isTooltipOpen, setTooltipOpen] = useState(false)

  useEffect(
    () => {
      if (affectedElasticStock) {
        setTooltipOpen(true)
      }
    },
    [affectedElasticStock],
  )

  const changeCartQuantity = useCartQuantityHandling({
    product,
    unitData,
    onAffectedElasticStock: affectedStock =>
      setAffectedElasticStock(affectedStock),
  })

  const isUnitOutOfStock = isOutOfStock({
    stock: unitData.stock,
    nonStock: product.nonStock,
  })
  const isSoonAvailable = isAvailableAfter(product, userData)
  const isUnitDisabled = !product.active || isSoonAvailable || isUnitOutOfStock

  if (product.licenseMissing) {
    return (
      <TextTooltip
        placement="top-end"
        content={formatMessage(messages.missingLicense, {
          licenseType: product.licenseType,
        })}
      >
        <InfoBtn data-test-id="missing-license-button">
          <ScenarioLocked color={theme.colors.white} />
        </InfoBtn>
      </TextTooltip>
    )
  }

  if (isOutOfCatalogReward) {
    return (
      <TextTooltip
        placement="top-end"
        content={formatMessage(messages.outOfCatalog)}
      >
        <BasicIconBtn
          $bgColor={theme.colors.semiTranspBg.grayLight}
          data-test-id="out-of-catalog-button"
        >
          <CartV2 color={theme.colors.gray7} />
        </BasicIconBtn>
      </TextTooltip>
    )
  }

  if (isUnitDisabled) {
    return (
      <TextTooltip
        placement="top-end"
        disabled={!isDesktop}
        content={formatMessage(messages.showReplacements)}
      >
        <RowIconBtn
          onClick={() => {
            dispatch(
              getReplacementsActions.delta({
                productId: product.id,
                showInModal: true,
              }),
            )
          }}
          data-test-id="replacements-button"
        >
          <Replace color={theme.colors.infoBlue2} />
        </RowIconBtn>
      </TextTooltip>
    )
  }

  return (
    <ProductCartControlsTooltip
      product={product}
      unitData={unitData}
      affectedElasticStock={affectedElasticStock}
      placement="top-end"
      isOpen={isTooltipOpen}
      targetStyles={{
        display: 'flex',
      }}
      onConfirmDateShift={() => {
        changeCartQuantity(affectedElasticStock?.requestedQuantity, {
          suppressElasticStockCheck: true,
        })
        setTooltipOpen(false)
      }}
      onCancel={() => {
        setTooltipOpen(false)
      }}
      onClosed={() => setAffectedElasticStock(null)}
    >
      <AddToCartBtn
        style={{ flex: 1, width: 'auto', padding: 0 }}
        disabled={isTooltipOpen}
        onAddToCart={() => {
          changeCartQuantity(historicalUnitData.quantity || 1)
        }}
        onDeleteFromCart={() => {
          changeCartQuantity(0)
        }}
        unitOfMeasure={unitData}
      />
    </ProductCartControlsTooltip>
  )
}

export default CartActionBtn
