import styled from 'styled-components'

import theme from 'theme'

import { TertiaryTextV2, H3v2, H4v2 } from 'components/Text'
import {
  CarouselOverflowContainer,
  CarouselScrollContainer,
} from 'components/Carousel/styles'
import { FilteredPageTileWrapper } from 'components/FilteredPageTile/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.sm} 0;
  // needed for catalog tiles edge to edge scroll
  > * {
    padding: 0 ${theme.spacing.sm};
  }
  gap: ${theme.spacing.sm};
`

export const ReturnButtonContainer = styled.div`
  display: flex;
  padding: ${theme.spacing.xxs} ${theme.spacing.xs};
  gap: ${theme.spacing.xs};
  align-items: center;
`

export const ReturnIconContainer = styled.div`
  padding: ${theme.spacing.xs};
`

export const ReturnText = styled(TertiaryTextV2).attrs({
  isSmall: { lineHeight: true },
})``

export const ScrollableContainer = styled.div`
  // 96px is sum of static elements heights that should not scroll
  height: ${p => `calc(100vh - ${p.$mobileMenuOffset}px - 96px)`};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`

export const TilesContainer = styled(CarouselScrollContainer)`
  display: flex;
  gap: ${theme.spacing.sm};
  > ${FilteredPageTileWrapper} {
    flex: 1 0 0;
    min-width: 147.5px;
  }

  @media screen and (min-width: 300px) {
    > ${FilteredPageTileWrapper} {
      flex-basis: 57%;
    }
  }

  @media screen and (min-width: 380px) {
    > ${FilteredPageTileWrapper} {
      flex-basis: 37%;
    }
  }

  @media screen and (min-width: 540px) {
    > ${FilteredPageTileWrapper} {
      flex-basis: 0;
    }
  }
`

export const TilesOverflowContainer = styled(CarouselOverflowContainer)`
  margin: 0 -${theme.spacing.sm};
  padding: 0 ${theme.spacing.md};
`

export const CategoryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.sm};
  gap: ${theme.spacing.xs};
`

export const CategoryTitleContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  min-width: 0;
`

export const TitleText = styled(H3v2)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const CategoryCountContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
`

export const CountText = styled(H4v2)`
  color: ${theme.colors.gray7};
`

export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacing.xs};
  border-top: 1px solid ${theme.colors.borderFaded};
`
