import React, { useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import { useNavigate, generatePath } from 'react-router-dom'
import { useIntl } from 'react-intl'

import {
  CategoryInfo,
  CloseSelectedCategoryButton,
  SCROLL_POSITIONS,
  SelectedCategory,
  SelectedCategoryName,
  SubcategoriesContainer,
  SubcategoriesList,
  SubcategoryItem,
  ViewFullButton,
  ViewFullText,
} from 'components/CategoriesDropdown/SubcategoriesSection/styles'
import { ArrowRightV2, X as XIcon } from 'components/Icons'
import messages from 'components/CategoriesDropdown/messages'
import { ROUTES } from 'consts'
import { CATEGORIES_MAP, DefaultIcon } from '../CategoriesIcons'

const SubcategoriesSection = ({ category, clearSelection }) => {
  const { formatMessage } = useIntl()
  const [scrollPosition, setScrollPosition] = useState(SCROLL_POSITIONS.TOP)
  const scrollRef = useRef()

  useEffect(() => {
    const scroll = scrollRef.current

    const handleScroll = throttle(e => {
      const { scrollTop, clientHeight, scrollHeight, scrollTopMax } = e.target
      const scrollMax = scrollTopMax || scrollHeight - clientHeight
      if (!scrollMax) {
        setScrollPosition(SCROLL_POSITIONS.NONE)
      } else if (scrollTop >= scrollMax - 20) {
        setScrollPosition(SCROLL_POSITIONS.BOTTOM)
      } else if (scrollTop === 0) {
        setScrollPosition(SCROLL_POSITIONS.TOP)
      } else if (scrollPosition !== SCROLL_POSITIONS.MID) {
        setScrollPosition(SCROLL_POSITIONS.MID)
      }
    }, 100)
    scroll.addEventListener('scroll', handleScroll)
    return () => {
      scroll.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(
    () => {
      const { clientHeight, scrollHeight, scrollTopMax } = scrollRef.current
      const scrollMax = scrollTopMax || scrollHeight - clientHeight
      if (!scrollMax) {
        setScrollPosition(SCROLL_POSITIONS.NONE)
      }
    },
    [category],
  )

  const navigate = useNavigate()
  const IconComponent = CATEGORIES_MAP[category.id] || DefaultIcon
  const handleGroupClick = group => {
    navigate(
      generatePath(ROUTES.PRODUCTS, {
        category: category.id,
        group: group?.id,
      }),
    )
  }
  return (
    <SubcategoriesContainer>
      <SelectedCategory $scrollPosition={scrollPosition}>
        <CategoryInfo>
          {IconComponent && <IconComponent />}
          <SelectedCategoryName>{category.name}</SelectedCategoryName>
        </CategoryInfo>
        <CloseSelectedCategoryButton
          onClick={clearSelection}
          data-test-id="cancel-subcategories-selection-button"
        >
          <XIcon />
        </CloseSelectedCategoryButton>
      </SelectedCategory>
      <SubcategoriesList
        scrollableNodeProps={{ ref: scrollRef }}
        $scrollPosition={scrollPosition}
        data-test-id="subcategories-list"
      >
        {category.groups.map(group => (
          <SubcategoryItem
            data-test-id={`subcategory-${group.id}`}
            key={group.id}
            onClick={() => handleGroupClick(group)}
          >
            <span>{group.name}</span>
            <span>{group.productCount}</span>
          </SubcategoryItem>
        ))}
      </SubcategoriesList>
      <ViewFullButton
        data-test-id="view-category-button"
        onClick={() => handleGroupClick()}
      >
        <ViewFullText>{formatMessage(messages.viewFull)}</ViewFullText>
        <ArrowRightV2 />
      </ViewFullButton>
    </SubcategoriesContainer>
  )
}

export default SubcategoriesSection
