import styled from 'styled-components'

import { TertiaryBtnV2 } from 'components/Button/styles'
import { APP_BREAKPOINTS } from 'consts'
import theme from 'theme'

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
  padding-top: ${theme.spacing.xs};

  text-wrap: wrap;
  text-align: start;

  font-size: 12px;
  line-height: 20px;

  h4 {
    line-height: 24px;
  }

  b,
  h4 {
    font-weight: 600;
  }
`

export const ReadButton = styled(TertiaryBtnV2)`
  height: 40px;
  width: 40px;
  :hover {
    background-color: ${theme.colors.greenLight5};
  }
`

export const Container = styled.div`
  background-color: ${p => !p.$isRead && theme.colors.lightGrey6};
  display: flex;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.sm} ${theme.spacing.base};
  cursor: pointer;

  :hover {
    background-color: ${theme.colors.semiTranspBg.grayLight};
  }

  svg {
    flex-shrink: 0;
  }

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding-top: ${theme.spacing.xs};
    padding-right: ${theme.spacing.sm};
  }
`

export const ContentContainer = styled.div`
  display: flex;
`

export const ActionsContainer = styled.div`
  width: 40px;
  flex-shrink: 0;
`

export const UnreadIndicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${theme.colors.secondary};
`
