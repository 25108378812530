import styled from 'styled-components'
import Modal from 'react-modal'

import theme from 'theme'

import ModalWrapper from '../ModalWrapper'

export const PortalModalOverlay = styled(ModalWrapper)`
  background: rgb(166 179 192 / 30%) !important;
  display: flex;
  align-items: end;

  @media screen and (min-width: ${p => p.$fullWidthBreakpoint}px) {
    align-items: center;
  }

  ${p => p.$overlayStyle};
`

export const ModalStyled = styled(Modal)`
  inset: auto;
  margin: 0;
  border: 0;
  box-shadow: 0px 12px 32px 0px #0c345929;
  padding: ${theme.spacing.sm};
  border-radius: 16px 16px 0 0;
  background-color: #fff;
  max-width: 100%;

  :focus-visible {
    outline: none;
  }

  @media screen and (min-width: ${p => p.$fullWidthBreakpoint}px) {
    max-width: 510px;
    width: 100%;
    margin: 0 ${theme.spacing.sm};
    border-radius: 16px;
    padding: ${theme.spacing.md};
  }
`
