import { combineReducers } from 'redux-immutable'
import { fromJS } from 'immutable'
import { get, findIndex } from 'lodash'
import { getOr, filter, compose } from 'lodash/fp'
import { handleActions } from 'redux-actions'

import { createReducer, reducer, initialState } from 'utils/simpleDataReducer'

import { resetProductUnitsOfMeasureInCart } from 'containers/Products/reducer'
import { deleteCartItem, updateCartItem } from '../Cart/actions'
import { templateStatusChange } from '../Templates/actions'

import { getOrderProductsActions } from './Details/actions'
import {
  ORDER_DETAILS_REDUCER_NAME,
  TRADE_LIMIT_REDUCER_NAME,
  ORDERS_LIST_ITEM_PRODUCTS_REDUCER_NAME,
} from './consts'
import { tradeLimitActions, orderActions } from './actions'

const makeProductReplacer = itemsKey => (state, product) => {
  const items = state.getIn(['data', itemsKey])
  if (items) {
    return state.setIn(
      ['data', itemsKey],
      items.map(item => {
        if (item.getIn(['product', 'id']) === product.id) {
          return item.set('product', fromJS(product))
        }

        return item
      }),
    )
  }

  return state
}

// eslint-disable-next-line default-param-last
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case orderActions.UPDATE: {
      const updatePath = ['data', 'orderItems']
      const collection = state.getIn(updatePath)
      const productId = get(action, 'additionalData.productId')
      const unitOfMeasure = get(action, 'additionalData.unitOfMeasure')
      const findByObj = { productId, unitOfMeasure }

      const itemIndex = productId
        ? findIndex(collection ? collection.toJS() : {}, findByObj)
        : -1

      return state
        .set('isFetching', false)
        .set('finished', true)
        .setIn([...updatePath, itemIndex], fromJS(action.data))
    }
    case deleteCartItem.SUCCESS: {
      const {
        additionalData: { productId },
      } = action

      const error = state.get('error')
      if (error) {
        const errorItems = compose(
          filter(product => product.productId !== productId),
          getOr([], 'items'),
        )(error)

        return state.set('error', { ...error, items: errorItems })
      }

      return state
    }
    case templateStatusChange.toString(): {
      return makeProductReplacer('orderItems')(state, action.payload)
    }
    default: {
      return reducer(state, action, orderActions)
    }
  }
}

const orderProductsReducer = createReducer(getOrderProductsActions)

const orderProductsHandlers = handleActions(
  {
    [templateStatusChange.toString()]: (state, action) =>
      makeProductReplacer('items')(state, action.payload),

    [updateCartItem.SUCCESS]: (state, { data: { cartItem } }) => {
      if (!cartItem) {
        return state
      }
      return makeProductReplacer('items')(state, cartItem.product)
    },

    [deleteCartItem.SUCCESS]: (
      state,
      { data: { cartItem }, additionalData },
    ) => {
      let updState = state

      if (cartItem) {
        return makeProductReplacer('items')(state, cartItem.product)
      }

      const items = state.getIn(['data', 'items']) || []
      items.forEach((item, itemIndex) => {
        const product = item.get('product')
        if (additionalData.productId === product.get('id')) {
          updState = updState.mergeIn(
            ['data', 'items', itemIndex, 'product', 'unitsOfMeasure'],
            resetProductUnitsOfMeasureInCart(
              product.get('unitsOfMeasure'),
              additionalData.unitOfMeasure,
            ),
          )
        }
      })

      return updState
    },
  },
  initialState,
)

const orderListItemProductsReducer = (state, action) => {
  const nextState = orderProductsReducer(state, action)
  return orderProductsHandlers(nextState, action)
}

export default combineReducers({
  [ORDER_DETAILS_REDUCER_NAME]: orderReducer,
  [TRADE_LIMIT_REDUCER_NAME]: createReducer(tradeLimitActions),
  [ORDERS_LIST_ITEM_PRODUCTS_REDUCER_NAME]: orderListItemProductsReducer,
})
