export const makeNumberLengthNormalizer = (
  minLength = 0,
  maxLength,
) => number => {
  if (!number && number !== 0) return number
  return String(number)
    .replace(/[^0-9]/g, '')
    .slice(minLength, maxLength)
}

export const makeMinMaxNormalizer = (min = 0, max) => number => {
  if (!number && number !== 0) return number

  if (number < min) return min
  if (max && number > max) return max

  return String(number).replace(/[^0-9]/g, '')
}
