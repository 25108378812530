import React from 'react'

const Meat = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.57931 15.0535C5.80074 16.1084 5.37988 16.0334 4.75016 15.9213C4.52588 15.8814 4.27511 15.8367 3.97225 15.8367C2.883 15.8367 2 16.7198 2 17.809C2 18.8983 2.883 19.7813 3.97225 19.7813C4.06178 19.7813 4.14993 19.7753 4.2363 19.7638C4.22474 19.8501 4.21878 19.9383 4.21878 20.0278C4.21878 21.1171 5.10178 22.0001 6.19102 22.0001C7.28027 22.0001 8.16338 21.1171 8.16338 20.0278C8.16338 20.0177 8.16326 20.0076 8.16304 19.9974C8.16126 19.88 8.14924 19.7651 8.12781 19.6536C8.12301 19.6218 8.11798 19.5897 8.11292 19.5574L8.11289 19.5572C8.01038 18.9027 7.89308 18.1538 9.27277 17.5625L9.11838 17.3502C8.49663 17.2636 7.8877 17.0865 7.30284 16.8047C7.22684 16.7681 7.16069 16.7282 7.10567 16.6848L7.09301 16.6983C7.06467 16.6684 7.03738 16.6373 7.01109 16.6049C6.97908 16.5759 6.94826 16.5458 6.91869 16.5147L6.93263 16.5002C6.79044 16.2952 6.69215 15.919 6.62624 15.4436C6.60822 15.3136 6.59259 15.1836 6.57931 15.0535Z"
      fill="#99D0AC"
    />
    <path
      className="icon-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6962 2.65053C12.3252 1.00415 16.691 2.7441 18.9433 4.95956C21.2335 7.33262 22.9946 11.7862 21.3649 13.4333C21.2146 13.5851 21.0375 13.7491 20.8396 13.9218C17.186 17.1098 12.2283 19.1299 7.47887 18.1526C7.01673 18.0575 6.64608 17.9285 6.41862 17.7563L6.40178 17.7736C6.41242 17.7627 6.38009 17.7345 6.34602 17.7047C6.32614 17.6874 6.30567 17.6695 6.29279 17.6543C6.25019 17.6173 6.20919 17.5789 6.16985 17.5392L6.1884 17.5207C6.01216 17.2768 5.8866 16.8432 5.79935 16.2942C5.0098 11.3259 7.15797 6.22656 10.6962 2.65053ZM13.1237 10.9723C15.4312 13.3195 18.341 14.1652 19.623 12.8612C20.905 11.5572 20.0736 8.59727 17.7661 6.25004C15.4585 3.90281 12.5487 3.05712 11.2667 4.36113C9.98474 5.66515 10.8161 8.62507 13.1237 10.9723Z"
      fill="#229944"
    />
    <ellipse
      cx="2.31474"
      cy="1.32271"
      rx="2.31474"
      ry="1.32271"
      transform="matrix(0.701419 0.712749 -0.701419 0.712749 14.7646 5.63647)"
      fill="#99D0AC"
    />
  </svg>
)

export default Meat
