import { compose } from 'lodash/fp'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/injectReducer'
import { bindActionCreators } from 'utils/redux-utils'

import { SETTINGS_REDUCER_NAME } from './consts'
import { settingsSelector } from './selectors'
import actions from './actions'
import reducer from './reducer'

export * from './consts'

export const mapStateToProps = createStructuredSelector({
  settings: settingsSelector,
})

export const mapDispatchToProps = bindActionCreators({
  getSettings: actions.delta,
  updateSettings: actions.updateDelta,
})

export default compose(
  injectReducer({ key: SETTINGS_REDUCER_NAME, reducer }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)
