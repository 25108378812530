import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import usePrevious from 'use-previous'

import { BaseTextV2Secondary, H2v2 } from 'components/Text'
import theme from 'theme'
import {
  orderProductsPaginationSelector,
  orderProductsSelector,
} from 'containers/Orders/Details/selectors'
import { getOrderProductsActions } from 'containers/Orders/Details/actions'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import { selectIsFetching } from 'hocs/selectors'
import {
  ORDER_INFO_REDUCER_NAME,
  ORDERS_LIST_ITEM_PRODUCTS_REDUCER_NAME,
} from 'containers/Orders'
import OrderItem from 'views/Orders/Details/OrderItemsList/OrderItem'
import RouteDeadlineBanner from './RouteDeadlineBanner'

import {
  OrderItemsListWrapper,
  OrderListHeaderWrapper,
  OrderListItemsWrapper,
  PaginationWrapper,
} from './styles'
import messages from '../messages'

const OrderItemsList = ({ orderId, orderDetails, isOrderDetailsFetching }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const orderItems = useSelector(orderProductsSelector)
  const { totalCount, currentPage, totalPages } = useSelector(
    orderProductsPaginationSelector,
  )

  const getOrderProducts = params => {
    dispatch(getOrderProductsActions.delta(params))
  }

  // TODO: try to get rid of usePrevious after moving endpoint to RTK
  const prevOrderId = usePrevious(orderId)
  useEffect(
    () => {
      if (!isOrderDetailsFetching && prevOrderId !== orderId) {
        getOrderProducts({ orderId })
      }
    },
    [prevOrderId, orderId, isOrderDetailsFetching],
  )
  const isFetching = useSelector(
    selectIsFetching([
      ORDER_INFO_REDUCER_NAME,
      ORDERS_LIST_ITEM_PRODUCTS_REDUCER_NAME,
    ]),
  )

  return (
    <OrderItemsListWrapper>
      <OrderListHeaderWrapper>
        <H2v2>{formatMessage(messages.orderedProducts)}</H2v2>
        {totalCount && (
          <BaseTextV2Secondary $fontWeight={theme.fontWeights.medium}>
            {`${totalCount} ${formatMessage(messages.numberOfProducts, {
              number: totalCount,
            })}`}
          </BaseTextV2Secondary>
        )}
      </OrderListHeaderWrapper>
      <RouteDeadlineBanner orderDetails={orderDetails} />
      {isFetching ? (
        <Loader />
      ) : (
        <>
          <OrderListItemsWrapper>
            {orderItems.map((orderItem, index) => (
              <OrderItem
                orderItem={orderItem}
                listIndex={index}
                key={`${orderItem.productId}-${orderItem.unit.name}`}
              />
            ))}
          </OrderListItemsWrapper>
          <PaginationWrapper>
            <Pagination
              current={currentPage}
              totalPages={totalPages}
              onChange={page => getOrderProducts({ orderId, page })}
            />
          </PaginationWrapper>
        </>
      )}
    </OrderItemsListWrapper>
  )
}

export default OrderItemsList
