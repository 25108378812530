import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { useGetOrderDetailsQuery } from 'containers/Orders/Details/rtkApi'
import { trackPageView, SCREENS } from 'services/analytics'
import LoaderFullHeight from 'components/LoaderFullHeight'
import OrderItemsList from './OrderItemsList'
import Breadcrumbs from './Breadcrumbs'
import Header from './Header'
import OrderActions from './OrderActions'
import PrimaryDetails from './PrimaryDetails'
import NotFound from './NotFound'

import {
  Container,
  TopSection,
  TopSectionInnerContainer,
  OrderDetailsDivider,
} from './styles'

const OrdersDetails = () => {
  const { orderId } = useParams()
  const location = useLocation()

  const userData = useSelector(userDataSelector)
  const notifProps = location.state?.notifProps

  const {
    data: orderDetails,
    isLoading: isOrderDetailsLoading,
    isFetching: isOrderDetailsFetching,
    error,
  } = useGetOrderDetailsQuery({ orderId, suppress404Redirect: !!notifProps })

  useEffect(() => trackPageView(SCREENS.ORDER_SUMMARY), [])

  if (isOrderDetailsLoading) return <LoaderFullHeight />

  const isNotFound = error?.status === 404
  if (isNotFound && notifProps) {
    return (
      <NotFound
        notifOrderNumber={notifProps.orderNumber}
        notifCustomerNo={notifProps.customerNo}
      />
    )
  }

  if (!orderDetails) return null

  return (
    <Container col="12">
      <TopSection data-test-id="order-details-top-section">
        <Breadcrumbs />
        <TopSectionInnerContainer>
          <Header orderDetails={orderDetails} />
          <OrderActions
            orderId={orderId}
            orderStatusId={orderDetails.orderStatusId}
            orderDetails={orderDetails}
          />
        </TopSectionInnerContainer>
      </TopSection>
      <OrderDetailsDivider />
      <PrimaryDetails userData={userData} orderDetails={orderDetails} />
      <OrderItemsList
        orderId={orderId}
        orderDetails={orderDetails}
        isOrderDetailsFetching={isOrderDetailsFetching}
      />
    </Container>
  )
}

export default OrdersDetails
