import React, { useMemo } from 'react'

import theme from 'theme'
import { TitleText, InlineTitleText } from './styledComponents'

export const HIGHLIGHT_MODE = {
  FILTER_FIRST: 'normal',
  FILTER_LAST: 'reverse',
}

const Title = ({ title: { firstLine, secondLine, mode } }) => {
  // using this instead of css as it's easier to test
  const firstLineCapitalized = useMemo(() => {
    if (!firstLine) {
      return null
    }

    return firstLine.charAt(0).toUpperCase() + firstLine.slice(1)
  })

  return (
    <TitleText>
      {!!firstLineCapitalized && (
        <InlineTitleText
          $textColor={
            mode === HIGHLIGHT_MODE.FILTER_FIRST ? theme.colors.gray7 : null
          }
        >
          {firstLineCapitalized}
        </InlineTitleText>
      )}
      {!!secondLine && (
        <InlineTitleText
          $textColor={
            mode === HIGHLIGHT_MODE.FILTER_FIRST ? null : theme.colors.gray7
          }
        >
          {secondLine}
        </InlineTitleText>
      )}
    </TitleText>
  )
}

export default Title
