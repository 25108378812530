import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { formatPrice } from 'utils'
import { FLOATING_PRICE_ORDER_STATUS } from 'views/Orders/consts'
import { FLOATING_PRICE_VARIANT } from 'components/FloatingPrice/consts'
import {
  FloatingPriceInfoBox,
  FloatingPriceWrapper,
} from 'components/FloatingPrice'

import messages from '../../messages'
import { ColumnHeader } from '../components/Column/styles'
import {
  OrderSummaryContainer,
  RowsContainer,
  OrderSummaryRow,
  TopOrderSummaryRow,
  BottomOrderSummaryRow,
  InnerContainer,
  RowLabel,
  RowText,
  BigRowLabel,
  BigRowText,
} from './styles'

const OrderSummary = ({ orderDetails }) => {
  const { formatMessage } = useIntl()

  const {
    orderPaymentMethod,
    totalNet,
    totalGross,
    anyFloatingPrice,
    orderStatusId,
  } = orderDetails

  const hasFloatingPrice =
    anyFloatingPrice && FLOATING_PRICE_ORDER_STATUS.includes(orderStatusId)

  return (
    <OrderSummaryContainer data-test-id="order-summary-column">
      <ColumnHeader>{formatMessage(messages.paymentSummary)}</ColumnHeader>
      <RowsContainer>
        <TopOrderSummaryRow>
          <RowLabel>{formatMessage(messages.payment)}</RowLabel>
          <RowText>{orderPaymentMethod}</RowText>
        </TopOrderSummaryRow>
        <InnerContainer>
          <OrderSummaryRow>
            <RowLabel>{formatMessage(messages.amountNet)}</RowLabel>
            <RowText>{formatPrice(totalNet)}</RowText>
          </OrderSummaryRow>
          <OrderSummaryRow>
            <RowLabel>{formatMessage(messages.orderVAT)}</RowLabel>
            <RowText>{formatPrice(totalGross - totalNet)}</RowText>
          </OrderSummaryRow>
          <OrderSummaryRow>
            <RowLabel>{formatMessage(messages.delivery)}</RowLabel>
            <RowText>{formatMessage(messages.free)}</RowText>
          </OrderSummaryRow>
        </InnerContainer>
        <BottomOrderSummaryRow>
          <BigRowLabel>{formatMessage(messages.amountGross)}</BigRowLabel>
          <FloatingPriceWrapper
            hasFloatingPrice={hasFloatingPrice}
            iconVariant={FLOATING_PRICE_VARIANT.EXTRA_SMALL}
            containerStyle={{
              alignItems: 'baseline',
            }}
          >
            <BigRowText>{formatPrice(totalGross)}</BigRowText>
          </FloatingPriceWrapper>
        </BottomOrderSummaryRow>
      </RowsContainer>
      {hasFloatingPrice && (
        <FloatingPriceInfoBox
          infoText={formatMessage(messages.floatingPriceInfo)}
          bgColor={theme.colors.lightGrey6}
          variant={FLOATING_PRICE_VARIANT.EXTRA_SMALL}
        />
      )}
    </OrderSummaryContainer>
  )
}

export default OrderSummary
