import styled, { css } from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { BaseTextV2 } from 'components/Text'
import { Button } from 'components/Button'
import { BUTTON_SIZE, BUTTON_TYPES } from 'components/Button/consts'
import PortalModal from '../PortalModal'

import { ModalHeader } from '../styles'

export const BUTTONS_VARIANT = {
  SELECT: 'select',
  DELETE: 'delete',
}

export const confirmationModalStyles = css`
  align-items: center;
  z-index: ${theme.zIndex.modalOverlay + 1};
`

export const ConfirmationModal = styled(PortalModal)`
  display: flex;
  flex-direction: column;
  margin: 0 ${theme.spacing.sm};
  border-radius: 16px;
  padding: ${theme.spacing.base};
  gap: ${theme.spacing.base};

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: ${theme.spacing.md};
    margin: 0;
    max-width: ${p => (p.$isWide ? 640 : 480)}px;
  }
`

export const ConfirmationModalHeader = styled(ModalHeader)`
  margin: 0;
`

export const ModalBtnsContainer = styled(Flex)`
  width: 100%;
  gap: 2px;
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    flex-direction: column;
  }
`

export const DescriptionText = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  overflow-wrap: break-word;
  white-space: break-spaces;
`

export const ModalBtn = styled(Button).attrs({
  size: BUTTON_SIZE.BASIC,
  type: BUTTON_TYPES.BASIC,
})`
  width: 100%;
  padding: 18px ${theme.spacing.sm};
  font-family: ${theme.fonts.primary};
  font-size: 13px;
  line-height: 20px;

  color: ${theme.colors.primary};
  background-color: ${theme.colors.lightGrey};

  :hover {
    background-color: ${theme.colors.lightGrey7};
  }
`

export const LeftBtn = styled(ModalBtn)`
  font-weight: 400;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0;
  }
`

export const RightBtn = styled(ModalBtn)`
  font-weight: 500;
  color: ${theme.colors.white};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  background-color: ${p =>
    p.$variant === BUTTONS_VARIANT.SELECT
      ? theme.colors.secondary
      : theme.colors.redLight};

  :hover {
    background-color: ${p =>
      p.$variant === BUTTONS_VARIANT.SELECT
        ? theme.colors.greenLight
        : theme.colors.redMainHover};
  }

  @media screen and (max-width: ${APP_BREAKPOINTS.DESKTOP_START - 1}px) {
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
  }

  :disabled {
    color: ${theme.colors.gray5};
    background-color: ${theme.colors.lightGrey};
    cursor: default;
  }
`

export const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
