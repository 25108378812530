import { makeMessages, makeTranslation } from 'utils'

const messages = {
  collectedFromTo: 'collectedFromTo',
  collectedFromToLong: 'collectedFromToLong',
  maxAvailableAmount: 'maxAvailableAmount',
  unavailableTill: { id: 'stock.unavailableTill' },
  unavailable: { id: 'stock.unavailable' },
  unavailableLong: { id: 'stock.unavailableLong' },
  unavailableLimited: { id: 'stock.unavailableLimited' },
  addedAmountText: { id: 'outOfStock.addedAmountText' },
  lackAmountText: { id: 'outOfStock.lackAmountText' },
  replacementButton: { id: 'prices.replacementButton' },
  removeFromCart: { id: 'prices.removeFromCart' },
  missingItemError: { id: 'cart.missingItemError' },
  addToTemplate: { id: 'productDetails.addToTemplate' },
  removeFromTemplate: { id: 'productDetails.removeFromTemplate' },
  promotion: { id: 'cart.promotion' },
  pricePlain: { id: 'cart.selfPromo.pricePlain' },
  pricePromo: { id: 'cart.selfPromo.pricePromo' },
  availableFrom: { id: 'stock.availableFrom' },
  elasticDeliveryOn: 'elasticDeliveryOn',
  elasticDeliveryOnLong: 'elasticDeliveryOnLong',
  tomorrow: { id: 'navbar.tomorrow' },
  today: { id: 'navbar.today' },
  availableForDelivery: 'availableForDelivery',
  inCartOnDate: 'inCartOnDate',
  addWithDeliveryOnDate: 'addWithDeliveryOnDate',
  chosenDelivery: 'chosenDelivery',
  missingLicense: { id: 'product.missingLicense' },
  missingLicenseTooltipText: { id: 'product.missingLicenseTooltipText' },
  toCart: { id: 'product.toCart' },
}

export default makeMessages(messages, makeTranslation('nonStock'))
