import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useLocation,
  useNavigate,
  generatePath,
  useMatch,
} from 'react-router-dom'

import {
  CategoriesActionsSection,
  CategoriesListSection,
  LoadingContainer,
  CategoriesContainer,
} from 'components/CategoriesDropdown/styles'
import { allCategoriesListSelector } from 'containers/Categories/selectors'
import FilteredPagesSection from 'components/CategoriesDropdown/FilteredPagesSection'
import SubcategoriesSection from 'components/CategoriesDropdown/SubcategoriesSection'
import { ROUTES } from 'consts'
import {
  ALL_CATEGORIES_LIST_REDUCER_NAME,
  CATEGORY_REDUCER_NAME,
} from 'containers/Categories'
import { selectIsFetching } from 'hocs/selectors'
import Loader from 'components/Loader'
import CategoryItem from './CategoryItem'

const Categories = () => {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [listHeight, setListHeight] = useState(0)

  const listRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()

  const categories = useSelector(allCategoriesListSelector)

  // '*' is a temporary workaround as useMatch doesn't work well with chained optional params
  // https://github.com/remix-run/react-router/discussions/9862

  const { params } = useMatch('/products/:category/*') || {}
  const currentCategory = useMemo(
    () => {
      if (location.search) {
        return null
      }

      const routeCategory = params?.category
      if (!routeCategory) {
        return null
      }

      return categories.find(category => category.id === routeCategory)
    },
    [params, categories],
  )

  useEffect(
    () => {
      setSelectedCategory(currentCategory)
    },
    [currentCategory],
  )

  const isCategoriesFetching = useSelector(
    selectIsFetching([CATEGORY_REDUCER_NAME, ALL_CATEGORIES_LIST_REDUCER_NAME]),
  )

  const handleCategoryClick = category => {
    if (selectedCategory?.id === category.id) {
      navigate(generatePath(ROUTES.PRODUCTS, { category: category.id }))
    } else {
      setSelectedCategory(category)
    }
  }

  useEffect(
    () => {
      setListHeight(listRef.current?.clientHeight)
    },
    [categories],
  )

  return (
    <CategoriesContainer>
      {isCategoriesFetching ? (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      ) : (
        <CategoriesListSection
          ref={listRef}
          data-test-id="categories-list"
          $splitColumns={categories?.length > 9}
        >
          {categories.map(category => (
            <CategoryItem
              key={category.id}
              active={category.id === selectedCategory?.id}
              category={category}
              onClick={() => handleCategoryClick(category)}
            />
          ))}
        </CategoriesListSection>
      )}
      <CategoriesActionsSection style={{ maxHeight: listHeight }}>
        {selectedCategory ? (
          <SubcategoriesSection
            clearSelection={() => setSelectedCategory(null)}
            category={selectedCategory}
          />
        ) : (
          <FilteredPagesSection />
        )}
      </CategoriesActionsSection>
    </CategoriesContainer>
  )
}

export default Categories
