import React from 'react'

import { CATEGORIES_MAP, DefaultIcon } from '../CategoriesIcons'
import { CategoryItemContainer, ItemName, ProductCount } from './styles'

const CategoryItem = ({
  category: { id, name, productCount },
  active = false,
  onClick,
}) => {
  const IconComponent = CATEGORIES_MAP[id] || DefaultIcon

  return (
    <CategoryItemContainer
      onClick={onClick}
      className={active && 'active'}
      data-test-id={`category-${id}`}
    >
      {IconComponent && <IconComponent />}

      <ItemName>{name}</ItemName>

      <ProductCount>{productCount}</ProductCount>
    </CategoryItemContainer>
  )
}

export default CategoryItem
