import {
  BestsellersTileImage,
  BestsellersTileWrapper,
  NoveltiesTileImage,
  NoveltiesTileWrapper,
  PromotionsTileImage,
  PromotionsTileWrapper,
  ClearancesTileImage,
  ClearancesTileWrapper,
} from 'components/CategoriesDropdown/FilteredPagesSection/styles'
import {
  ALL_BESTSELLERS_ORIGINS,
  ALL_NOVELTIES_ORIGINS,
  ALL_PROMO_ORIGINS,
  ALL_RECOMMENDED_ORIGINS,
  FILTER_EVENTS,
  trackEventWithOrigin,
} from 'services/analytics'
import bestsellers from 'components/FilteredPageTile/assets/Bestsellers.png'
import novelties from 'components/FilteredPageTile/assets/Novelties.png'
import promotions from 'components/FilteredPageTile/assets/Promotions.png'
import clearances from 'components/FilteredPageTile/assets/Clearance.png'
import {
  BASE_FILTERED_PAGE_VARIANTS,
  FILTERED_PAGE_MAP,
} from 'components/FilteredPageTile/consts'

export const DROPDOWN_FILTERED_PAGE_VARIANTS = {
  [FILTERED_PAGE_MAP.BESTSELLERS]: {
    ...BASE_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.BESTSELLERS],
    TileContainer: BestsellersTileWrapper,
    ImageContainer: BestsellersTileImage,
    imageSrc: bestsellers,
    clickEvent: () =>
      trackEventWithOrigin({
        event: FILTER_EVENTS.clickedBestsellers,
        origin: ALL_BESTSELLERS_ORIGINS.DROPDOWN,
      }),
  },
  [FILTERED_PAGE_MAP.NOVELTIES]: {
    ...BASE_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.NOVELTIES],
    TileContainer: NoveltiesTileWrapper,
    ImageContainer: NoveltiesTileImage,
    imageSrc: novelties,
    clickEvent: () =>
      trackEventWithOrigin({
        event: FILTER_EVENTS.clickedNovelties,
        origin: ALL_NOVELTIES_ORIGINS.DROPDOWN,
      }),
  },
  [FILTERED_PAGE_MAP.PROMOTIONS]: {
    ...BASE_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.PROMOTIONS],
    TileContainer: PromotionsTileWrapper,
    ImageContainer: PromotionsTileImage,
    imageSrc: promotions,
    clickEvent: () =>
      trackEventWithOrigin({
        event: FILTER_EVENTS.clickedPromotions,
        origin: ALL_PROMO_ORIGINS.DROPDOWN,
      }),
  },
  [FILTERED_PAGE_MAP.CLEARANCE]: {
    ...BASE_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.CLEARANCE],
    TileContainer: ClearancesTileWrapper,
    ImageContainer: ClearancesTileImage,
    imageSrc: clearances,
    clickEvent: () =>
      trackEventWithOrigin({
        event: FILTER_EVENTS.clickedClearances,
        origin: ALL_RECOMMENDED_ORIGINS.DROPDOWN,
      }),
  },
}
