import { rtkApi } from 'services/api/rtkApi'
import { getGAClientId } from 'utils/cookieUtils'
import { provideProductsQueryTags } from './utils'

const RECOMMENDED_PER_PAGE = 21

const products = rtkApi
  .enhanceEndpoints({
    addTagTypes: ['product'],
  })
  .injectEndpoints({
    endpoints: build => ({
      getRecommendations: build.query({
        query: ({ categoryId, groupId, subgroupId, pageName } = {}) => ({
          url: 'me/recommendations',
          params: {
            categoryId,
            groupId,
            subgroupId,
            pageName,
            // hardcoded as there's only one model currently
            model: 'RECOMMENDED_FOR_YOU',
            visitorId: getGAClientId(),
            perPage: RECOMMENDED_PER_PAGE,
            page: 1,
          },
        }),
        providesTags: result => provideProductsQueryTags(result),
      }),

      getProductRecommendations: build.query({
        query: ({ id }) => ({
          url: `me/products/${id}/recommendations`,
          params: {
            visitor_id: getGAClientId(),
            per_page: RECOMMENDED_PER_PAGE,
          },
        }),
        providesTags: result => provideProductsQueryTags(result),
      }),

      getProductReplacements: build.query({
        query: ({ id }) => ({
          url: `me/products/${id}/replacements`,
        }),
        providesTags: result => provideProductsQueryTags(result),
      }),
    }),
  })

export const {
  useLazyGetRecommendationsQuery,
  useLazyGetProductRecommendationsQuery,
  useLazyGetProductReplacementsQuery,
} = products

export const useGetProductRecommendationsState =
  rtkApi.endpoints.getProductRecommendations.useQueryState

export const useGetRecommendationsState =
  rtkApi.endpoints.getRecommendations.useQueryState
