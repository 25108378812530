import React from 'react'
import theme from 'theme'

const MailSmall = ({ color = theme.colors.primary }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1.75 11V5C1.75 4.30964 2.30964 3.75 3 3.75H13C13.6904 3.75 14.25 4.30964 14.25 5V11C14.25 11.6904 13.6904 12.25 13 12.25H3C2.30964 12.25 1.75 11.6904 1.75 11Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 4.5L6.58579 8.58579C7.36683 9.36683 8.63316 9.36684 9.41421 8.58579L13.5 4.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MailSmall
