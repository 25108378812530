import styled from 'styled-components'

import {
  BasicButton,
  GreyWrapper,
  PrimaryBtnV2,
} from 'components/Button/styles'
import BasicBtn from 'components/Button/ButtonV2'
import theme from 'theme'
import {
  BottomBar,
  CatalogContainer,
  TitleContainer,
} from 'components/Product/StockConfirmation/Confirmation/styles'
import { StockLimitedTooltipContainer } from 'components/Product/ButtonsContainer/styles'
import { BaseTextV2 } from 'components/Text'
import { DefaultPopoverContainer } from 'components/Popup/styles'

export const CartBtn = styled(PrimaryBtnV2)`
  flex: 1;
`

export const InfoBtn = styled(BasicBtn).attrs({
  $bgColor: theme.colors.infoBlue2,
  $hoverColor: theme.colors.infoBlue3,
})`
  flex: 1;
`

export const ElasticTooltipWrapper = styled(CatalogContainer)`
  ${TitleContainer} {
    max-width: none;
    margin: 0;
  }

  ${BottomBar} {
    max-width: none;
    flex-direction: column-reverse;
  }

  ${BasicButton} {
    justify-content: start;

    > span {
      max-width: none;
      font-weight: ${theme.fontWeights.medium};
    }
  }
`

export const StockLimitedContainer = styled(StockLimitedTooltipContainer)`
  ${DefaultPopoverContainer};

  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  ${BaseTextV2} {
    margin: 0;
    padding: ${theme.spacing.xxs};
  }

  ${BasicButton} {
    font-weight: ${theme.fontWeights.medium};
  }
  width: auto;
  height: auto;
`

export const ProductUnavailableBtn = styled(GreyWrapper)`
  cursor: auto;
  width: 100%;
  justify-content: center;
`

export const BtnText = styled(BaseTextV2)`
  font-weight: ${theme.fontWeights.medium};
`
