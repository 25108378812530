import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'lodash/fp'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import queryString from 'query-string'

import { withSpinner } from 'hocs'
import {
  AUTH_REDUCER_NAME,
  isSessionExpiredSelector,
  LOGIN_REDUCER_NAME,
  loginActions,
  signInErrorSelector,
  signInFetchingSelector,
} from 'containers/Auth'
import { SETTINGS_REDUCER_NAME } from 'containers/Settings'
import Logo from 'containers/App/LoggedInLayout/Header/Logo'
import { BaseTextV2, BaseTextV2Medium, Link } from 'components/Text'
import { BasicButtonV2 } from 'components/Button'
import { RESET_PASSWORD_ROUTES, ROUTES } from 'consts'
import { trackSignUpClicked } from 'services/analytics'

import FormInputField from 'components/Fields/FormInputField'
import { ACTION_TYPE } from 'components/ActionWrapper'
import { FooterLinkText } from 'containers/App/LoggedInLayout/Footer/styles'
import { CircleAlert, InfoV2 } from 'components/Icons'
import { ErrorText } from 'components/Fields/ValidationWrapper/styles'
import theme from 'theme'

import { useFirebaseBackgroundImage } from 'views/Login/utils'
import messages from './messages'
import {
  Wrapper,
  LoginWrapper,
  RegisterRow,
  ImgBg,
  ImgBgContainer,
  LoginActionsSection,
  ContactsSection,
  LoginTitle,
  FieldsContainer,
  LoginLink,
  CTAContainer,
  MessageLink,
  ContactLinksWrapper,
  LoginErrorContainer,
  SessionToast,
  ToastText,
} from './styles'

const useLoginFormValidation = () => {
  const { formatMessage } = useIntl()
  return ({ login = '', password = '' }) => {
    const errors = {}

    if (!login.trim()) {
      errors.login = formatMessage(messages.emptyField)
    }
    if (!password.trim()) {
      errors.password = formatMessage(messages.emptyField)
    }

    return errors
  }
}

const Login = () => {
  const bgImg = useFirebaseBackgroundImage()
  const isSessionExpired = useSelector(isSessionExpiredSelector)
  const isLoginFetching = useSelector(signInFetchingSelector)
  const dispatch = useDispatch()
  const location = useLocation()
  const { formatMessage } = useIntl()
  const validate = useLoginFormValidation()

  const { redirectTo } = queryString.parse(location.search)

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
    validateForm,
    setErrors,
  } = useFormik({
    // 'validateOnBlur' includes 'setFieldTouched', which we use for custom touched logic
    // leading to validation running on user's first input
    validateOnBlur: false,

    validateOnChange: false,
    initialValues: {
      login: '',
      password: '',
    },
    onSubmit: fieldValues =>
      dispatch(loginActions.delta(fieldValues, { redirectTo })),
    validate: fieldValues => validate(fieldValues),
  })

  const fetchError = useSelector(signInErrorSelector)
  const [loginError, setLoginError] = useState(false)

  useEffect(
    () => {
      if (fetchError) {
        setLoginError(true)
      }
    },
    [fetchError],
  )

  useEffect(
    () => {
      if (loginError) {
        setLoginError(false)
      }
    },
    [values],
  )

  const handleValueChange = e => {
    handleChange(e)
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: null })
    }
    !touched[e.target.name] && setFieldTouched(e.target.name)
  }

  const handleBlur = async e => {
    const formErrors = await validateForm(values)
    setErrors({ ...errors, [e.target.name]: formErrors[e.target.name] })
  }

  return (
    <LoginWrapper>
      <Wrapper onSubmit={handleSubmit}>
        <Logo isLoggedIn={false} />
        <LoginActionsSection>
          <LoginTitle data-test-id="login_title">
            {formatMessage(messages.header)}
          </LoginTitle>

          <FieldsContainer>
            {isSessionExpired && (
              <SessionToast>
                <InfoV2 color={theme.colors.white} />
                <ToastText>Sesja wygasła, wylogowano z systemu.</ToastText>
              </SessionToast>
            )}
            <FormInputField
              name="login"
              value={values.login}
              touched={touched.login}
              testId="login_input"
              containerTestId="login_container"
              placeholder={formatMessage(messages.usernameLabel)}
              handleChange={handleValueChange}
              handleBlur={handleBlur}
              errorText={errors.login}
              isError={loginError || (touched.login && errors.login)}
            />
            <FormInputField
              name="password"
              type="password"
              testId="password_input"
              containerTestId="password_container"
              value={values.password}
              touched={touched.password}
              placeholder={formatMessage(messages.passwordLabel)}
              handleChange={handleValueChange}
              handleBlur={handleBlur}
              errorText={errors.password}
              isError={loginError || (touched.password && errors.password)}
            />
            {loginError && (
              <LoginErrorContainer>
                <CircleAlert />
                <ErrorText>{formatMessage(messages.invalidCreds)}</ErrorText>
              </LoginErrorContainer>
            )}
            <LoginLink to={RESET_PASSWORD_ROUTES.INIT}>
              {formatMessage(messages.forgotPassword)}
            </LoginLink>
          </FieldsContainer>

          <CTAContainer>
            <BasicButtonV2
              disabled={isLoginFetching}
              data-test-id="login_submit"
            >
              {formatMessage(messages.submit)}
            </BasicButtonV2>
            <RegisterRow>
              <BaseTextV2>{formatMessage(messages.dontHaveAccount)}</BaseTextV2>
              <LoginLink
                to={ROUTES.SIGN_UP}
                data-test-id="register_link"
                onClick={() => trackSignUpClicked()}
              >
                {formatMessage(messages.register)}
              </LoginLink>
            </RegisterRow>
          </CTAContainer>
        </LoginActionsSection>
        <ContactsSection>
          <BaseTextV2Medium>
            {formatMessage(messages.needHelp)}
          </BaseTextV2Medium>

          <ContactLinksWrapper>
            <MessageLink
              value={formatMessage(messages.contactsPhone)}
              actionType={ACTION_TYPE.PHONE}
            >
              <FooterLinkText>
                {formatMessage(messages.contactsPhone)}
              </FooterLinkText>
            </MessageLink>
            <MessageLink
              value={formatMessage(messages.contactsMail)}
              actionType={ACTION_TYPE.EMAIL}
            >
              <FooterLinkText>
                {formatMessage(messages.contactsMail)}
              </FooterLinkText>
            </MessageLink>
            <Link to={ROUTES.CONTACT}>
              <FooterLinkText>
                {formatMessage(messages.contactUs)}
              </FooterLinkText>
            </Link>
          </ContactLinksWrapper>
        </ContactsSection>
      </Wrapper>

      <ImgBgContainer>
        <ImgBg data-test-id="background-image" img={bgImg} />
      </ImgBgContainer>
    </LoginWrapper>
  )
}

export default compose(
  withSpinner([[AUTH_REDUCER_NAME, LOGIN_REDUCER_NAME], SETTINGS_REDUCER_NAME]),
)(Login)
