import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'consts'
import { userDataSelector } from 'containers/UserInfo/selectors'
import FilteredPageTile from 'components/FilteredPageTile'
import { DROPDOWN_FILTERED_PAGE_VARIANTS } from 'components/CategoriesDropdown/FilteredPagesSection/consts'
import { FILTERED_PAGE_MAP } from 'components/FilteredPageTile/consts'
import { trackAllProductsClicked } from 'services/analytics'

import { BaseTextV2Medium } from 'components/Text'
import { DropdownItemContainer } from '../styles'
import { FooterContainer, PreselectedFilterPagesList } from './styles'
import messages from '../messages'

const FilteredPagesSection = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { offerDetails } = useSelector(userDataSelector)

  const handleMainPageClick = () => {
    navigate(ROUTES.CATEGORIES)
  }

  const handleAllProductsClick = () => {
    trackAllProductsClicked()
    navigate(ROUTES.ALL_PRODUCTS)
  }

  return (
    <>
      <PreselectedFilterPagesList>
        {offerDetails?.hasNovelties && (
          <FilteredPageTile
            variantConfig={
              DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.NOVELTIES]
            }
          />
        )}
        {offerDetails?.hasActiveClearances && (
          <FilteredPageTile
            variantConfig={
              DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.CLEARANCE]
            }
          />
        )}
        {offerDetails?.hasBestsellers && (
          <FilteredPageTile
            variantConfig={
              DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.BESTSELLERS]
            }
          />
        )}
        {offerDetails?.hasActivePromotions && (
          <FilteredPageTile
            variantConfig={
              DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.PROMOTIONS]
            }
          />
        )}
      </PreselectedFilterPagesList>
      <FooterContainer>
        <DropdownItemContainer
          onClick={handleMainPageClick}
          data-test-id="main-page-link"
        >
          <BaseTextV2Medium>
            {formatMessage(messages.mainPageLink)}
          </BaseTextV2Medium>
        </DropdownItemContainer>
        <DropdownItemContainer
          onClick={handleAllProductsClick}
          data-test-id="all-products-link"
        >
          <BaseTextV2Medium>
            {formatMessage(messages.allProducts)}
          </BaseTextV2Medium>
        </DropdownItemContainer>
      </FooterContainer>
    </>
  )
}

export default FilteredPagesSection
