import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  DashboardTileActionButton,
  DashboardTileContainer,
  DashboardTileContentWrapper,
  DashboardTileFooterWrapper,
  DashboardTileHeaderContentWrapper,
  DashboardTileHeaderIconWrapper,
  DashboardTileHeaderSubtitle,
  DashboardTileHeaderTitle,
  DashboardTileHeaderWrapper,
} from 'components/DashboardTile/styles'
import { ArrowDownWide } from 'components/Icons'
import { dashboardTilesDisplaySelector } from 'containers/App/selectors'
import { setDashboardTiles } from 'containers/App/actions/dashboardTiles'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'

const DashboardTile = ({
  title,
  subtitle,
  content,
  children,
  footer,
  actionButtonIcon,
  onActionButtonClick,
  disableCollapse,
  ContainerComponent = DashboardTileContainer,
  containerTestId = 'dashboard-tile-container',
  headerTestId = 'dashboard-tile-header',
  contentTestId = 'dashboard-tile-content',
  footerTestId = 'dashboard-tile-footer',
  onExpand = () => {},
  onCollapse = () => {},
}) => {
  const dispatch = useDispatch()
  const [isOpened, setOpened] = useState(false)
  const isOpenedGlobally = useSelector(dashboardTilesDisplaySelector)

  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  const isContentOpened = useMemo(
    () => {
      if (isTablet) {
        return isOpenedGlobally
      }
      return isOpened
    },
    [isOpened, isOpenedGlobally, isTablet],
  )

  const handleCollapseBtnClick = useCallback(
    () => {
      if (isContentOpened) {
        onCollapse()
      } else {
        onExpand()
      }

      if (isTablet) {
        dispatch(setDashboardTiles(!isContentOpened))
      } else {
        setOpened(!isOpened)
      }
    },
    [isContentOpened, isOpened, isTablet],
  )

  const expandHandler = useCallback(() => {
    setOpened(true)
    if (isTablet) {
      dispatch(setDashboardTiles(true))
    }
    onExpand()
  }, [])

  return (
    <ContainerComponent data-test-id={containerTestId}>
      <DashboardTileHeaderWrapper data-test-id={headerTestId}>
        {actionButtonIcon && (
          <DashboardTileActionButton onClick={onActionButtonClick}>
            {actionButtonIcon}
          </DashboardTileActionButton>
        )}
        <DashboardTileHeaderContentWrapper>
          <DashboardTileHeaderTitle>{title}</DashboardTileHeaderTitle>
          <DashboardTileHeaderSubtitle>{subtitle}</DashboardTileHeaderSubtitle>
        </DashboardTileHeaderContentWrapper>
        {!disableCollapse && (
          <DashboardTileHeaderIconWrapper
            data-test-id="tile-collapse-button"
            $isContentOpened={isContentOpened}
            onClick={handleCollapseBtnClick}
          >
            <ArrowDownWide />
          </DashboardTileHeaderIconWrapper>
        )}
      </DashboardTileHeaderWrapper>
      {(children || content) && (
        <DashboardTileContentWrapper
          data-test-id={contentTestId}
          $isContentOpened={isContentOpened}
          $disableCollapse={disableCollapse}
        >
          {children || content}
        </DashboardTileContentWrapper>
      )}
      {footer && (
        <DashboardTileFooterWrapper data-test-id={footerTestId}>
          {React.cloneElement(footer, {
            expandTile: expandHandler,
            isContentOpened,
          })}
        </DashboardTileFooterWrapper>
      )}
    </ContainerComponent>
  )
}

export default DashboardTile
