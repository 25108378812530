import styled from 'styled-components'

import { APP_BREAKPOINTS } from 'consts'
import { SecondaryBtnV2 } from 'components/Button/styles'
import ProductImage from 'components/Product/ProductImage'
import theme from 'theme'

export const RowIconBtn = styled(SecondaryBtnV2)`
  padding: ${theme.spacing.xs};
  height: 40px;
  :disabled {
    background-color: ${theme.colors.semiTranspBg.grayLight};
  }
`

export const OrderItemActionsWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    flex-direction: row-reverse;
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    gap: ${theme.spacing.ss};
  }
  > * {
    flex: 1;
  }
`

export const OrderItemAmountWrapper = styled.div`
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    margin-left: auto;
    margin-right: 80px;
  }
`

export const OrderItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 112px 1fr;
  grid-column-gap: ${theme.spacing.ss};
  grid-row-gap: 10px;
  cursor: pointer;
  border-radius: ${theme.borderRadius.base};
  padding: ${theme.spacing.vertical} 0;

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    grid-template-columns: 48px 1fr 1fr 88px;
    align-items: center;
    :hover {
      background-color: ${theme.colors.semiTranspBg.grayLight};

      ${RowIconBtn}:enabled {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        background-color: ${theme.colors.white};
        :hover {
          background-color: ${theme.colors.semiTranspBg.grayLight};
          padding: 0 ${theme.spacing.xxs};
          border: 4px solid ${theme.colors.white};
        }
      }
    }
    ${OrderItemAmountWrapper} {
      grid-area: 1 / 3;
    }
    ${OrderItemActionsWrapper} {
      grid-area: 1 / 4;
    }

    padding: ${theme.spacing.sm};
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    grid-template-columns: 48px 2fr 1fr 92px;
  }
`

export const OrderItemImageWrapper = styled.div`
  display: flex;
  justify-self: center;
  max-height: 72px;
`

export const OrderItemImage = styled(ProductImage)`
  mix-blend-mode: multiply;
`

export const OrderItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xxs};
`

export const RewardBadgeContainer = styled.div`
  display: flex;
  border-radius: 6px;
  padding: 0 ${theme.spacing.xxs};
  background-color: ${theme.colors.greenLight5};
  align-self: start;
`
