import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { Document as DocumentIcon } from 'components/Icons'
import ContactFormModalWrapper from 'components/ContactForm/ContactFormModal/Wrapper'

import messages from '../messages'
import { OtherRowContainer } from '../styles'
import OtherRow from '../OtherRow'

const ContactForm = () => {
  const { formatMessage } = useIntl()
  const [isContactFormOpen, setContactFormOpen] = useState(false)

  return (
    <ContactFormModalWrapper
      isContactFormOpen={isContactFormOpen}
      onContactFormClose={() => setContactFormOpen(false)}
    >
      <OtherRowContainer
        onClick={() => setContactFormOpen(true)}
        data-test-id="contact-form-row"
      >
        <OtherRow
          text={formatMessage(messages.contactForm)}
          icon={<DocumentIcon />}
        />
      </OtherRowContainer>
    </ContactFormModalWrapper>
  )
}

export default ContactForm
