import styled from 'styled-components'

import theme from 'theme'
import { BaseTextV2, TinyTextV2Secondary } from 'components/Text'
import { DropdownItemContainer } from '../styles'

export const ItemName = styled(BaseTextV2)`
  font-weight: ${theme.fontWeights.medium};
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ProductCount = styled(TinyTextV2Secondary)``

export const CategoryItemContainer = styled(DropdownItemContainer)`
  padding: ${theme.spacing.xs} ${theme.spacing.ss};
  align-items: center;
  gap: ${theme.spacing.ss};

  &:active,
  &.active {
    background-color: ${theme.colors.secondary};

    .icon-fill {
      fill: ${theme.colors.white};
    }

    ${ItemName} {
      color: ${theme.colors.white};
    }

    ${ProductCount} {
      color: ${theme.colors.white};
    }
  }
  &.active:hover {
    background-color: ${theme.colors.greenLight};
  }
`
