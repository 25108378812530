import React from 'react'

const NonFood = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3508 9.60201C13.3426 9.91966 13.2015 10.2261 12.9619 10.4468L10.306 12.8919L12.1081 14.694L14.5532 12.0381C14.7739 11.7985 15.0803 11.6574 15.398 11.6492L16.834 11.6124C17.1346 11.6047 17.4259 11.4779 17.6438 11.26L21.8236 7.08021C22.0505 6.85326 22.0597 6.4945 21.8441 6.2789C21.6285 6.0633 21.2698 6.0725 21.0428 6.29945L17.7554 9.58685C17.5285 9.8138 17.1697 9.823 16.9541 9.6074C16.7385 9.3918 16.7477 9.03304 16.9747 8.8061L20.2621 5.51869C20.489 5.29174 20.4982 4.93299 20.2826 4.71739C20.067 4.50179 19.7083 4.51099 19.4813 4.73793L16.1939 8.02534C15.967 8.25229 15.6082 8.26148 15.3926 8.04588C15.177 7.83028 15.1862 7.47153 15.4131 7.24458L18.7006 3.95717C18.9275 3.73023 18.9367 3.37147 18.7211 3.15587C18.5055 2.94027 18.1467 2.94947 17.9198 3.17642L13.74 7.35618C13.5221 7.57414 13.3953 7.86544 13.3876 8.16599L13.3508 9.60201Z"
      fill="#99D0AC"
    />
    <path
      d="M10.1023 16.8725C10.4655 16.478 10.4529 15.8673 10.0738 15.4881L9.51188 14.9262C9.13271 14.5471 8.52195 14.5345 8.12745 14.8977L4.38928 18.3393C3.9025 18.7874 3.86724 19.5324 4.31175 19.9769L5.02314 20.6883C5.46765 21.1328 6.21255 21.0975 6.66071 20.6107L10.1023 16.8725Z"
      fill="#99D0AC"
    />
    <path
      className="icon-fill"
      d="M3.0495 3.19891C2.66056 2.8404 2.05319 2.99095 2.01653 3.45496C1.94643 4.34204 2.09991 5.2506 2.46519 6.11104L2.82278 6.95336C3.42431 8.37028 4.45131 9.62956 5.77546 10.5738C6.73516 11.2582 7.8245 11.7581 8.97052 12.0402L9.14607 12.0834C9.43779 12.1552 9.70706 12.3168 9.90653 12.5399L17.1085 20.5022C17.6137 21.0674 18.4826 21.168 18.9709 20.718L19.6941 20.0514C20.1465 19.6343 20.0907 18.9067 19.5693 18.4261L3.0495 3.19891Z"
      fill="#229944"
    />
  </svg>
)

export default NonFood
