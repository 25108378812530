import React from 'react'
import {
  ThresholdFont,
  ProgressInfoContainer,
} from 'components/ValuePromoProgressBar/styles'
import { formatPrice } from 'utils'
import SplittableProgressBar from 'components/SplittableProgressBar'
import { CheckV3 } from 'components/Icons'
import theme from 'theme'
import { MaxIconContainer } from 'components/Product/PromoGroup/PrizeRow/PrizeProgress/styles'

const ValuePromoProgressInfo = ({ rewardDetails }) =>
  rewardDetails.maxApplicationsReached && !rewardDetails.toCollect ? (
    <MaxIconContainer>
      <CheckV3 color={theme.colors.white} />
    </MaxIconContainer>
  ) : (
    <ProgressInfoContainer>
      <ThresholdFont>
        {formatPrice(rewardDetails.threshold, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </ThresholdFont>
      <SplittableProgressBar
        threshold={rewardDetails.threshold}
        progress={rewardDetails.thresholdProgression}
        totalProgress={rewardDetails.thresholdDelta}
        maxReached={rewardDetails.maxApplicationsReached}
        toCollect={rewardDetails.toCollect}
      />
    </ProgressInfoContainer>
  )

export default ValuePromoProgressInfo
