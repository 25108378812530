import React from 'react'
import { FormattedMessage } from 'react-intl'

import { P, InlineText } from 'components/Text'

import Notification from '../Notification'
import messages from '../Notification/messages'

const Succeed = ({ email }) => (
  <Notification header={messages.header}>
    <P>
      <InlineText bold>
        <FormattedMessage {...messages.toContinue} values={{ value: email }} />
      </InlineText>
      &nbsp;
      <InlineText>
        <FormattedMessage {...messages.toDoThis} />
      </InlineText>
    </P>
  </Notification>
)

export default Succeed
