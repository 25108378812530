import React, { forwardRef, useEffect } from 'react'
import usePrevious from 'use-previous'

import Carousel from 'components/Carousel'
import { ProductBox } from 'components/Product'
import { trackViewItemList } from 'services/analytics'
import { ProductTileMultipleSkeleton } from 'components/Skeletons/ProductTileSkeleton'

import { CarouselContainer, CarouselTitle } from './styles'
import SkeletonBlock from '../../../components/Skeletons/SkeletonBlock'

export const PRODUCT_CAROUSEL_ID = 'suggestions-section'

const ProductCarousel = (
  {
    products,
    isLoading,
    itemModelId,
    attributionToken,
    itemListId,
    itemListName,
    title,
    Container = CarouselContainer,
    testId = 'products-carousel',
  },
  ref,
) => {
  const prevIsLoading = usePrevious(isLoading)

  useEffect(
    () => {
      if (!isLoading && prevIsLoading) {
        trackViewItemList({
          products,
          itemListId,
          itemListName,
          itemModelId,
        })
      }
    },
    [isLoading, prevIsLoading],
  )

  if (!isLoading && !products.length) {
    return null
  }

  return (
    <Container ref={ref} id={PRODUCT_CAROUSEL_ID} data-test-id={testId}>
      {isLoading ? <SkeletonBlock /> : <CarouselTitle>{title}</CarouselTitle>}

      <Carousel>
        {isLoading ? (
          <ProductTileMultipleSkeleton length={7} />
        ) : (
          products.map((product, index) => (
            <ProductBox
              key={product.id}
              {...product}
              index={index}
              product={product}
              itemListId={itemListId}
              itemListName={itemListName}
              itemModelId={itemModelId}
              attributionToken={attributionToken}
            />
          ))
        )}
      </Carousel>
    </Container>
  )
}

export default forwardRef(ProductCarousel)
