import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { userDataSelector } from 'containers/UserInfo/selectors'
import { trackSimpleEvent, NAV_BAR_EVENTS } from 'services/analytics'
import { UserMenu as UserMenuIcon } from 'components/Icons'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'
import { HEADER_DROPDOWN_TRANSITIONS } from 'components/Popup/transitions'
import ContactFormModalWrapper from 'components/ContactForm/ContactFormModal/Wrapper'

import UserMenu from '../UserMenu'
import {
  UserIconContainer,
  UserIconCustomerNo,
  UserMenuContainer,
} from './styles'

const User = ({ userBtnStyles }) => {
  const { customerNo } = useSelector(userDataSelector)
  const [isContactFormOpen, setContactFormOpen] = useState(false)

  const toggleDropdown = () => {
    trackSimpleEvent(NAV_BAR_EVENTS.USER_MENU_CLICKED)
  }

  return (
    <>
      <ContactFormModalWrapper
        isContactFormOpen={isContactFormOpen}
        onContactFormClose={() => setContactFormOpen(false)}
      />
      <Popover
        disableArrow
        placement="bottom-end"
        offsetValues={{
          main: 20,
        }}
      >
        <PopoverTrigger asChild>
          <UserIconContainer
            onClick={toggleDropdown}
            data-test-id="user-menu"
            userBtnStyles={userBtnStyles}
          >
            <UserMenuIcon />
            {customerNo && (
              <UserIconCustomerNo>{customerNo}</UserIconCustomerNo>
            )}
          </UserIconContainer>
        </PopoverTrigger>
        <PopoverContent withOverlay transitions={HEADER_DROPDOWN_TRANSITIONS}>
          <UserMenuContainer data-test-id="user-menu-dropdown">
            <UserMenu openContactForm={() => setContactFormOpen(true)} />
          </UserMenuContainer>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default User
