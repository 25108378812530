import React from 'react'
import Select, { components } from 'react-select' // eslint-disable-line import/no-named-default
import { fromJS } from 'immutable'

import { ArrowDown as ArrowDownIcon } from 'components/Icons'
import { SelectContainer } from './styledComponents'
import { getBorderType } from '../../Input/TextInput'

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <ArrowDownIcon fill="rgba(60, 60, 60, 0.6)" />
  </components.DropdownIndicator>
)

const SelectComponent = ({
  input,
  input: { value: reduxValue, onBlur },
  meta,
  options,
  placeholder = '',
  formatValueLabel,
  components: selectComponents = {},
  isSearchable = true,
  handleChange,
  ...rest
}) => {
  const value = reduxValue && reduxValue.toJS ? reduxValue.toJS() : {}
  const selectValue = formatValueLabel
    ? { ...value, label: formatValueLabel(value) }
    : value

  const onChange = valueObj => {
    input.onChange(fromJS(valueObj))
    if (handleChange) handleChange(valueObj)
  }

  return (
    <SelectContainer borderType={getBorderType(meta)} {...rest}>
      <Select
        classNamePrefix="select"
        components={{ DropdownIndicator, ...selectComponents }}
        {...input}
        onBlur={() => onBlur()}
        onChange={onChange}
        value={selectValue}
        placeholder={placeholder}
        options={options}
        isSearchable={isSearchable}
      />
      {meta.touched &&
        (meta.error && <p className="help is-danger">{meta.error}</p>)}
    </SelectContainer>
  )
}

export default SelectComponent
