import { rtkApi } from 'services/api/rtkApi'

import { ORDERS_LIST_TAG_TYPE } from '../List/rtkApi'

const ORDER_TAG_TYPE = 'Order'

export const orderApi = rtkApi
  .enhanceEndpoints({
    addTagTypes: [ORDER_TAG_TYPE],
  })
  .injectEndpoints({
    endpoints: build => ({
      getOrderDetails: build.query({
        query: ({ orderId, suppress404Redirect }) => ({
          url: `me/orders/${orderId}`,
          meta: {
            suppress404Redirect,
          },
        }),
        providesTags: (result, error, params) => [
          { type: ORDER_TAG_TYPE, id: params.orderId },
        ],
      }),
      cancelOrder: build.mutation({
        query: ({ orderId, affectedOrderIds, reasons }) => ({
          url: `me/orders/${orderId}/cancelation`,
          method: 'POST',
          data: { affectedOrderIds, reasons },
        }),
        invalidatesTags: (result, error, params) => {
          const affectedOrdersTags = params.affectedOrderIds.map(id => ({
            type: ORDER_TAG_TYPE,
            id: String(id),
          }))

          return [
            ...affectedOrdersTags,
            { type: ORDER_TAG_TYPE, id: params.orderId },
            ORDERS_LIST_TAG_TYPE,
          ]
        },
      }),
      getAffectedOrders: build.query({
        query: ({ orderId }) => ({
          url: `me/orders/${orderId}/cancelation/affected_orders`,
        }),
      }),
    }),
  })

export const {
  useGetOrderDetailsQuery,
  useCancelOrderMutation,
  useGetAffectedOrdersQuery,
} = orderApi
