import * as yup from 'yup'

import validationMessages from 'utils/validators/messages'

export default yup.object().shape({
  email: yup
    .string()
    .email(validationMessages.emailFormat)
    .required(validationMessages.required),
})
