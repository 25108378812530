import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  description: 'description',
  contactData: 'contactData',
  deliveryAddress: { id: 'orderDetails.deliveryAddress' },
  customerNo: 'customerNo',
  customerName: 'customerName',
  customerFirstName: 'customerFirstName',
  customerLastName: 'customerLastName',
  phoneNo: 'phoneNo',
  customerVatId: 'customerVatId',
  emailAddress: 'emailAddress',
  deliveryPostCode: 'deliveryPostCode',
  deliveryCountryCode: 'deliveryCountryCode',
  deliveryCity: 'deliveryCity',
  submit: 'submit',
  agreement_1: 'agreement1',
  optional: 'optional',
  agreement_2: 'agreement2',
  acceptMarketing: 'acceptMarketing',
  agreement_3: 'agreement3',
  emptyField: { id: 'login.emptyField' },
  invalidEmail: 'error.invalidEmail',
  invalidVatId: 'error.invalidVatId',
  required: 'required',
  emailUsed: 'emailUsed',
  emailUsedForReg: 'emailUsedForReg',
  emailNotVerified: 'emailNotVerified',
  emailUsedForRegMsg: 'emailUsedForRegMsg',
  accountExists: 'accountExists',
  backToForm: 'backToForm',
  toLogin: { id: 'setPassword.toLogin' },
  phoneInvalid: 'phoneInvalid',
  somethingWentWrong: { id: 'modal.somethingWentWrong' },
  termsLink: 'termsLink',
  privacyLink: 'privacyLink',
  login: 'login',
  loginTxt: 'loginTxt',
  loginInvalid: 'error.invalidLogin',
  blank: { id: 'validators.blank' },
  loginAlreadyTaken: 'error.loginTaken',
  tooShort: { id: 'password.tooShort' },
}

export default makeMessages(messages, makeTranslation('signUp'))
