import queryString from 'query-string'

import { ORDERS_PER_PAGE_SIZE } from '../consts'

const url = 'me/orders'

export const getOrderProducts = ({
  orderId,
  perPage = ORDERS_PER_PAGE_SIZE,
  page = 1,
} = {}) => {
  const queryParams = queryString.stringify({
    per_page: perPage,
    page,
  })

  return {
    url: `${url}/${orderId}/items?${queryParams}`,
  }
}

export const sendComplaintForm = ({ body, id }) => {
  const options = {
    method: 'POST',
    body,
    headers: {
      omitContentType: true,
    },
  }

  return { url: `${url}/${id}/complaints`, options }
}

export const sendFeedbackForm = ({ body, id }) => {
  const options = {
    method: 'POST',
    body,
  }

  return { url: `${url}/${id}/feedbacks`, options }
}
