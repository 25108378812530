import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import { trackAddedAllFromTemplate } from 'services/analytics'
import { addTemplateToCartActions } from 'containers/Templates/actions'
import Confirmation from 'components/modal/Confirmation'
import { BUTTONS_VARIANT } from 'components/modal/Confirmation/styles'

import { TemplateAddProductsButton, TemplateAddProductsText } from './styles'
import messages from '../messages'

const AddTemplateToCartButton = ({
  id,
  name,
  icon,
  type,
  isActive,
  dataTestId = 'template_add_all',
  clearSearch,
}) => {
  const [isAddConfirmOpen, setAddConfirmOpen] = useState(false)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const addTemplateToCart = () =>
    dispatch(
      addTemplateToCartActions.delta(
        { id, templateName: name, type },
        {
          successCallback: () => {
            trackAddedAllFromTemplate(type, id)
            clearSearch()
          },
          successMessage: formatMessage(messages.addedToCartNotification, {
            title: name,
          }),
          errorMessage: messages.addedToCartNotificationError,
        },
      ),
    )

  const handleConfirm = () => {
    addTemplateToCart()
    setAddConfirmOpen(false)
  }

  return (
    <>
      <Confirmation
        isOpen={isAddConfirmOpen}
        onClose={() => setAddConfirmOpen(false)}
        onConfirm={handleConfirm}
        headingText={formatMessage(messages.addAllModalHeader)}
        rightBtnText={formatMessage(messages.addAllModalConfirm)}
        leftBtnText={formatMessage(messages.cancel)}
        rightBtnTestId="template_remove_confirm"
        btnsVariant={BUTTONS_VARIANT.SELECT}
      />
      <TemplateAddProductsButton
        data-test-id={isActive ? dataTestId : `${dataTestId}_disabled`}
        onClick={() => setAddConfirmOpen(true)}
        disabled={!isActive}
        icon={icon}
      >
        <TemplateAddProductsText>
          {formatMessage(messages.addAll)}
        </TemplateAddProductsText>
      </TemplateAddProductsButton>
    </>
  )
}

export default AddTemplateToCartButton
