import { createSelector } from 'reselect'

import { genericSelector, makeIsFetchingSelector } from 'utils/redux-utils'

import { SETTINGS_REDUCER_NAME } from './consts'

export const { data: dataSelector } = genericSelector(SETTINGS_REDUCER_NAME)

export const settingsSelector = createSelector(dataSelector, data => data || {})

export const isSettingsFetching = makeIsFetchingSelector(SETTINGS_REDUCER_NAME)
