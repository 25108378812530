import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Link, BrandedLink } from 'components/Text'
import { ROUTES } from 'consts'
import { ACCOUNT_ERRORS } from '../../containers/Auth/consts'

import messages from './messages'
import {
  ErrorModalP,
  StyledButton,
  ModalWrapper,
  ErrorModalH,
} from './styledComponents'

const contentMapper = {
  header: {
    [ACCOUNT_ERRORS.EXISTS]: 'accountExists',
    [ACCOUNT_ERRORS.NOT_VERIFIED]: 'emailUsedForReg',
    [ACCOUNT_ERRORS.REGISTRATION_IN_PROGRESS]: 'emailUsedForReg',
  },
  subHeader: {
    [ACCOUNT_ERRORS.EXISTS]: 'emailUsed',
    [ACCOUNT_ERRORS.NOT_VERIFIED]: 'emailNotVerified',
    [ACCOUNT_ERRORS.REGISTRATION_IN_PROGRESS]: 'emailUsedForRegMsg',
  },
}

const ErrorModal = ({ modal: { closeModal }, modal: { errorCode } }) => {
  const headerMsg = contentMapper.header[errorCode] || 'somethingWentWrong'
  const subHeaderMsg = contentMapper.subHeader[errorCode]
  const accountExists = errorCode === ACCOUNT_ERRORS.EXISTS

  return (
    <ModalWrapper>
      <ErrorModalH>
        <FormattedMessage {...messages[headerMsg]} />
      </ErrorModalH>
      <ErrorModalP>
        {subHeaderMsg && <FormattedMessage {...messages[subHeaderMsg]} />}
      </ErrorModalP>
      {accountExists && (
        <Link to={ROUTES.LOGIN}>
          <StyledButton onClick={closeModal}>
            <FormattedMessage {...messages.toLogin} />
          </StyledButton>
        </Link>
      )}
      <BrandedLink to="#" onClick={closeModal}>
        <FormattedMessage {...messages.backToForm} />
      </BrandedLink>
    </ModalWrapper>
  )
}

export default ErrorModal
