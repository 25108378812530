import React from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import { P } from 'components/Text'
import { ErrorBtn, AlternateBtn, BUTTON_SIZE } from 'components/Button'
import { clearCartAction } from 'containers/Cart/actions'

import messages from '../messages'
import { ButtonsWrapper } from './styles'

const RemoveProductsModal = ({ modal: { closeModal } }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const handleConfirmRemoval = () => {
    dispatch(clearCartAction())
    closeModal()
  }

  return (
    <React.Fragment>
      <P>
        <FormattedMessage {...messages.removeConfirmationDialog} />{' '}
      </P>
      <ButtonsWrapper>
        <ErrorBtn
          data-test-id="cart_remove_all_confirm"
          onClick={handleConfirmRemoval}
          size={BUTTON_SIZE.SMALL}
        >
          {formatMessage(messages.clearCartModal)}
        </ErrorBtn>
        <AlternateBtn onClick={() => closeModal()} size={BUTTON_SIZE.SMALL}>
          {formatMessage(messages.abort)}
        </AlternateBtn>
      </ButtonsWrapper>
    </React.Fragment>
  )
}

export default RemoveProductsModal
