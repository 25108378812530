import { makeMessages, makeTranslation } from 'utils'

const messages = {
  headerTitle: 'headerTitle',
  cart: 'cart',
  cartItemsNumber: 'cartItemsNumber',
  logout: 'logout',
  manageProfile: 'manage_profile',
  changeAddress: { id: 'deliveryAddress.changeAddress' },
  deliveryExpTitle: { id: 'deliveryExpiredModal.title' },
  deliveryExpMsg: { id: 'deliveryExpiredModal.msg' },
  deliveryExpCtaBtn: { id: 'deliveryExpiredModal.ctaBtn' },
  creditLimit: { id: 'navbar.creditLimit' },
}

export default makeMessages(messages, makeTranslation('loggedInLayout'))
