import React from 'react'
import PropTypes from 'prop-types'

import { ROUTES } from 'consts'
import { Link } from 'components/Text'
import { trackSimpleEvent, NAV_BAR_EVENTS } from 'services/analytics'

import { StyledSimpleLogo } from './styles'

const Logo = ({ isLoggedIn = true }) => (
  <Link
    to={isLoggedIn ? ROUTES.DASHBOARD : ROUTES.LOGIN}
    onClick={() => trackSimpleEvent(NAV_BAR_EVENTS.MAIN_LOGO_CLICKED)}
  >
    <StyledSimpleLogo isLoggedIn={isLoggedIn} />
  </Link>
)

Logo.propTypes = {
  isLoggedIn: PropTypes.bool,
}

export default Logo
