import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import LoaderFullHeight from 'components/LoaderFullHeight'
import { orderProductsDataSelector } from 'containers/Orders/Details/selectors'
import {
  trackOrderEvent,
  PAST_ORDER_EVENTS,
} from 'services/analytics/pastOrders'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { BasicButtonV2Grey } from 'components/Button'
import CancelOrderModal from './CancelOrderModal'

import messages from '../../messages'

const CancelOrder = ({ orderId, orderDetails }) => {
  const { formatMessage } = useIntl()
  const [isConfirmationOpen, setConfirmationOpen] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const orderProductsData = useSelector(orderProductsDataSelector)
  const productCount = orderProductsData?.meta?.pagination?.totalCount
  const userData = useSelector(userDataSelector)

  const openConfirmation = () => {
    setConfirmationOpen(true)
    trackOrderEvent({
      event: PAST_ORDER_EVENTS.CLICKED_CANCEL_ORDER,
      orderDetails,
      userData,
      productCount,
    })
  }
  const closeConfirmation = () => {
    setConfirmationOpen(false)
    trackOrderEvent({
      event: PAST_ORDER_EVENTS.CANCELLATION_ABANDONED,
      orderDetails,
      userData,
      productCount,
    })
  }

  if (isSubmitting) return <LoaderFullHeight />

  return (
    <>
      {isConfirmationOpen && (
        <CancelOrderModal
          onClose={closeConfirmation}
          orderId={orderId}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          onCancelSuccess={({ reasonsParam }) => {
            setConfirmationOpen(false)
            trackOrderEvent({
              event: PAST_ORDER_EVENTS.CANCELLED_ORDER,
              orderDetails,
              userData,
              productCount,
              reason: reasonsParam,
            })
          }}
          onCancelError={() => setConfirmationOpen(false)}
        />
      )}
      <BasicButtonV2Grey onClick={openConfirmation}>
        {formatMessage(messages.cancelOrder)}
      </BasicButtonV2Grey>
    </>
  )
}

export default CancelOrder
