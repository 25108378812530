import React from 'react'

import DashboardTile from 'components/DashboardTile'

import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import {
  EVENT_ORIGIN,
  trackFinanceTileCollapsed,
  trackFinanceTileExpanded,
} from 'services/analytics'
import { useSelector } from 'react-redux'
import { userDataSelector } from 'containers/UserInfo/selectors'
import { InvoicesTileContainer } from './styles'

const Tile = ({ title, subtitle, children, footer, subtitleColor }) => {
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)
  const { customerNo, storeId } = useSelector(userDataSelector)

  return (
    <DashboardTile
      title={title}
      subtitle={
        <span
          data-test-id="invoices-header-subtitle"
          style={{ color: subtitleColor }}
        >
          {subtitle}
        </span>
      }
      headerTestId="invoices-header"
      footer={footer}
      ContainerComponent={InvoicesTileContainer}
      containerTestId="invoices-tile-container"
      disableCollapse={isTablet && !isDesktop}
      onExpand={() =>
        trackFinanceTileExpanded({
          customerNo,
          storeId,
          origin: EVENT_ORIGIN.DASHBOARD,
        })
      }
      onCollapse={() => {
        trackFinanceTileCollapsed({
          customerNo,
          storeId,
          origin: EVENT_ORIGIN.DASHBOARD,
        })
      }}
    >
      {children}
    </DashboardTile>
  )
}

export default Tile
