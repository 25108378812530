import { createSelector } from 'reselect'
import { getOr } from 'lodash/fp'

import { makeDataSelector, makePaginationSelector } from 'utils/redux-utils'

import {
  ORDER_INFO_REDUCER_NAME,
  ORDERS_LIST_ITEM_PRODUCTS_REDUCER_NAME,
} from '../consts'

export const orderProductsDataSelector = makeDataSelector([
  ORDER_INFO_REDUCER_NAME,
  ORDERS_LIST_ITEM_PRODUCTS_REDUCER_NAME,
])

export const orderProductsSelector = createSelector(
  orderProductsDataSelector,
  getOr([], 'items'),
)

export const orderProductsPaginationSelector = makePaginationSelector(
  orderProductsDataSelector,
)
