import React from 'react'
import { useIntl } from 'react-intl'

import DashboardTile from 'components/DashboardTile'

import {
  EVENT_ORIGIN,
  trackOrderTileCollapsed,
  trackOrderTileExpanded,
} from 'services/analytics'
import messages from '../messages'
import NoOrders from '../NoOrders'
import OrderRow from '../OrderRow'
import OrderSummary from '../OrderSummary'

import useOrderStatus from './useOrderStatus'
import { OrdersTileContainer } from './styles'

const TileComponent = ({
  latestOrder,
  hasNoOrders,
  customerNo,
  onOrderDetailsClick,
  onAllOrdersClick,
  storeId,
}) => {
  const { formatMessage } = useIntl()
  const { status, color } = useOrderStatus(latestOrder)

  return (
    <DashboardTile
      title={formatMessage(hasNoOrders ? messages.emptyTitle : messages.title)}
      subtitle={
        hasNoOrders ? (
          formatMessage(messages.noOrdersSubtitle)
        ) : (
          <span style={{ color }}>{status}</span>
        )
      }
      ContainerComponent={OrdersTileContainer}
      containerTestId="orders-tile-container"
      footer={
        latestOrder && (
          <OrderSummary
            order={latestOrder}
            onOrderDetailsClick={onOrderDetailsClick}
            onAllOrdersClick={onAllOrdersClick}
          />
        )
      }
      onCollapse={() =>
        trackOrderTileCollapsed({
          storeId,
          customerNo,
          origin: EVENT_ORIGIN.DASHBOARD,
        })
      }
      onExpand={() =>
        trackOrderTileExpanded({
          storeId,
          customerNo,
          origin: EVENT_ORIGIN.DASHBOARD,
        })
      }
    >
      {hasNoOrders ? (
        <NoOrders />
      ) : (
        latestOrder && (
          <OrderRow order={latestOrder} customerNo={customerNo} color={color} />
        )
      )}
    </DashboardTile>
  )
}

export default TileComponent
