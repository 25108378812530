import styled, { css } from 'styled-components'

import theme from 'theme'

import { P, TinyTextV2 } from 'components/Text'
import { BORDER_TYPES, ICON_STYLES } from './consts'

// Icon for checkbox
import CheckboxIcon from './checked.svg'
import CheckboxIconV2 from './checkedV2.svg'

const iconHeight = '25px'
const inputHeight = '56px'

const IconStyle = {
  [ICON_STYLES.DEFAULT]: css`
    figure {
      position: absolute;
      top: ${`calc( (${inputHeight} - ${iconHeight}) / 2 )`};
      bottom: 0;
      right: 8px;
      svg {
        height: ${iconHeight};
        width: 32px;
      }
    }
  `,
}

const getIconStyle = iconType =>
  IconStyle[iconType] || IconStyle[ICON_STYLES.DEFAULT]

export const getdisabledOpacity = () => css`
  opacity: 0.4;
`

export const InputWrapper = styled.div`
  ${({ iconType }) => getIconStyle(iconType)};
  width: ${({ width }) => width || '328px'};
  position: relative;
  margin-top: ${theme.spacing.sm};
  ${({ disabled }) => disabled && 'pointer-events: none'};
  input:disabled {
    opacity: 0.4;
    background: ${theme.colors.white};
  }
  svg {
    ${({ disabled }) => disabled && getdisabledOpacity()};
  }
  label {
    ${({ disabled }) => disabled && getdisabledOpacity()};
  }
`

const Borders = {
  [BORDER_TYPES.DEFAULT]: css`
  0.25px solid ${theme.colors.gray2}`,
  [BORDER_TYPES.ERROR]: css`
  1px solid ${theme.colors.error}`,
  [BORDER_TYPES.SUCCESS]: css`
  0.25px solid ${theme.colors.secondary}`,
  [BORDER_TYPES.VISITED]: css`
  0.25px solid ${theme.colors.black}`,
}

export const getBorder = borderType =>
  Borders[borderType] || Borders[BORDER_TYPES.DEFAULT]

const smallLabel = css`
  height: 16px;
  left: ${theme.spacing.sm};
  top: 8px;
  font-size: ${theme.textSizes.sm};
  letter-spacing: 0.02em;
`

export const BasicInput = styled.input`
  box-sizing: border-box;
  border: ${({ borderType }) => getBorder(borderType)};
  height: ${inputHeight};
  width: 100%;
  padding-left: ${theme.spacing.sm};
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.l};
  line-height: ${theme.lineHeights.md};
  display: flex;
  align-items: center;
  padding-top: 12px;
  :focus {
    border: ${getBorder(BORDER_TYPES.SUCCESS)};
    outline: none;
  }
  :-webkit-autofill {
    + label {
      ${smallLabel};
    }
  }
  :focus {
    + label {
      ${smallLabel};
    }
  }

  :not(:focus) + label {
    ${({ value }) => value && smallLabel};
  }
`

const smallTextAreaLabel = css`
  padding-top: 8px;
  font-size: ${theme.textSizes.sm};
  letter-spacing: 0.02em;
`

export const TextareaBasic = styled.textarea`
  border: ${({ borderType }) => getBorder(borderType)};
  width: 100%;
  resize: none;
  background: ${props => getBackgroundColor(props)};
  height: ${({ height }) => height || '180px'};
  padding: ${theme.spacing.sm};
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.l};
  line-height: ${theme.lineHeights.md};
  display: flex;
  align-items: center;
  :disabled {
    opacity: 0.4;
    background: ${theme.colors.white};
  }
  :focus {
    border: ${getBorder(BORDER_TYPES.SUCCESS)};
    outline: none;
  }

  ::placeholder {
    color: ${theme.colors.gray4};
  }

  :focus {
    + label {
      ${smallTextAreaLabel};
      border-top: ${getBorder(BORDER_TYPES.SUCCESS)};
    }
  }

  :not(:focus) + label {
    ${({ value }) => value && smallTextAreaLabel};
    border-top: ${({ borderType }) => getBorder(borderType)};
  }
`

export const Label = styled.label`
  height: 20px;
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.l};
  line-height: 19px;
  color: ${theme.colors.gray4};
  mix-blend-mode: normal;
  pointer-events: none;
`
export const TextareaLabel = styled(Label)`
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 2px);
  height: auto;
  margin-left: 1px;
  padding: 16px;
  padding-bottom: 4px;
  align-items: center;
  display: flex;
  background: ${theme.colors.white};
`

export const TextInputLabel = styled(Label)`
  position: absolute;
  top: 18px;
  left: ${theme.spacing.sm};
  align-items: center;
  display: flex;
`

export const CheckboxLabel = styled.div`
  height: 28px;
  width: 28px;
  content: '';
  background: ${props => getBackgroundColor(props)};
  display: inline-block;
  box-sizing: border-box;
`

export const HintBox = styled(P)`
  font-size: ${theme.textSizes.sm};
  padding-left: ${theme.spacing.sm};
  color: ${theme.colors.gray4};
  color: ${props => props.isError && theme.colors.error};
  mix-blend-mode: normal;
  max-width: 100%;
  white-space: pre-wrap;
  letter-spacing: 0.02em;
  margin-top: ${theme.spacing.xs};
`
const CheckboxSize = css`
  width: 28px;
  height: 28px;
`

// prettier-ignore
export const CheckboxBasic = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  & + div {
    ${props => getCheckboxBorder(props)};
  }

  &:checked + div::before {
    ${CheckboxSize};
    content: url("${CheckboxIcon}");
    display: flex;
    padding: 6px;
    margin: -1px;
    background: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    box-sizing: border-box;
  }
`

const getBackgroundColor = ({ disabled }) =>
  disabled ? theme.colors.gray4 : theme.colors.white

const getCheckboxBorder = ({ disabled }) =>
  !disabled
    ? css`
        border: 1px solid ${theme.colors.gray2};
      `
    : css`
        border: 1px solid transparent;
      `

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  ${p => !p.disabled && `cursor: pointer;`};

  :hover {
    ${CheckboxLabel} {
      ${p => !p.disabled && `background-color: ${theme.colors.lightGrey7}`};
    }

    input:checked + ${CheckboxLabel}::before {
      background-color: ${theme.colors.greenLight};
    }
  }
`

export const CloseBtn = styled.a`
  margin-left: 16px;
  margin-right: 8px;
  cursor: pointer;
  color: ${theme.colors.primary};
`

export const CheckMarkBtn = styled.a`
  cursor: pointer;
  color: ${theme.colors.primary};

  ${({ acceptBtnDisabled }) =>
    acceptBtnDisabled &&
    `
    opacity: 0.3;
    cursor: auto;
  `};
`

export const ListInputWrapper = styled.div`
  flex-grow: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

// prettier-ignore
export const ListInputContainer = styled.form`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: ${props => props.hasFocus
    ? theme.colors.lightGrey7
    : theme.colors.lightGrey
};
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  border-radius: 8px;
  ${props => props.isError && `
    background: ${theme.colors.redLight2};
    path {
      stroke: ${theme.colors.orangeDark3};
    }
    input {
      color: ${theme.colors.orangeDark3};
      background: ${theme.colors.redLight2};
      :focus {
        background: ${theme.colors.redLight2};
      }
    }
  `}
`

export const ListInputStyled = styled.input`
  background: ${theme.colors.lightGrey};
  font-family: ${theme.fonts.primary};
  font-size: 13px;
  line-height: 24px;
  outline: none;
  width: 100%;
  margin: 0;
  :focus {
    background: ${theme.colors.lightGrey7};
  }
`

export const ListInputError = styled(TinyTextV2)`
  color: ${theme.colors.orangeDark3};
  display: block;
`

export const InputAssistiveText = styled.div`
  margin-top: ${theme.spacing.xs};
  padding: 0 ${theme.spacing.sm};
  display: flex;
  align-items: center;
`

export const HiddenInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const visibleStyles = css`
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
`
export const hiddenStyles = css`
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
`

export const CheckboxWrapperV2 = styled(CheckboxWrapper)`
  > div {
    height: 18px;
    width: 18px;
    border: none;
    border-radius: 4px;
    background-color: ${theme.colors.lightGrey};
  }

  input:checked + ${CheckboxLabel}::before {
    height: 18px;
    width: 18px;
    border: none;
    border-radius: 4px;
    justify-content: center;
    content: url("${CheckboxIconV2}");
    margin: 0;
    padding: 0 ${theme.spacing.xxs};
    background-color: ${theme.colors.secondary};
  }
`
