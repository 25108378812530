import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'

import { BaseTextV2, TinyTextV2 } from 'components/Text'
import { PRODUCT_IMAGE_SIZES } from 'components/Product/ProductImage'
import {
  findUnitData,
  ITEM_LIST_IDS,
  ITEM_LIST_NAMES,
  useSelectItemTracking,
} from 'services/analytics'
import BookmarkIcon from 'components/Product/BookmarkIcon'
import { getProductTemplateInclusions } from 'utils'
import { formatAmount } from 'components/Product/utils'
import TextTooltip from 'components/Tooltip/TextTooltip'
import theme from 'theme'
import { APP_BREAKPOINTS, ROUTES } from 'consts'
import { useOfMinWidth } from 'hooks'

import messages from 'views/Orders/Details/messages'
import CartActionBtn from './CartActionBtn'
import {
  OrderItemActionsWrapper,
  OrderItemInfoWrapper,
  OrderItemWrapper,
  OrderItemImageWrapper,
  OrderItemImage,
  OrderItemAmountWrapper,
  RewardBadgeContainer,
  RowIconBtn,
} from './styles'

const OrderItem = ({ orderItem, listIndex }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  const { product, unit: historicalUnitData } = orderItem
  const productUnitData =
    findUnitData(product.unitsOfMeasure, historicalUnitData.name) || {}
  const [isInUserTemplate] = getProductTemplateInclusions(productUnitData)

  const trackSelectItem = useSelectItemTracking({
    product,
    itemListId: ITEM_LIST_IDS.ORDER_DETAILS,
    itemListName: ITEM_LIST_NAMES.ORDER_DETAILS,
    unitOfMeasure: historicalUnitData.name,
    index: listIndex,
  })

  const isOutOfCatalogReward =
    historicalUnitData.reward && !product.inUserCatalog

  const isTemplateBtnDisabled = !product.inUserCatalog || !product.active
  const templateIconColor = isTemplateBtnDisabled
    ? theme.colors.gray7
    : theme.colors.primary

  return (
    <OrderItemWrapper
      onClick={() => {
        trackSelectItem()
        !isOutOfCatalogReward &&
          navigate(generatePath(ROUTES.PRODUCT_DETAILS, { id: product.id }), {
            state: {
              itemListId: ITEM_LIST_IDS.ORDER_DETAILS,
              itemListName: ITEM_LIST_NAMES.ORDER_DETAILS,
            },
          })
      }}
      data-test-id={`order-product-${product.id}-${historicalUnitData.name}`}
    >
      <OrderItemImageWrapper>
        <OrderItemImage
          size={PRODUCT_IMAGE_SIZES.S}
          imageSrc={product.images[0]?.urlSmall || product.imageUrl}
        />
      </OrderItemImageWrapper>
      <OrderItemInfoWrapper>
        <TinyTextV2>{product.brand}</TinyTextV2>
        <BaseTextV2 $lineHeight="20px">{product.name}</BaseTextV2>
        {historicalUnitData.reward && (
          <RewardBadgeContainer>
            <BaseTextV2
              $fontSize="10px"
              $lineHeight="16px"
              $fontWeight={500}
              $color={theme.colors.secondary}
            >
              {formatMessage(messages.rewardProductBadge)}
            </BaseTextV2>
          </RewardBadgeContainer>
        )}
      </OrderItemInfoWrapper>
      <OrderItemActionsWrapper onClick={e => e.stopPropagation()}>
        <CartActionBtn
          product={product}
          unitData={productUnitData}
          historicalUnitData={historicalUnitData}
          isOutOfCatalogReward={isOutOfCatalogReward}
        />
        <TextTooltip
          disabled={isTemplateBtnDisabled || !isDesktop}
          content={formatMessage(messages.addToTemplate)}
        >
          <BookmarkIcon
            Wrapper={RowIconBtn}
            unitOfMeasure={historicalUnitData.name}
            listIndex={listIndex}
            disabled={isTemplateBtnDisabled}
            itemListName={ITEM_LIST_NAMES.ORDER_DETAILS}
            itemListId={ITEM_LIST_IDS.ORDER_DETAILS}
            product={product}
            isInUserTemplate={isInUserTemplate}
            clearTemplatesOnClose
            color={templateIconColor}
            fillColor={templateIconColor}
          />
        </TextTooltip>
      </OrderItemActionsWrapper>
      <OrderItemAmountWrapper>
        <BaseTextV2
          $fontSize={theme.textSizes.m}
          $fontWeight={theme.fontWeights.bold}
          $lineHeight="24px"
        >
          {formatAmount(
            {
              multiplier: historicalUnitData.multiplier,
              unitOfMeasureDescription: historicalUnitData.description,
            },
            historicalUnitData.quantity,
          )}
        </BaseTextV2>
      </OrderItemAmountWrapper>
    </OrderItemWrapper>
  )
}

export default OrderItem
