import React from 'react'

const Mushroom = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 15.75C8 15.3358 8.33579 15 8.75 15H15.25C15.6642 15 16 15.3358 16 15.75V17C16 19.2091 14.2091 21 12 21C9.79086 21 8 19.2091 8 17V15.75Z"
      fill="#99D0AC"
    />
    <path
      className="icon-fill"
      d="M12 3C7.02944 3 3 6.17132 3 10.0833C3 10.5311 3.05279 10.9691 3.15373 11.3938C3.40203 12.4385 4.45796 13 5.53177 13H18.4682C19.542 13 20.598 12.4385 20.8463 11.3938C20.9472 10.9691 21 10.5311 21 10.0833C21 6.17132 16.9706 3 12 3Z"
      fill="#229944"
    />
  </svg>
)

export default Mushroom
