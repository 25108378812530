import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useParams } from 'react-router-dom'

import { useLazyGetProductReplacementsQuery } from 'containers/Products/rtkApi'

export const getBreadcrumbsParams = ({ subgroupId, groupId, categoryId }) => {
  if (subgroupId) {
    return {
      subgroup: subgroupId,
    }
  }
  if (groupId) {
    return {
      group: groupId,
    }
  }
  if (categoryId) {
    return {
      category: categoryId,
    }
  }
  return null
}

// isUnitDisabled flag used for PDP variant
export const useCarouselItems = ({
  isUnitDisabled,
  recommendationsState = {},
  fetchRecommendations,
  fetchRecommendationsArgs,
}) => {
  const { id } = useParams()
  const [carouselElement, setCarouselElement] = useState(null)

  const [
    fetchReplacements,
    {
      data: { products: replacements = [] } = {},
      isFetching: isReplacementsFetching,
    },
  ] = useLazyGetProductReplacementsQuery()

  const {
    data: {
      products: recommendations = [],
      meta: { experiment: { id: itemModelId } = {}, attributionToken } = {},
    } = {},
    isFetching: isRecommendationsFetching,
    isUninitialized: isRecommendationsUninitialized,
  } = recommendationsState

  const checkSuggestionsInView = useCallback(
    debounce(() => {
      const rect = carouselElement.getBoundingClientRect()

      if (rect.top && rect.top < window.innerHeight && rect.bottom >= 0) {
        fetchRecommendations(fetchRecommendationsArgs)

        window.removeEventListener('scroll', checkSuggestionsInView)
        window.removeEventListener('resize', checkSuggestionsInView)
      }
    }, 50),
    [carouselElement, fetchRecommendations, fetchRecommendationsArgs],
  )

  useEffect(
    () => {
      if (
        carouselElement &&
        !isUnitDisabled &&
        isRecommendationsUninitialized
      ) {
        checkSuggestionsInView()
      }
    },
    [carouselElement, isUnitDisabled, isRecommendationsUninitialized],
  )

  useEffect(
    () => {
      if (isUnitDisabled) {
        fetchReplacements({ id })
      }

      if (
        carouselElement &&
        !isUnitDisabled &&
        isRecommendationsUninitialized
      ) {
        window.addEventListener('scroll', checkSuggestionsInView)
        window.addEventListener('resize', checkSuggestionsInView)
      }

      return () => {
        window.removeEventListener('scroll', checkSuggestionsInView)
        window.removeEventListener('resize', checkSuggestionsInView)
      }
    },
    [carouselElement, isUnitDisabled, id, isRecommendationsUninitialized],
  )

  if (isUnitDisabled) {
    return {
      carouselItems: replacements,
      isCarouselFetching: isReplacementsFetching,
    }
  }

  return {
    setCarouselElement,
    carouselItems: recommendations,
    isCarouselFetching:
      isRecommendationsFetching || isRecommendationsUninitialized,
    itemModelId,
    attributionToken,
  }
}
