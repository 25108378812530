import { TAG_COLORS } from 'components/Tag/consts'
import { BADGE_STATUS } from 'components/Product/Badge'

export const STOCK_TYPES = {
  AVAILABLE: 'available',
  LOW: 'low',
  DEADLINED: 'deadline',
  UNAVAILABLE: 'unavailable',
}

export const AVAILABILITY_MODE = {
  BADGE: 'badge',
  TEXT: 'text',
  ROW: 'row',
}

export const STATUS_TAG_MAP = {
  [BADGE_STATUS.INFO]: TAG_COLORS.BLUE,
  [BADGE_STATUS.WARNING]: TAG_COLORS.ORANGE,
  [BADGE_STATUS.ERROR]: TAG_COLORS.RED,
}

export const UNAVAILABILITY_TYPES = {
  LIMITED_STOCK: 'limitedStock',
  NON_STOCK: 'nonStock',
  AVAILABLE_SOON: 'availableSoon',
}
