/* eslint-disable indent */
import styled from 'styled-components'
import { BaseTextV2 } from 'components/Text'
import theme from 'theme'
import { StyledScroll } from 'components/Scroll/styles'
import { BasicButtonV2 } from 'components/Button'

export const SCROLL_POSITIONS = {
  TOP: 'top',
  MID: 'mid',
  BOTTOM: 'bottom',
  NONE: 'none',
}

export const SubcategoriesContainer = styled.div`
  position: relative;
  height: 100%;
`

export const SelectedCategory = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.white};
  padding: ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs}
    ${theme.spacing.ss};
  border-bottom: ${p =>
    p.$scrollPosition === SCROLL_POSITIONS.TOP ||
    p.$scrollPosition === SCROLL_POSITIONS.NONE
      ? 'none'
      : `1px solid ${theme.colors.semiTranspBg.gray}`};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${theme.zIndex.base};
`

export const CategoryInfo = styled.div`
  display: flex;
  gap: ${theme.spacing.ss};
`

export const SubcategoriesList = styled(StyledScroll)`
  .simplebar-content {
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.xxs};
  }
  // trackbar spacing
  margin-right: -${theme.spacing.sm};
  padding-right: ${theme.spacing.sm};
  // scroll effects
  &:after {
    content: '';
    position: absolute;
    z-index: ${theme.zIndex.base};
    pointer-events: none;
    width: calc(100% - ${theme.spacing.sm});
    height: 40px;
    left: 0;
    bottom: 40px;
    opacity: ${p =>
      p.$scrollPosition === SCROLL_POSITIONS.BOTTOM ||
      p.$scrollPosition === SCROLL_POSITIONS.NONE
        ? '0'
        : '1'};
    transition: opacity 100ms ease-in;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 86.67%
    );
  }
  padding-top: 41px;
  padding-bottom: 39px;
  height: 100%;
`

export const SubcategoryItem = styled(BaseTextV2).attrs({
  isSmall: { lineHeight: true },
})`
  display: flex;
  justify-content: space-between;
  padding: 10px ${theme.spacing.ss};
  align-items: center;
  color: ${theme.colors.lightGrey4};
  cursor: pointer;
  :hover {
    color: ${theme.colors.primary};
  }
`

export const SelectedCategoryName = styled(BaseTextV2)`
  color: ${theme.colors.primary};
`

export const CloseSelectedCategoryButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  cursor: pointer;
  path {
    stroke: ${theme.colors.gray5};
  }
  &:hover {
    path {
      stroke: ${theme.colors.primary};
    }
  }
`

export const ViewFullButton = styled(BasicButtonV2)`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: ${theme.spacing.xs} ${theme.spacing.xs} ${theme.spacing.xs}
    ${theme.spacing.sm};
  transition: background-color 300ms ease-out;
  path {
    stroke: ${theme.colors.white};
  }
`

export const ViewFullText = styled(BaseTextV2)`
  color: ${theme.colors.white};
`
