import React from 'react'

const Icecream = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="icon-fill"
      d="M12.8162 21.3692C12.4799 22.2102 11.5201 22.2103 11.1838 21.3692L8.09752 13.6513C7.79414 12.8927 8.23536 12 8.91374 12L15.0863 12C15.7646 12 16.2059 12.8927 15.9025 13.6513L12.8162 21.3692Z"
      fill="#229944"
    />
    <path
      d="M16.5 10H7.5C6.11929 10 5 8.88071 5 7.5C5 6.11929 6.11929 5 7.5 5C7.83774 5 8.16713 4.8031 8.29467 4.49037C8.89045 3.02956 10.325 2 12 2C13.675 2 15.1096 3.02956 15.7053 4.49037C15.8329 4.8031 16.1623 5 16.5 5C17.8807 5 19 6.11929 19 7.5C19 8.88071 17.8807 10 16.5 10Z"
      fill="#99D0AC"
    />
  </svg>
)

export default Icecream
