import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  trackCategoriesFilterClick,
  trackBrandsFilterClick,
  trackProductFeaturesClick,
  trackOfferFeaturesOpen,
} from 'services/analytics'
import { selectAvailableFiltersData } from 'containers/Templates/selectors'
import { applyTemplateFilters } from 'containers/Templates/actions'
import Filters from 'components/Filters'

import { TemplateFiltersContainer } from './styles'

const TemplateFilters = ({
  id,
  selectedFilters,
  resetFilters,
  scrollToTopOfList,
  isTemplateAutomatic,
}) => {
  const dispatch = useDispatch()

  const availableFilters = useSelector(selectAvailableFiltersData)

  const handleFiltersApply = (
    name,
    filters,
    isMultipleFilters,
    eventValues,
  ) => {
    scrollToTopOfList()
    dispatch(
      applyTemplateFilters({
        id,
        name,
        filters,
        isMultipleFilters,
        eventValues: {
          ...eventValues,
          templateType: isTemplateAutomatic ? 'automatic' : 'manual',
        },
      }),
    )
  }

  return (
    <TemplateFiltersContainer>
      <Filters
        isCategoryFilter
        isTitle={false}
        handleFiltersClear={resetFilters}
        handleCategoriesShow={() => trackCategoriesFilterClick(id)}
        handleBrandsShow={() => trackBrandsFilterClick(id)}
        handleProductFeaturesShow={() => trackProductFeaturesClick(id)}
        handleOfferFeaturesShow={() => trackOfferFeaturesOpen()}
        calculatePositionFromParent
        portalNode={document.getElementById('template-list-toolbar')}
        {...{
          availableFilters,
          selectedFilters,
          handleFiltersApply,
        }}
      />
    </TemplateFiltersContainer>
  )
}

export default TemplateFilters
