import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import {
  BellSmall as BellIcon,
  ReplaceV2 as ReplaceIcon,
} from 'components/Icons'
import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import EmailNotificationModal from 'components/ProductCartControls/EmailNotificationModal'
import {
  PRODUCT_EVENTS,
  useProductAvailabilityTracking,
} from 'services/analytics'
import theme from 'theme'

import {
  BtnSeparator,
  IconBtn,
  ReplacementsBtn,
  ReplacementsBtnContainer,
} from './styles'
import messages from './messages'

const CatalogReplacements = ({
  onReplacementsClick,
  dataTestId,
  product,
  notificationOptionEnabled,
  unitData,
  isSoonAvailable,
}) => {
  const { formatMessage } = useIntl()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  const trackProductAvailability = useProductAvailabilityTracking({
    product,
    unitData,
  })

  const handleOpenModal = () => {
    setIsModalOpen(true)
    trackProductAvailability(PRODUCT_EVENTS.OPEN_NOTIFICATION_MODAL_CLICKED)
  }

  const isNotificationBtnShown =
    notificationOptionEnabled &&
    !isSoonAvailable &&
    product.active &&
    !unitData.isStockLimited

  const showSeparator = product.unitsOfMeasure.length > 1

  return (
    <ReplacementsBtnContainer>
      {isNotificationBtnShown && (
        <>
          <EmailNotificationModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            product={product}
            unitData={unitData}
          />

          <IconBtn
            data-test-id={`${
              product.notificationEmail ? 'active' : 'inactive'
            }-notification-modal-btn`}
            onClick={handleOpenModal}
          >
            <BellIcon
              color={theme.colors.infoBlue}
              fill={product.notificationEmail && theme.colors.infoBlue}
            />
          </IconBtn>
          {showSeparator && <BtnSeparator />}
        </>
      )}
      <ReplacementsBtn onClick={onReplacementsClick} data-test-id={dataTestId}>
        {isTablet && <ReplaceIcon style={{ marginRight: '8px' }} />}
        {formatMessage(messages.replacements)}
      </ReplacementsBtn>
    </ReplacementsBtnContainer>
  )
}

export default CatalogReplacements
