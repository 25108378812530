import styled from 'styled-components'

import theme from 'theme'

import ModalClose from 'components/modal/ModalClose'
import { H4, P } from 'components/Text'

import { APP_BREAKPOINTS } from 'consts'

export const Header = styled(H4)`
  margin-bottom: ${theme.spacing.base};
`

export const Content = styled(P)`
  margin-bottom: ${theme.spacing.base};
`

export const ModalHeader = styled(ModalClose)`
  margin-bottom: 24px;
  align-items: start;
  > h4 {
    line-height: 24px;
    font-size: ${theme.textSizes.l};
    font-weight: ${theme.fontWeights.medium};
    font-family: ${theme.fonts.primary};
    color: ${theme.colors.primary};
    margin-right: ${theme.spacing.sm};

    @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
      line-height: 32px;
      font-size: ${theme.textSizes.xl};
      font-weight: ${theme.fontWeights.bold};
    }
  }

  button {
    align-self: flex-start;
    color: ${theme.colors.primary};
    width: 24px;
    height: 32px;
    svg {
      color: ${theme.colors.gray5};
    }
    :hover {
      svg {
        color: ${theme.colors.primary};
      }
    }
  }
`
