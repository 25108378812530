import React from 'react'
import theme from 'theme'

const Empty = ({ color = theme.colors.primary }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="2"
      width="12"
      height="12"
      rx="2"
      fill={color}
      className="primaryFill"
    />
    <path
      d="M5 11L11 5"
      stroke="#F6F6F6"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export default Empty
