import { makeMessages, makeTranslation } from 'utils'

const messages = {
  header: 'header',
  didntGetMessage: 'didntGetMessage',
  toContinue: 'toContinue',
  toDoThis: 'toDoThis',
  needHelp: 'needHelp',
  email: { id: 'support.mail' },
}

export default makeMessages(messages, makeTranslation('verifyEmail'))
