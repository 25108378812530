import React from 'react'

const Coffee = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 17.5C3 17.2239 3.22386 17 3.5 17L18.5 17C18.7761 17 19 17.2239 19 17.5V18C19 18.5523 18.5523 19 18 19L4 19C3.44772 19 3 18.5523 3 18L3 17.5Z"
      fill="#99D0AC"
    />
    <path
      className="icon-fill"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.14645 7.85356C8.05268 7.94732 8 8.0745 8 8.20711V10.5C8 10.7761 8.22386 11 8.5 11H10.5C10.7761 11 11 10.7761 11 10.5V8.20711C11 8.0745 10.9473 7.94732 10.8536 7.85355L10.1464 7.14645C10.0527 7.05268 10 6.9255 10 6.7929V5.5C10 5.22386 10.2239 5 10.5 5H16L16.0025 5H17.7857C19.5609 5 21 6.34315 21 8C21 9.65686 19.5609 11 17.7857 11L17 11C17 13.2091 15.2091 15 13 15H9C6.79086 15 5 13.2091 5 11V6C5 5.44772 5.44772 5 6 5H8.5C8.77614 5 9 5.22386 9 5.5V6.7929C9 6.9255 8.94732 7.05268 8.85355 7.14645L8.14645 7.85356ZM18 7H17.0003V9H18C18.5917 9 19.0714 8.55229 19.0714 8C19.0714 7.44772 18.5917 7 18 7Z"
      fill="#229944"
    />
  </svg>
)

export default Coffee
