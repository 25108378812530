import styled from 'styled-components'

import theme from 'theme'

import { APP_BREAKPOINTS } from 'consts'
import { RecommendedBoxContainer } from 'components/RecommendedBox/styles'
import { CarouselTitle } from 'views/ProductDetails/ProductCarousel/styles'
import { ProductBoxContainer } from 'components/Product/ProductBox/styles'
import { CarouselOverflowContainer } from 'components/Carousel/styles'

export const CatalogRecommendedContainer = styled(RecommendedBoxContainer)`
  width: calc(100% + ${theme.spacing.lg});
  margin: 0 0 0 -${theme.spacing.md};
  padding: 40px ${theme.spacing.md};
  background-color: ${theme.colors.lightGrey6};

  ${ProductBoxContainer} {
    :hover {
      background: ${theme.colors.gray8};
    }
  }

  @media (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    padding: 40px 0px;

    ${CarouselOverflowContainer}, ${CarouselTitle} {
      padding: 0 ${theme.spacing.md};
    }

    // slight fade on carousel container sides
    ${CarouselOverflowContainer} {
      &::before,
      &::after {
        content: '';
        display: block;
        height: 100%;
        width: 16px;
        position: absolute;
        top: 0;
        mix-blend-mode: darken;
      }

      &::before {
        left: 0;
        z-index: ${theme.zIndex.base};
        background: linear-gradient(
          90deg,
          ${theme.colors.lightGrey6} 0%,
          rgba(246, 248, 250, 0) 100%
        );
      }

      &::after {
        right: 0;
        background: linear-gradient(
          270deg,
          ${theme.colors.lightGrey6} 0%,
          rgba(246, 248, 250, 0) 100%
        );
      }
    }
  }

  @media (min-width: ${theme.size.inner}) {
    border-radius: 24px;
  }
`

export const NoResultsRecommendedContainer = styled(RecommendedBoxContainer)`
  width: 100%;
  margin-top: 96px;
  margin-bottom: 0;
`
