import styled from 'styled-components'
import { Flex } from '@rebass/grid'

import theme from 'theme'
import { H1v2 } from 'components/Text'
import { DropdownScroll, StyledScroll } from 'components/Scroll/styles'

import {
  DESKTOP_HEIGHT as DESKTOP_HEADER_HEIGHT,
  MOBILE_HEIGHT as MOBILE_HEADER_HEIGHT,
} from 'containers/App/LoggedInLayout/Header/styles'
import { STICKY_ELEMENTS_OFFSET } from 'views/Templates/styles'
import { TEMPLATES_MOBILE_MAX_WIDTH } from '../consts'

export const TemplateHeading = styled(H1v2)`
  margin-right: -32px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.borderFaded};
  @media screen and (max-width: ${TEMPLATES_MOBILE_MAX_WIDTH}) {
    font-size: 22px;
    line-height: 30px;
    padding-bottom: 0;
    border: none;
    margin-bottom: 0;
  }
`

export const TemplatesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 324px;
  flex-shrink: 0;
  padding: 0 32px 32px 32px;
  border-right: 1px solid ${theme.colors.border};

  @media screen and (max-width: ${TEMPLATES_MOBILE_MAX_WIDTH}) {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 16px 21px 16px;
    border-bottom: 1px solid ${theme.colors.border};
  }
`

export const CreateTemplateContainer = styled.div`
  margin-bottom: 8px;
`

export const CreateTemplateToggleContainer = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid ${theme.colors.borderFaded};
  button {
    padding-left: 16px;
  }
  @media screen and (max-width: ${TEMPLATES_MOBILE_MAX_WIDTH}) {
    button {
      padding-left: 34px;
    }
    form {
      margin: 0 16px;
    }
  }
`

export const StickyContainer = styled.div`
  position: sticky;
  top: ${DESKTOP_HEADER_HEIGHT}px;
  height: 100%;
  max-height: calc(100vh - ${DESKTOP_HEADER_HEIGHT}px);
`

export const TopSegmentContainer = styled.div`
  background-color: #fff;
  top: ${MOBILE_HEADER_HEIGHT}px;
  border-radius: 16px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`

export const ScrollableListContainer = styled(StyledScroll)`
  max-height: calc(100vh - ${STICKY_ELEMENTS_OFFSET}px);
  // sticky offset
  .simplebar-vertical {
    margin: ${theme.spacing.xs} 0;
  }
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${theme.spacing.xs} 0;
  // additional scrollbar space
  margin-right: -22px;
  padding-right: 22px;
  li:not(:last-child) {
    margin-bottom: ${theme.spacing.xs};
  }
`

export const TemplatesListContainer = styled.ul`
  list-style-type: none;
  padding: 8px 0;
  > li:not(:last-child) {
    margin-bottom: 8px;
  }
`

export const BidfoodTemplateIconContainer = styled.div`
  margin-right: 16px;
`

export const BidfoodTemplateListItem = styled.div`
  span {
    color: ${theme.colors.greenDark};
  }

  > a.active,
  a:hover {
    background-color: ${theme.colors.greenLight5};
    span {
      color: ${theme.colors.greenDark};
    }
  }
`

export const CloseIconContainer = styled(Flex)`
  padding: 4px 0;
  cursor: pointer;
`

export const BidfoodTemplateContainer = styled(Flex)`
  align-items: center;
  gap: 10px;
  padding: 8px 0;
  border-bottom: 1px solid ${theme.colors.borderFaded};
  ${TemplatesListContainer} {
    width: 100%;
  }
  @media screen and (max-width: ${TEMPLATES_MOBILE_MAX_WIDTH}) {
    padding: 8px 0;
    margin: 0 16px;
  }
`

export const TemplatesListDropdownScroll = styled(DropdownScroll)`
  height: calc(100% - 122px);
`
