import { omit } from 'lodash/fp'

const createFormData = ({ data }) => {
  const formData = new FormData()
  formData.append('details', JSON.stringify(omit('files', data)))

  if (data.files.length) {
    data.files.forEach(file => formData.append('files[]', file, file.name))
  }

  return formData
}

export default createFormData
