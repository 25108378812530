import React from 'react'
import { useIntl } from 'react-intl'

import theme from 'theme'
import { ProgressBar, PROGRESS_MODE } from 'components'

import { SaleContainer, SaleTextContainer, SaleText } from './styles'
import messages from './messages'

const CONFIG = {
  [PROGRESS_MODE.SALE]: {
    container: {
      gap: theme.spacing.xs,
    },
    text: {
      fontSize: theme.textSizes.xxs,
      lineHeight: '16px',
      fontWeight: theme.fontWeights.medium,
    },
  },
  [PROGRESS_MODE.SALE_BIG]: {
    container: {
      gap: theme.spacing.xxs,
    },
    text: {
      fontSize: theme.textSizes.sm,
      lineHeight: '24px',
      fontWeight: theme.fontWeights.regular,
    },
  },
}

const SaleProgressBar = ({
  className,
  isLongText,
  promotion,
  baseUnitDesc,
  mode = PROGRESS_MODE.SALE,
}) => {
  const { formatMessage } = useIntl()
  const reward = promotion?.reward

  const { initialStock, stock } = reward

  const containerStyle = CONFIG[mode].container
  const textStyle = CONFIG[mode].text

  return (
    <SaleContainer style={containerStyle} className={className}>
      <SaleTextContainer>
        <SaleText style={textStyle}>
          {formatMessage(
            messages[
              isLongText ? 'availableAtDiscountLong' : 'availableAtDiscount'
            ],
          )}
        </SaleText>
        <SaleText style={textStyle}>
          {stock} {baseUnitDesc}
        </SaleText>
      </SaleTextContainer>
      <ProgressBar value={stock} maxValue={initialStock} mode={mode} />
    </SaleContainer>
  )
}

export default SaleProgressBar
