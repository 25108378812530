import { LogisticLimitError as LogisticLimitIcon } from 'components/Icons'
import messages from './NotificationCenterModal/messages'

export const FEED_MESSAGE_TYPES = {
  logisticLimitNotSet: 'logistic-limit-not-set',
}

export const FEED_MESSAGE_MAP = {
  [FEED_MESSAGE_TYPES.logisticLimitNotSet]: {
    titleKey: messages.unmetLogisticLimitTitle,
    IconComponent: LogisticLimitIcon,
  },
}
