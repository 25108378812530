export const getSettings = () => ({ url: 'me/settings' })

export const updateSettings = ({ body }) => {
  const options = {
    method: 'PUT',
    body,
  }
  return { url: 'me/settings', options }
}

export const sendFeedbackForm = ({ body }) => {
  const options = {
    method: 'POST',
    body,
    headers: {
      omitContentType: true,
    },
  }

  return { url: 'me/feedbacks', options }
}
