import styled from 'styled-components'
import {
  DashboardTileContainer,
  DashboardTileFooterWrapper,
} from 'components/DashboardTile/styles'

export const DeliveriesTileContainer = styled(DashboardTileContainer)`
  > ${DashboardTileFooterWrapper} {
    margin-top: 0;
  }
`
