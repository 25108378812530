import React from 'react'
import PropTypes from 'prop-types'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'

import theme from 'theme'
import { RESET_PASSWORD_ROUTES, ROUTES } from 'consts'

import { ArrowLongLeft } from 'components/Icons'
import Logo from './Logo'
import { BackBtn, BtnText, LoggedOutHeader, LoggedOutWrapper } from './styles'

const SimpleHeader = ({ isMobile, isLoggedIn }) => {
  const navigate = useNavigate()

  // TODO: remove after switch to new header for the rest of the screens (register, set pass, etc)
  const location = useLocation()
  const isButtonDisplayed = matchRoutes(
    [
      { path: RESET_PASSWORD_ROUTES.INIT },
      { path: RESET_PASSWORD_ROUTES.CONFIRM },
    ],
    location,
  )
  return (
    <LoggedOutWrapper {...{ isMobile }} data-test-id="simple-header">
      <LoggedOutHeader>
        {isButtonDisplayed && (
          <BackBtn onClick={() => navigate(ROUTES.LOGIN)}>
            <ArrowLongLeft color={theme.colors.primary} />
            <BtnText>Zaloguj się</BtnText>
          </BackBtn>
        )}
        <Logo isLoggedIn={isLoggedIn} />
      </LoggedOutHeader>
    </LoggedOutWrapper>
  )
}

SimpleHeader.propTypes = {
  isLoggedIn: PropTypes.bool,
}

export default SimpleHeader
