import { call, takeLatest, put, select, all } from 'redux-saga/effects'
import { omit, identity } from 'lodash'

import { getResponseError } from 'utils'
import { ROUTES } from 'consts'

import { genericGetDataEnhanced } from 'containers/App/sagas'
import { campaignProductsActions } from 'containers/Campaigns/actions'
import { makeCampaignProductByIdSelector } from 'containers/Campaigns/selectors'
import productActions from 'containers/Products/actions'
import { selectProductById } from 'containers/Products/selectors'
import { replacementByIdSelector } from 'containers/Products/Replacements/selectors'
import { getReplacementsActions } from 'containers/Products/Replacements/actions'
import { productDetailsActions } from 'containers/ProductDetails/actions'
import { productDetailsSelector } from 'containers/ProductDetails/selectors'
import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'

import { findRouterPath } from 'utils/findRouterPath'
import { notifyFailure } from 'components/Product/utils'
import { rtkApi } from 'services/api/rtkApi'
import { templateByIdSelector } from '../selectors'
import {
  ADD_PRODUCT_TO_TEMPLATE,
  DELETE_PRODUCT_FROM_TEMPLATE,
  PRODUCT_TEMPLATES_ACTION,
  templatesActions,
  templateStatusChange,
} from '../actions'
import {
  addProductToTemplate,
  deleteTemplateProduct,
  getProductTemplates,
} from '../api'
import { getTemplateFlow } from './templates'
import { itemNotExistInTemplate, makeProductUpdater } from './utils'

export function* getProductTemplatesFlow({ data = {} }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: templatesActions,
      request: getProductTemplates,
      params: data,
    })
  } catch (error) {
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* addProductToTemplateFlow({
  data: { quantity, unitOfMeasure, template, path, productId } = {},
  additionalData: { onSuccess = identity } = {},
}) {
  const { id } = template

  try {
    yield put(templatesActions.request())

    const response = yield call(genericGetDataEnhanced, {
      request: addProductToTemplate,
      params: { id, productId, body: { quantity, unitOfMeasure } },
    })

    if (!response) return

    const { product } = response

    yield call(updateRtkListRequest, {
      listName: 'getProductReplacements',
      updatedProduct: product,
    })
    yield call(updateRtkListRequest, {
      listName: 'getProductRecommendations',
      updatedProduct: product,
    })
    yield call(updateRtkListRequest, {
      listName: 'getRecommendations',
      updatedProduct: product,
    })

    yield put(templateStatusChange(product))

    const currTemplate = yield select(templateByIdSelector(id))
    yield put(
      templatesActions.update({ ...currTemplate, inTemplate: true }, { id }),
    )

    yield call(onSuccess)
    yield call(updateProduct, { path, productId, ...product })
    // Refetch single template to update items count
    yield call(getTemplateFlow, { data: { id } })
  } catch (error) {
    yield put(templatesActions.failure(omit(error, ['headers'])))
    yield put(notifyFailure(getResponseError(error)))
    // TODO reset template state for product?
    yield call(getProductTemplatesFlow, { data: { productId, unitOfMeasure } })
  }
}

export function* deleteProductFromTemplateFlow({
  data: { unitOfMeasure, template, path, productId } = {},
  additionalData: { onSuccess = identity } = {},
}) {
  const { id } = template

  try {
    const { update, success, ...restActions } = templatesActions
    const responseProduct = yield call(genericGetDataEnhanced, {
      actions: restActions,
      request: deleteTemplateProduct,
      params: { id, productId },
    })

    if (!responseProduct) return

    yield call(updateRtkListRequest, {
      listName: 'getProductReplacements',
      updatedProduct: responseProduct,
    })
    yield call(updateRtkListRequest, {
      listName: 'getProductRecommendations',
      updatedProduct: responseProduct,
    })
    yield call(updateRtkListRequest, {
      listName: 'getRecommendations',
      updatedProduct: responseProduct,
    })

    const currTemplate = yield select(templateByIdSelector(id))
    yield put(update({ ...currTemplate, inTemplate: false }, { id, productId }))

    yield put(templateStatusChange(responseProduct))

    yield call(onSuccess)
    yield call(updateProduct, { path, productId, ...responseProduct })
    // Refetch single template to update items count
    yield call(getTemplateFlow, { data: { id } })
  } catch (error) {
    if (error.body.errors && itemNotExistInTemplate(error.body.errors)) {
      yield call(onSuccess)
    } else {
      yield put(notifyFailure(getResponseError(error)))
    }
    // TODO reset template state for product?
    yield call(getProductTemplatesFlow, { data: { productId, unitOfMeasure } })
  }
}

export function* updateProduct({ path, ...params }) {
  switch (findRouterPath(path)) {
    case ROUTES.SEARCH_RESULTS:
    case ROUTES.PRODUCTS_ROOT: {
      yield call(
        makeProductUpdater({
          itemSelector: selectProductById,
          actions: productActions,
        }),
        params,
      )
      break
    }

    case ROUTES.CAMPAIGN_DETAILS: {
      yield call(
        makeProductUpdater({
          itemSelector: makeCampaignProductByIdSelector,
          actions: campaignProductsActions,
        }),
        params,
      )
      break
    }

    default: {
      yield call(
        makeProductUpdater({
          itemSelector: replacementByIdSelector,
          actions: getReplacementsActions,
        }),
        params,
      )
      yield call(updateProductDetails, params)
    }
  }
}

function* updateProductDetails({ productId, ...rest }) {
  const item = yield select(productDetailsSelector)
  if (item && item.id === productId) {
    yield put(
      productDetailsActions.success({
        ...item,
        ...rest,
      }),
    )
  }
}

export default [
  takeLatest(PRODUCT_TEMPLATES_ACTION, getProductTemplatesFlow),
  takeLatest(DELETE_PRODUCT_FROM_TEMPLATE, deleteProductFromTemplateFlow),
  takeLatest(ADD_PRODUCT_TO_TEMPLATE, addProductToTemplateFlow),
]

function* updateRtkListRequest({ listName, updatedProduct }) {
  const productIds = yield select(state =>
    rtkApi.util.selectCachedArgsForQuery(state, listName),
  )
  yield all(
    (productIds || []).map(productId =>
      put(
        rtkApi.util.updateQueryData(listName, productId, state => {
          state?.products.forEach(product => {
            if (product.id === updatedProduct.id) {
              // eslint-disable-next-line no-param-reassign
              product.unitsOfMeasure = updatedProduct.unitsOfMeasure
              // eslint-disable-next-line no-param-reassign
              product.inTemplate = updatedProduct.inTemplate
            }
          })
        }),
      ),
    ),
  )
}
