import { values } from 'lodash/fp'
import {
  Bio,
  Eco,
  GlutenFree,
  Halal,
  Kosher,
  LactoseFree,
  PreservativeFree,
  SugarFree,
  Vegan,
  Vegetarian,
} from 'components/Icons/Labels'

export const AVAILABLE_FILTERS_REDUCER_NAME = 'availableFilters'
export const SELECTED_FILTERS_REDUCER_NAME = 'selectedFilters'

export const FILTERS_TYPES = {
  CATEGORIES: 'categories',
  BRANDS: 'brands',
  ON_STOCK: 'onStock',
  PROMOTION: 'promotion',
  CLEARANCE: 'clearance',
  STORAGE_TYPES: 'storageTypes',
  PRODUCT_FEATURES: 'productFeatures',
  OFFER_FEATURES: 'offerFeatures',
}

export const FILTERS_VALUES = {
  ON_STOCK: {
    ALL: 'all',
    AVAILABLE: 1,
  },
  PROMOTION: {
    ALL: 'all',
    AVAILABLE: 1,
  },
  CLEARANCE: {
    ALL: 'all',
    AVAILABLE: 1,
  },
  STORAGE_TYPES: {
    ROOM_TEMP: 'S',
    FROZEN: 'M',
    CHILLED: 'Z',
  },
  OFFER_FEATURES: {
    BESTSELLER: 'BESTSELLER',
    NOVELTY: 'NOVELTY',
    BIDMARKET: 'BIDMARKET',
    RECOMMENDATION: 'RECOMMENDATION',
  },
}

export const FILTER_TYPES_LIST = values(FILTERS_TYPES)

export const FILTER_ITEMS = {
  ALL: 'all',
}

export const SELECT_TYPE = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
  BUTTON: 'button',
  GROUP_BUTTON: 'group button',
}

export const MOBILE_FILTERS_QUERY = '(max-width: 640px)'

export const FILTER_SECTION = {
  ALL: 'all',
  CATEGORY: 'category',
  GROUP: 'group',
  SUBGROUP: 'subgroup',
}

export const FILTER_CHILD_HIERARCHY = {
  [FILTER_SECTION.ALL]: [
    FILTER_SECTION.CATEGORY,
    FILTER_SECTION.GROUP,
    FILTER_SECTION.SUBGROUP,
  ],
  [FILTER_SECTION.CATEGORY]: [FILTER_SECTION.GROUP, FILTER_SECTION.SUBGROUP],
  [FILTER_SECTION.GROUP]: [FILTER_SECTION.SUBGROUP],
  [FILTER_SECTION.SUBGROUP]: [],
}

export const STATIC_FILTERS = [
  {
    filterField: 'filter.promotion',
    translateLabels: true,
    type: 'single',
    items: [
      {
        value: null,
        label: 'filter.promotion.all',
      },
      {
        value: 1,
        label: 'filter.promotion.available',
      },
    ],
  },
  {
    filterField: 'filter.clearance',
    translateLabels: true,
    type: 'single',
    items: [
      {
        value: null,
        label: 'filter.clearance.all',
      },
      {
        value: 1,
        label: 'filter.clearance.available',
      },
    ],
  },
]

export const FILTERS_TEST_IDS = {
  CATEGORIES: 'categories_filter',
  ON_STOCK: 'on_stock_filter',
  PROMOTION: 'promotion_filter',
  CLEARANCE: 'clearance_filter',
  ROOM_TEMP: 'room_temp_filter',
  CHILLED: 'chilled_filter',
  FROZEN: 'frozen_filter',
  BRANDS: 'brands_filter',
  PRODUCT_FEATURES: 'product_features_filter',
  OFFER_FEATURES: 'offer_features_filter',
}

const PRODUCT_FEATURE_MAP = {
  LACTOSE_FREE: 'LACTOSE_FREE',
  VEGETARIAN: 'VEGETARIAN',
  VEGAN: 'VEGAN',
  GLUTEN_FREE: 'GLUTEN_FREE',
  BIO: 'BIO',
  ECO: 'ECO',
  HALAL: 'HALAL',
  SUGAR_FREE: 'SUGAR_FREE',
  KOSHER: 'KOSHER',
  PRESERVATIVES_FREE: 'PRESERVATIVES_FREE',
}

export const PRODUCT_FEATURE_ICON = {
  [PRODUCT_FEATURE_MAP.BIO]: Bio,
  [PRODUCT_FEATURE_MAP.ECO]: Eco,
  [PRODUCT_FEATURE_MAP.GLUTEN_FREE]: GlutenFree,
  [PRODUCT_FEATURE_MAP.HALAL]: Halal,
  [PRODUCT_FEATURE_MAP.KOSHER]: Kosher,
  [PRODUCT_FEATURE_MAP.LACTOSE_FREE]: LactoseFree,
  [PRODUCT_FEATURE_MAP.PRESERVATIVES_FREE]: PreservativeFree,
  [PRODUCT_FEATURE_MAP.SUGAR_FREE]: SugarFree,
  [PRODUCT_FEATURE_MAP.VEGAN]: Vegan,
  [PRODUCT_FEATURE_MAP.VEGETARIAN]: Vegetarian,
}
