import styled from 'styled-components'
import SimpleBar from 'simplebar-react'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import ModalClose from 'components/modal/ModalClose'

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${theme.spacing.xxs};
`

export const FeedContainer = styled(SimpleBar)`
  display: flex;
  flex: 1;
  flex-direction: column;
  // height of modal - header
  // need to keep it static so empty list text doesn't jump around when opening
  height: calc(100% - 72px);
  overflow: auto;

  .simplebar-scrollbar::before {
    background-color: ${theme.colors.gray5};
    width: 4px;
    border-radius: 2px;
  }

  .simplebar-vertical {
    margin-bottom: ${theme.spacing.sm};
  }
  .simplebar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
`

export const Header = styled(ModalClose)`
  margin: 0;
  padding: ${theme.spacing.xs} ${theme.spacing.sm} ${theme.spacing.sm}
    ${theme.spacing.base};

  > h4 {
    color: inherit;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
  }

  border-bottom: 1px solid ${theme.colors.semiTranspBg.grayLight};

  button {
    color: ${theme.colors.primary};
    svg {
      stroke: ${theme.colors.gray5};
    }
    :hover {
      svg {
        stroke: ${theme.colors.primary};
      }
    }
  }
`

export const Container = styled.div`
  height: calc(100vh - 56px);

  @supports (height: 100svh) {
    height: calc(100svh - 56px);
  }

  overflow: hidden;
  width: 100%;
  background-color: ${theme.colors.white};
  color: ${theme.colors.primary};

  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    box-shadow: 0 12px 32px 0 ${theme.colors.shadow.modal};
    position: relative;
    width: 480px;
    height: 612px;
    padding: ${theme.spacing.sm} 0 0 0;
    border-radius: 16px;
  }

  display: flex;
  flex-direction: column;
`
