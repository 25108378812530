import React from 'react'
import { PopoverClose } from 'components/Popup'
import { generatePath, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useOfMinWidth } from 'hooks'
import { APP_BREAKPOINTS } from 'consts'
import { ORDERS_ROUTES } from 'views/Orders/consts'

import { FEED_MESSAGE_MAP } from '../consts'
import EndOfListComponent from './EndOfListComponent'
import NotificationFeedItem from './NotificationFeedItem'
import messages from './messages'
import { Container, FeedContainer, Header, ScrollableContainer } from './styles'

const NotificationCenterModal = ({ items, markAsRead }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()

  const handleFeedItemClick = item => {
    markAsRead(item)
    navigate(
      generatePath(ORDERS_ROUTES.DETAILS, {
        orderId: item.data.order_id,
      }),
      {
        state: {
          notifProps: {
            customerNo: item.data.customer_no,
            orderNumber: item.data.order_number,
          },
        },
      },
    )
  }

  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)

  return (
    <Container id="notification-center-modal">
      {isTablet && <Header heading={formatMessage(messages.title)} />}
      <FeedContainer>
        <ScrollableContainer>
          {items.map(item => {
            const { titleKey, IconComponent } = FEED_MESSAGE_MAP[
              item.source.key
            ]

            return (
              <PopoverClose key={item.id}>
                <NotificationFeedItem
                  innerHtml={item.blocks[0].rendered}
                  title={formatMessage(titleKey)}
                  IconComponent={IconComponent}
                  onReadClick={e => {
                    e.stopPropagation()
                    markAsRead(item)
                  }}
                  isRead={!!item.read_at}
                  onClick={() => handleFeedItemClick(item)}
                />
              </PopoverClose>
            )
          })}
        </ScrollableContainer>
        <EndOfListComponent empty={!items.length} />
      </FeedContainer>
    </Container>
  )
}

export default NotificationCenterModal
