import { makeMessages, makeTranslation } from 'utils'

const messages = {
  home: 'home',
  categories: 'categories',
  templates: 'templates',
  offers: 'offers',
  orders: 'orders',
  invoices: 'invoices',
  orderIn: 'orderIn',
  tomorrow: 'tomorrow',
  day: 'day',
  days: 'days',
  promo: 'promo',
  return: 'return',
  allProducts: { id: 'products.header.allProducts' },
}

export default makeMessages(messages, makeTranslation('navbar'))
