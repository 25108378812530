import { fromJS } from 'immutable'
import { findIndex, flow, get, some } from 'lodash/fp'

import { reducer, initialState } from 'utils/simpleDataReducer'

import { isRewardPromoType } from 'containers/Promotions/utils'
import { templateStatusChange } from '../Templates/actions'

import actionsGroup, {
  updateCartItem,
  deleteCartItem,
  SET_CHANGING_DATE_PRODUCT_ID,
} from './actions'

const getProductId = get('product.id')
const getItemDeliveryIndex = ({ state, productId }) => {
  const jsDeliveries = state.getIn(['data', 'deliveries'])

  return findIndex(
    flow(
      get('items'),
      some(item => getProductId(item) === productId),
    ),
  )(jsDeliveries && jsDeliveries.toJS())
}

// eslint-disable-next-line default-param-last
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case updateCartItem.SUCCESS: {
      const {
        data: { cart, cartItem },
      } = action

      if (!state.get('data')) {
        // no deliveries array in add to cart response, needs to be added manually if no state
        return state.set('data', fromJS({ ...cart, deliveries: [] }))
      }

      let updatedState = state.mergeIn(['data'], fromJS(cart))

      // TODO consider passing deliveryIndex in the actions instead.
      // Leaving for now as it's a faster implementation
      const itemDeliveryIndex = getItemDeliveryIndex({
        state,
        productId: getProductId(cartItem),
      })

      if (cartItem) {
        // temporary for BFX-7164, to avoid unnecessary cart renders
        // TODO: remove once there is assigned delivery returned in add to cart response, use reducer to update cart (currently refetched)
        if (
          cartItem.product.promotion &&
          isRewardPromoType(cartItem.product.promotion)
        ) {
          return updatedState
        }
        if (itemDeliveryIndex > -1) {
          updatedState = updatedState.updateIn(
            ['data', 'deliveries', itemDeliveryIndex, 'items'],
            items =>
              items.map(item => {
                if (item.getIn(['product', 'id']) === cartItem.product.id) {
                  return fromJS(cartItem)
                }
                return item
              }),
          )
        } else {
          // TODO: refactor once there is an assigned delivery in response
          updatedState = updatedState.updateIn(['data', 'deliveries'], list =>
            list.push(fromJS({ items: [cartItem] })),
          )
        }
      }

      return updatedState
    }

    case deleteCartItem.SUCCESS: {
      const {
        additionalData,
        data: { cartItem },
      } = action

      const itemDeliveryIndex = getItemDeliveryIndex({
        state,
        productId: additionalData.productId,
      })

      if (itemDeliveryIndex > -1 && !cartItem) {
        return state.updateIn(
          ['data', 'deliveries', itemDeliveryIndex, 'items'],
          items =>
            items.filter(
              item =>
                item.getIn(['product', 'id']) !== additionalData.productId,
            ),
        )
      }

      return state
    }

    case templateStatusChange.toString(): {
      const itemDeliveryIndex = getItemDeliveryIndex({
        state,
        productId: action.payload.id,
      })

      if (itemDeliveryIndex > -1) {
        return state.updateIn(
          ['data', 'deliveries', itemDeliveryIndex, 'items'],
          items =>
            items.map(item => {
              if (item.getIn(['product', 'id']) === action.payload.id) {
                return item.set('product', fromJS(action.payload))
              }

              return item
            }),
        )
      }

      return state
    }

    case SET_CHANGING_DATE_PRODUCT_ID: {
      const { data } = action

      if (data) {
        return state.set('changingDateProductId', {
          [data]: get(data)(state.get('changingDateProductId'))
            ? undefined
            : true,
        })
      }

      return state.set('changingDateProductId', {})
    }

    default: {
      return reducer(state, action, actionsGroup)
    }
  }
}

export default cartReducer
