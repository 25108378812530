import styled from 'styled-components'

import BasicBtn from 'components/Button/ButtonV2'
import theme from 'theme'

export const BasicIconBtn = styled(BasicBtn).attrs(props => props)`
  padding: 0;
  height: 40px;
`

export const InfoBtn = styled(BasicIconBtn).attrs({
  $bgColor: theme.colors.infoBlue2,
  $hoverColor: theme.colors.infoBlue3,
})`
  flex: 1;
`
