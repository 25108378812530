import { takeLatest, call, put } from 'redux-saga/effects'

import { openModal } from 'containers/App/actions/modal'
import { MODAL_SOMETHING_WENT_WRONG } from 'containers/App/modalTypes'
import { genericGetDataEnhanced } from 'containers/App/sagas'
import createFormData from 'utils/createFormData'

import {
  getOrderProductsActions,
  sendComplaintFormActions,
  sendFeedbackFormActions,
} from './actions'
import { getOrderProducts, sendComplaintForm, sendFeedbackForm } from './api'

function* getOrderProductsFlow({ data }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: getOrderProductsActions,
      request: getOrderProducts,
      params: data,
    })
  } catch (err) {
    console.error('Error getting order products', err)
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* sendComplaintFormFlow({ data, additionalData }) {
  try {
    const formData = yield call(createFormData, { data })

    yield call(genericGetDataEnhanced, {
      actions: sendComplaintFormActions,
      request: sendComplaintForm,
      params: { body: formData, id: additionalData.id },
    })

    yield call(additionalData.onSuccess)
  } catch (error) {
    console.log('Error sending complaint form', error)
    yield call(additionalData.onError, { error })
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export function* sendFeedbackFormFlow({ data, additionalData }) {
  try {
    yield call(genericGetDataEnhanced, {
      actions: sendFeedbackFormActions,
      request: sendFeedbackForm,
      params: { body: { details: data }, id: additionalData.id },
    })

    yield call(additionalData.onSuccess)
  } catch (error) {
    console.log('Error sending feedback form', error)
    yield call(additionalData.onError, { error })
    yield put(openModal(MODAL_SOMETHING_WENT_WRONG, { hideHeader: true }))
  }
}

export default [
  takeLatest(getOrderProductsActions.DELTA, getOrderProductsFlow),
  takeLatest(sendComplaintFormActions.DELTA, sendComplaintFormFlow),
  takeLatest(sendFeedbackFormActions.DELTA, sendFeedbackFormFlow),
]
