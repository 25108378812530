import styled from 'styled-components'
import { P, H4, H3 } from 'components/Text'
import { AlternateBtn } from 'components/Button'
import { BasicButton } from 'components/Button/styles'
import {
  getColors,
  getFontWeights,
  getSpacing,
  getSize,
  getTextSizes,
  getLineHeights,
} from 'components/helpers/theme-selectors'
import { InputWrapper } from 'components/Input/styles'
import { SelectContainer } from 'components/Fields/SelectComponent/styledComponents'

export const PageLayout = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: ${props => getSpacing(props).md};

  & > ${BasicButton} {
    position: absolute;
  }

  @media screen and (max-width: 60em) {
    & > ${BasicButton} {
      position: relative;
      margin-bottom: ${props => getSpacing(props).md};
      margin-right: 100%;
    }
  }

  @media screen and (max-width: 667px) {
    padding: ${props => getSpacing(props).base};
  }
`

export const FormWrapper = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  max-width: ${props => getSize(props).panel};
  border: 1px solid ${props => getColors(props).border};
  padding: ${props => getSpacing(props).md} ${props => getSpacing(props).base};
  overflow-x: scroll;

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  button {
    width: 100%;
    margin-top: ${props => getSpacing(props).base};
  }

  @media screen and (max-width: 650px) {
    max-width: none;
    border: none;
    padding: 0;
  }
`

export const Title = styled(H3).attrs({
  strongBold: true,
})`
  white-space: pre-line;
  width: 100%;
  margin-bottom: ${props => getSpacing(props).md};
`

export const Description = styled(P)`
  font-weight: ${props => getFontWeights(props).regular};
  line-height: 19px;
  ${props => props.mb && `margin-bottom: ${getSpacing(props).sm};`};
`

export const LoginTxt = styled(Description)`
  margin-top: ${props => getSpacing(props).base};

  b {
    font-weight: 700;
  }
`

export const FormSectionHeading = styled(H4).attrs({
  strongBold: true,
})`
  line-height: ${props => getLineHeights(props).base};
`

export const FormSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${props => getSpacing(props).md};

  ${InputWrapper} {
    width: 100%;
  }
  ${SelectContainer} {
    margin-right: ${props => getSpacing(props).sm};
  }
`

export const Optional = styled.span`
  font-size: ${props => getTextSizes(props).sm};
  color: ${props => getColors(props).gray3};
`

export const ModalWrapper = styled.div``

export const ErrorModalH = styled(H4)`
  margin-top: 0;
  font-weight: ${props => getFontWeights(props).bold};
  line-height: 24px;
  margin-bottom: ${props => getSpacing(props).base};
`

export const ErrorModalP = styled(P)`
  margin-bottom: 24px;
`

export const StyledButton = styled(AlternateBtn)`
  width: 100%;
  margin-bottom: 24px;
`
const A = styled.a`
  font-weight: ${p => getFontWeights(p).bold};
  color: ${p => getColors(p).primary};
  text-decoration: none;
`
export const Href = styled(A).attrs({
  target: '_blank',
})``
