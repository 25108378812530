import React, { PureComponent } from 'react'
import { omit, contains, debounce, isEqual, sumBy, isEmpty } from 'lodash/fp'
import { FormattedMessage } from 'react-intl'

import { BUTTON_SIZE } from 'components/Button'

import theme from 'theme'
import { X } from 'components/Icons'
import { FILTERS_TYPES } from 'components/Filters/consts'
import { renderFilterIcon } from 'components/Filters/utils'
import messages from '../messages'
import Dropdown from '../Dropdown'

import Search from './Search'
import CheckBox from './CheckBox'
import {
  FiltersDropdownScroll,
  Header,
  ClearBtn,
  Footer,
  ClearAll,
  FilterItemsCount,
  ApplyFiltersBtn,
  DropdownItemsSeparator,
} from './styles'

// prettier-ignore
class MultiFilter extends PureComponent {
  state = {
    searchQuery: '',
    displayAllItems: [],
    items: [],
  }

  constructor(props) {
    super(props)

    this.handleSearchQueryChangeDebounced = debounce(
      500,
      this.handleSearchQueryChange,
    )
  }

  static getDerivedStateFromProps({ items }, prevState) {
    if (items !== prevState.items) {
      return {
        searchQuery: '',
        displayAllItems: [...items],
        items,
      }
    }

    return null
  }

  handleSearchQueryChange = searchQuery => {
    const query = searchQuery.toLowerCase()
    this.setState({
      searchQuery,
      displayAllItems: this.props.items.filter(item =>
        contains(query, item.label.toLowerCase()),
      ),
    })
  }

  handleFilterChange = (item, checked) => {
    const { onChange, selectedFilters } = this.props
    if (checked) {
      return onChange({ ...selectedFilters, [item.value]: true })
    }

    return onChange(omit([item.value], selectedFilters))
  }

  handleApplyFilters = closeDropdown => {
    this.props.onApply()
    closeDropdown()
  }

  handleDropdownHide = () => {
    this.handleSearchQueryChange('')
    this.props.onReset()
  }

  onClearSelected = e => {
    this.props.onClear(this.props.onApply)

    e.stopPropagation()
  }

  renderFilterItems = array => {
    const { selectedFilters, hideLabelsCount, formatMessage, name } = this.props

    return array.map(item => {
      const isChecked = !!selectedFilters[item.value]
      const message = messages[item.label]
      const label = message ? formatMessage(message) : item.label
      return (
        <CheckBox
          id={item.value.toString()}
          label={label}
          className="checkbox"
          onChange={checked => this.handleFilterChange(item, checked)}
          key={item.value}
          name={item.value}
          checked={isChecked}
        >
          {!hideLabelsCount && <FilterItemsCount>{item.count}</FilterItemsCount>}
          {name === FILTERS_TYPES.PRODUCT_FEATURES && <div data-test-id="filter-icon">{renderFilterIcon(item.value, theme.colors.gray5)}</div>}
        </CheckBox>
      )
    })
  }

  render() {
    const {
      title,
      formatMessage,
      searchActive,
      selectedFilters,
      selectedItems,
      testId,
      onDropdownShow,
      showSelectedSection,
      hideLabelsCount,
    } = this.props
    const { displayAllItems, searchQuery } = this.state
    const disabled = isEmpty(displayAllItems)
    const selectedFiltersLength = Object.keys(selectedFilters).length
    const selectedItemsLength = Object.keys(selectedItems).length
    const isClearAllShown = showSelectedSection && selectedItemsLength > 0 && selectedFiltersLength > 0
    const isNewFilters = !isEqual(selectedFilters, selectedItems)
    const isShowAllBtn = !isEmpty(selectedItems) && isEmpty(selectedFilters)
    const selectedTotalCount = sumBy('count', displayAllItems.filter(item => selectedFilters[item.value]))
    const displaySelectedItems = showSelectedSection ? displayAllItems.filter(
      item => !!selectedItems[item.value],
    ) : []
    const displayNotSelectedItems = showSelectedSection ? displayAllItems.filter(
      item => !selectedItems[item.value],
    ) : displayAllItems

    const handleMobileClick = fn => {
      this.handleApplyFilters(fn)
    }

    return (
      <Dropdown
        title={title}
        disabled={disabled}
        selectedFiltersLength={selectedFiltersLength}
        testId={testId}
        onDropdownHide={this.handleDropdownHide}
        onDropdownShow={onDropdownShow}
        onClearSelected={this.onClearSelected}
        calculatePositionFromParent={this.props.calculatePositionFromParent}
        portalNode={this.props.portalNode}
      >
        {closeDropdown => (
          <>
            {searchActive && (
              <Header>
                <Search
                  testId="filter_list_search"
                  query={searchQuery}
                  onSearchChange={this.handleSearchQueryChangeDebounced}
                  placeholder={formatMessage(messages.searchPlaceholder)}
                  isEmpty={displayAllItems.length === 0}
                />
              </Header>
            )}
            <FiltersDropdownScroll $isNewFilters={isNewFilters}>
              {isClearAllShown && (
                <ClearAll onClick={() => this.props.onClear()}>
                  <X color={theme.colors.gray5} />
                  <ClearBtn strongBold>
                    <FormattedMessage {...messages.clearAll} />
                  </ClearBtn>
                </ClearAll>
              )}
              {!!displaySelectedItems.length && !searchQuery && (
                <>
                  <div>
                    {this.renderFilterItems(displaySelectedItems)}
                  </div>
                  <DropdownItemsSeparator />
                </>
              )}
              {this.renderFilterItems(
                searchQuery ? displayAllItems : displayNotSelectedItems,
              )}
            </FiltersDropdownScroll>
            {isNewFilters && (
              <Footer>
                <ApplyFiltersBtn
                  size={BUTTON_SIZE.SMALL}
                  onClick={() => handleMobileClick(closeDropdown)}
                  $selectedTotalCount={!hideLabelsCount && selectedTotalCount}
                >
                  <FormattedMessage {...messages[isShowAllBtn ? 'showAll' : 'applyBtn']} />
                </ApplyFiltersBtn>
              </Footer>
            )}
          </>
        )}
      </Dropdown>
    )
  }
}

export default MultiFilter
