import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate, generatePath } from 'react-router-dom'

import theme from 'theme'
import { ROUTES } from 'consts'
import {
  CATEGORIES_MAP,
  DefaultIcon,
} from 'components/CategoriesDropdown/CategoriesIcons'
import { ArrowLeftV2, ArrowRightXS } from 'components/Icons'
import { trackAllProductsClicked } from 'services/analytics'

import Carousel from 'components/Carousel'
import { userDataSelector } from 'containers/UserInfo/selectors'
import FilteredPageTile from 'components/FilteredPageTile'
import { DROPDOWN_FILTERED_PAGE_VARIANTS } from 'components/CategoriesDropdown/FilteredPagesSection/consts'
import { FILTERED_PAGE_MAP } from 'components/FilteredPageTile/consts'
import {
  Container,
  ReturnButtonContainer,
  ReturnIconContainer,
  ReturnText,
  ScrollableContainer,
  CategoryContainer,
  CategoryTitleContainer,
  TitleText,
  CategoryCountContainer,
  CountText,
  TilesContainer,
  TilesOverflowContainer,
  CategoriesContainer,
} from './styles'
import messages from '../../messages'

const CategoriesList = ({
  categories,
  closeCategoriesList,
  mobileMenuOffset,
}) => {
  const navigate = useNavigate()
  const { offerDetails } = useSelector(userDataSelector)
  const { formatMessage } = useIntl()

  const handleCategorySelect = category =>
    navigate(generatePath(ROUTES.PRODUCTS, { category }))

  const handleAllProductsLinkClick = () => {
    trackAllProductsClicked()
    navigate(ROUTES.ALL_PRODUCTS)
  }

  return (
    <Container data-test-id="categories-list-container">
      <ReturnButtonContainer onClick={closeCategoriesList}>
        <ReturnIconContainer>
          <ArrowLeftV2 color={theme.colors.gray5} />
        </ReturnIconContainer>
        <ReturnText>{formatMessage(messages.return)}</ReturnText>
      </ReturnButtonContainer>
      <ScrollableContainer $mobileMenuOffset={mobileMenuOffset}>
        <Carousel
          hideControls
          ScrollContainer={TilesContainer}
          OverflowContainer={TilesOverflowContainer}
        >
          {offerDetails?.hasNovelties && (
            <FilteredPageTile
              variantConfig={
                DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.NOVELTIES]
              }
            />
          )}
          {offerDetails?.hasActiveClearances && (
            <FilteredPageTile
              variantConfig={
                DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.CLEARANCE]
              }
            />
          )}
          {offerDetails?.hasBestsellers && (
            <FilteredPageTile
              variantConfig={
                DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.BESTSELLERS]
              }
            />
          )}
          {offerDetails?.hasActivePromotions && (
            <FilteredPageTile
              variantConfig={
                DROPDOWN_FILTERED_PAGE_VARIANTS[FILTERED_PAGE_MAP.PROMOTIONS]
              }
            />
          )}
        </Carousel>
        <CategoryContainer
          onClick={handleAllProductsLinkClick}
          data-test-id="all-products-link"
        >
          <CategoryTitleContainer>
            <DefaultIcon />
            <TitleText>{formatMessage(messages.allProducts)}</TitleText>
          </CategoryTitleContainer>
          <CategoryCountContainer>
            <ArrowRightXS color={theme.colors.gray7} />
          </CategoryCountContainer>
        </CategoryContainer>
        <CategoriesContainer>
          {categories.map(category => {
            const CategoryIcon = CATEGORIES_MAP[category.id] || DefaultIcon

            return (
              <CategoryContainer
                key={category.id}
                onClick={() => handleCategorySelect(category.id)}
              >
                <CategoryTitleContainer>
                  <CategoryIcon />
                  <TitleText>{category.name}</TitleText>
                </CategoryTitleContainer>
                <CategoryCountContainer>
                  <CountText>{category.productCount}</CountText>
                  <ArrowRightXS color={theme.colors.gray7} />
                </CategoryCountContainer>
              </CategoryContainer>
            )
          })}
        </CategoriesContainer>
      </ScrollableContainer>
    </Container>
  )
}

export default CategoriesList
