import React, { useState, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { identity } from 'lodash'

import theme from 'theme'
import { MOBILE_FILTERS_QUERY } from 'components/Filters/consts'
import { ArrowDownFilter, ArrowUpFilter, X } from 'components/Icons'

import DropdownBody from 'components/DropdownBody'
import {
  DropdownWrapper,
  DropdownControl,
  DropdownTitle,
  DropdownIconWrapper,
  DropdownSelectedCount,
  ClearSelectedBtn,
} from './styles'

const Dropdown = ({
  onDropdownShow = identity,
  onDropdownHide = identity,
  maxWidth = 320,
  isDropdownInvert = false,
  title,
  children,
  testId,
  selectedFiltersLength,
  onClearSelected,
  calculatePositionFromParent,
  portalNode,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [offset, setOffset] = useState(null)
  const isMobile = useMediaQuery({ query: MOBILE_FILTERS_QUERY })
  const dropdownNode = useRef(null)

  useEffect(() => {
    const onResize = () => {
      if (!isMobile) {
        hideDropdown()
      }
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const showDropdown = () => {
    if (!disabled) {
      setIsOpen(true)
      onDropdownShow()
    }
  }

  const hideDropdown = () => {
    setIsOpen(false)
    onDropdownHide()
  }

  const toggleDropdownOpen = () => {
    if (isOpen) {
      hideDropdown()
    } else {
      const {
        top,
        height,
        left,
        right,
      } = dropdownNode.current.getBoundingClientRect()
      const { offsetLeft, offsetTop } = dropdownNode.current
      const invertPosition = right - maxWidth
      /* eslint-disable */
      // flag controls how position is calculated relative to parent
      // mainly useful for dropdowns in sticky containers
      // can be passed as object with additional left/top parameters to control position
      // usually used when providing custom portalNode
      calculatePositionFromParent
        ? setOffset({
            left: offsetLeft + (calculatePositionFromParent.left || 0),
            top: offsetTop + (calculatePositionFromParent.top || 0) + height + 8,
        })
        : setOffset({
            top: top + height + window.scrollY + 8,
            left: isDropdownInvert ? invertPosition : left,
          })
      /* eslint-enable */
      showDropdown()
    }
  }

  return (
    <DropdownWrapper ref={dropdownNode}>
      <DropdownControl
        data-test-id={testId}
        onClick={toggleDropdownOpen}
        isOpen={isOpen}
        disabled={disabled}
      >
        <DropdownTitle {...{ isOpen }}>{title}</DropdownTitle>
        {selectedFiltersLength > 0 && (
          <>
            <DropdownSelectedCount isOpen={isOpen}>
              {selectedFiltersLength}
            </DropdownSelectedCount>
            <ClearSelectedBtn onClick={e => onClearSelected(e)}>
              <X width={26.66} height={19} color={theme.colors.white} />
            </ClearSelectedBtn>
          </>
        )}
        <DropdownIconWrapper>
          {isOpen ? <ArrowUpFilter /> : <ArrowDownFilter />}
        </DropdownIconWrapper>
      </DropdownControl>
      {isOpen && (
        <DropdownBody
          testId="filters-dropdown-content"
          portalNode={portalNode}
          onOverlayClick={hideDropdown}
          {...{ offset, maxWidth, hideDropdown, isMobile }}
        >
          {children(hideDropdown)}
        </DropdownBody>
      )}
    </DropdownWrapper>
  )
}

export default Dropdown
