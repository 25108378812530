import { makeMessages, makeTranslation } from 'utils'

const messages = {
  numberOfProducts: { id: 'products.numberOfProducts' },
  cart: {
    id: 'loggedInLayout.cart',
  },
  lackOfDeliveryAddress: { id: 'lackOfDeliveryAddress' },
  summary: 'summary',
  amountNet: 'amountNet',
  delivery: 'delivery',
  toPay: 'toPay',
  change: 'change',
  amountGross: 'amountGross',
  amountCanChange: 'amountCanChange',
  goToTheOrder: 'goToTheOrder',
  keepShoping: 'keepShoping',
  addAll: 'addAll',
  removeAll: 'removeAll',
  emptyCart: 'emptyCart',
  submitOrder: 'submitOrder',
  orderComments: 'orderComments',
  continueShopping: 'continueShopping',
  changeToSelfPickup: 'changeToSelfPickup',
  errorCreditLimitLine1: 'errorCreditLimitLine1',
  errorCreditLimitLine2: 'errorCreditLimitLine2',
  errorCreditLimitLine3: 'errorCreditLimitLine3',
  errorHeading: 'error.heading',
  selectedRouteInfo: 'selectedRouteInfo',
  differentRoutes: 'differentRoutes',
  moveAll: 'moveAll',
  deliveryBidfood: 'deliveryBidfood',
  goodsDeliveryPeriod: 'goodsDeliveryPeriod',
  pickGoodsDeliveryPeriod: 'pickGoodsDeliveryPeriod',
  pickGoodsdeliveryType: 'pickGoodsdeliveryType',
  saveGoodsDeliveryPeriod: 'saveGoodsDeliveryPeriod',
  saveComment: 'saveComment',
  deliveryRoute: 'deliveryRoute',
  deliveryType: 'deliveryType',
  selfPickUp: 'selfPickUp',
  orderVAT: 'orderVAT',
  free: 'free',
  removeConfirmationDialog: 'removeConfirmationDialog',
  clearCartModal: 'clearCartModal',
  abort: 'abort',
  modalHeader: 'modalHeader',
  addBtn: 'addBtn',
  changeBtn: 'changeBtn',
  nonStockDeliveries: 'nonStockDeliveries',
  noLongerActive: 'noLongerActive',
  addToMeetLimit: 'addToMeetLimit',
  moveAllProducts: 'moveAllProducts',
  10206051042: 'orderError.10206051042',
  10206051043: 'orderError.10206051043',
  10206051041: 'orderError.10206051041',
  10206051044: 'orderError.10206051044',
  10206050031: 'orderError.10206050031',
  10206050033: 'orderError.10206050033',
  10206050047: 'orderError.10206050047',
  10206055045: 'orderError.10206055045',
  noTimeSlots: 'orderError.noTimeSlots',
  paymentTitle: 'payment.title',
  paymentDebt: 'payment.debt',
  paymentOverdue: 'payment.overdue',
  paymentMethod: 'payment.method',
  paymentCreditLimit: 'payment.creditLimit',
  addComment: 'addComment',
  yourComment: 'yourComment',
  freeDelivery: 'freeDelivery',
  lacksToFreeDelivery: 'lacksToFreeDelivery',
  meetLogisticLimitInfo: 'meetLogisticLimitInfo',
  grossSum: 'grossSum',
  unavailableProducts: 'unavailableProducts',
  contactCS: 'contactCS',
  payBtn: { id: 'payment.payBtn' },
  missing: { id: 'orderDetails.none' },
  seePromotion: { id: 'promoProduct.seePromotion' },
  grossPrice: { id: 'prices.gross' },
  points: { id: 'points' },
  overduePlural: { id: 'plural.overdue' },
  finances: 'finances',
  invoicesAmount: 'invoicesAmount',
  paymentDate: 'paymentDate',
  paymentSummary: 'paymentSummary',
  deliveryPlace: 'deliveryPlace',
  orderCta: 'orderCta',
  deliveriesOn: 'deliveriesOn',
  receiveDeliveries: 'receiveDeliveries',
  receiveDeliveriesShort: 'receiveDeliveriesShort',
  headerDeliveriesCount: 'headerDeliveriesCount',
  deliveriesCount: 'deliveriesCount',
  deliveriesCountGenitive: 'deliveriesCountGenitive',
  yourCreditLimit: 'yourCreditLimit',
  overdue: 'overdue',
  payBills: 'payBills',
  sendForApproval: 'sendForApproval',
  availableLimit: 'availableLimit',
  invoices: { id: 'plural.invoices' },
  includesOtherOrders: 'includesOtherOrders',
  incomingOrder: 'incomingOrder',
  addProductsTill: 'addProductsTill',
  payOverdueBills: 'payOverdueBills',
  promotionsSection: 'promotionsSection',
  productPresent: 'productPresent',
  showProduct: 'showProduct',
  clearCart: 'clearCart',
  hints: 'hints',
  invoicesToPay: 'invoicesToPay',
  cartValue: 'cartValue',
  overdueInvoices: 'overdueInvoices',
  afterDeadline: 'afterDeadline',
  removeAllUnavailable: 'removeAllUnavailable',
  removeUnavailableInfo: 'removeUnavailableInfo',
  hideDuesCtaBtn: 'hideDuesCtaBtn',
  hasOutOfStocks: 'orderError.hasOutOfStocks',
  savingsLabel: 'savingsLabel',
}

export default makeMessages(messages, makeTranslation('cart'))
