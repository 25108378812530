export const ORDERS_ROUTES = {
  LIST: '/orders',
  DETAILS: '/orders/:orderId',
}

export const ORDER_STATUS = {
  TO_BE_ACCEPTED: 0,
  ACCEPTED: 1,
  IN_PROGRESS: 2,
  CANCELED: 3,
  COMPLETED: 4,
}

export const CANCELLABLE_ORDER_STATUS = [
  ORDER_STATUS.TO_BE_ACCEPTED,
  ORDER_STATUS.ACCEPTED,
]

export const FINALIZED_ORDER_STATUS = [
  ORDER_STATUS.CANCELED,
  ORDER_STATUS.COMPLETED,
]

export const FLOATING_PRICE_ORDER_STATUS = [
  ORDER_STATUS.TO_BE_ACCEPTED,
  ORDER_STATUS.ACCEPTED,
  ORDER_STATUS.IN_PROGRESS,
]

export const ORDER_SOURCE_LABELS = {
  Online: 'orderDetails.source.online',
}
