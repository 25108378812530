import styled from 'styled-components'

import theme from 'theme'
import { H3 } from 'components/Text'

export const TitleText = styled(H3)`
  color: ${theme.colors.primary};
  font-family: ${theme.fonts.primary};
  font-size: 28px;
  margin-bottom: ${theme.spacing.sm};
  line-height: 40px;
  font-weight: ${theme.fontWeights.bold};
`

export const ProductCount = styled.span`
  margin-left: ${theme.spacing.xs};
  color: ${theme.colors.gray5};
  font-family: ${theme.fonts.primary};
  font-size: ${theme.textSizes.xxs};
`

export const InlineTitleText = styled.span`
  color: ${p => p.$textColor || 'inherit'};
`
