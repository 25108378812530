import Drinks from './U5-Drinks'
import Meat from './D5-Meat'
import Cheese from './H5-Cheese'
import Fish from './E5-Fish'
import Fruits from './F5-Fruits'
import Bread from './I5-Bread'
import Mushroom from './G5-Mushroom'
import Cupcake from './L5-Cupcake'
import Honey from './T5-Honey'
import Ketchup from './S5-Ketchup'
import Oil from './M5-Oil'
import Wheat from './O5-Wheat'
import Syrop from './K5-Syrop'
import Spices from './P5-Spices'
import Soup from './R5-Soup'
import Fries from './N5-Fries'
import Alkohol from './Y8-Alkohol'
import NonFood from './Z5-NonFood'
import Coffee from './W5-Coffee'
import Icecream from './J5-Icecream'
import Sushi from './B5-Sushi'
import Wege from './C5-Wege'

export { default as DefaultIcon } from './Default'

export const CATEGORIES_MAP = {
  U5: Drinks,
  D5: Meat,
  H5: Cheese,
  E5: Fish,
  F5: Fruits,
  I5: Bread,
  G5: Mushroom,
  L5: Cupcake,
  T5: Honey,
  S5: Ketchup,
  M5: Oil,
  O5: Wheat,
  K5: Syrop,
  P5: Spices,
  R5: Soup,
  N5: Fries,
  Y8: Alkohol,
  Z5: NonFood,
  W5: Coffee,
  J5: Icecream,
  B5: Sushi,
  C5: Wege,
}
