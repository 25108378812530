import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { ROUTES, APP_BREAKPOINTS } from 'consts'
import {
  EVENT_ORIGIN,
  NAV_BAR_EVENTS,
  trackEventWithOrigin,
} from 'services/analytics'
import { closestTimerDeliveryDateSelector } from 'containers/Delivery/selectors'
import SwitchIcon from 'components/Icons/NavMenu/Switch'
import { TertiaryDashboardBtn } from 'views/Dashboard/styles'
import TextTooltip from 'components/Tooltip/TextTooltip'

import DeadlineOrderDate from './DeadlineOrderDate'
import messages from './messages'

import {
  AccountDetailsContainer,
  AccountDetailsTextContainer,
  AccountDetailsText,
  AccountDetailsSecondaryText,
} from './styles'

const AccountDetails = ({
  userData: { customerName, deliveryAddress, deliveryCity, multipleLocations },
}) => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const hasTabletMinWidth = useMediaQuery({
    query: `(min-width: ${APP_BREAKPOINTS.TABLET_START}px)`,
  })

  const closestDeliveryDate = useSelector(closestTimerDeliveryDateSelector)

  const handleNavigateLocationSelect = useCallback(
    () => {
      navigate(ROUTES.CHANGE_LOCATION)
      trackEventWithOrigin({
        event: NAV_BAR_EVENTS.SWITCH_ACCOUNT_CLICKED,
        origin: EVENT_ORIGIN.DASHBOARD,
      })
    },
    [navigate],
  )

  return (
    <AccountDetailsContainer>
      <AccountDetailsTextContainer>
        {hasTabletMinWidth ? (
          <AccountDetailsText data-test-id="account-details-text">
            {`${customerName}, `}
            <AccountDetailsSecondaryText>
              {`${deliveryAddress}, ${deliveryCity}`}
            </AccountDetailsSecondaryText>
          </AccountDetailsText>
        ) : (
          <>
            <AccountDetailsText data-test-id="account-details-text">
              {`${customerName}`}
            </AccountDetailsText>
            <AccountDetailsSecondaryText>
              {`${deliveryAddress}, ${deliveryCity}`}
            </AccountDetailsSecondaryText>
          </>
        )}
        <DeadlineOrderDate closestDeliveryDate={closestDeliveryDate} />
      </AccountDetailsTextContainer>
      {multipleLocations && (
        <TextTooltip content={formatMessage(messages.switchBtn)}>
          <TertiaryDashboardBtn
            data-test-id="change-location-btn"
            onClick={handleNavigateLocationSelect}
            style={{ width: 'auto' }}
          >
            <SwitchIcon />
          </TertiaryDashboardBtn>
        </TextTooltip>
      )}
    </AccountDetailsContainer>
  )
}

export default AccountDetails
