import styled from 'styled-components'
import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'
import { H3v2 } from 'components/Text'

export const DashboardTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.base};
  border-radius: 16px;
  overflow: hidden;
  background-color: ${theme.colors.lightGrey};
  padding: ${theme.spacing.sm} ${theme.spacing.xs};
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    padding: ${theme.spacing.base} ${theme.spacing.sm} ${theme.spacing.sm};
  }
`

export const DashboardTileHeaderWrapper = styled.div`
  display: flex;
  padding: 0 ${theme.spacing.xs};
  position: relative;
`

export const DashboardTileActionButton = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 8px;
  top: 4px;
  cursor: pointer;
  &:hover {
    path {
      stroke: ${theme.colors.primary};
    }
  }
`

export const DashboardTileHeaderContentWrapper = styled.div`
  width: calc(100% - 24px);
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    width: 100%;
  }
`
export const DashboardTileHeaderIconWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  transform: ${p => (p.$isContentOpened ? 'scale(1, -1)' : 'scale(-1, 1)')};
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 6px;

  &:hover svg path {
    stroke: ${theme.colors.primary};
  }
`

export const DashboardTileHeaderText = styled(H3v2)`
  font-weight: ${theme.fontWeights.bold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: ${APP_BREAKPOINTS.TABLET_START}px) {
    font-size: 20px;
    line-height: 32px;
  }
`

export const DashboardTileHeaderTitle = styled(DashboardTileHeaderText)`
  color: ${theme.colors.gray7};
`
export const DashboardTileHeaderSubtitle = styled(DashboardTileHeaderText)`
  color: ${theme.colors.primary};
`

export const DashboardTileContentWrapper = styled.div`
  display: ${p =>
    !p.$isContentOpened && !p.$disableCollapse ? 'none' : 'block'};
  height: 100%;
`
export const DashboardTileFooterWrapper = styled.div`
  margin-top: auto;
`
