import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import {
  userDeliveriesSelector,
  closestOrderDateSelector,
} from 'containers/Delivery/selectors'
import { getUserDeliveries } from 'containers/Delivery/actions'
import DashboardTile from 'components/DashboardTile'
import { DeliveriesTileContainer } from 'views/Dashboard/Deliveries/styles'
import { useSubtitleMsg } from './utils'
import TileContent from './TileContent'

import messages from './messages'

const Deliveries = () => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const deliveries = useSelector(userDeliveriesSelector)
  const closestOrderDate = useSelector(closestOrderDateSelector)

  useEffect(() => {
    dispatch(getUserDeliveries.delta())
  }, [])

  const subtitleMsg = useSubtitleMsg(closestOrderDate)

  return (
    <DashboardTile
      title={formatMessage(messages.closestDelivery)}
      subtitle={subtitleMsg}
      ContainerComponent={DeliveriesTileContainer}
      footer={<TileContent deliveries={deliveries} />}
      containerTestId="deliveries-tile-container"
    />
  )
}

export default Deliveries
