/* eslint-disable react/no-array-index-key */
import React from 'react'
import {
  MaxIconContainer,
  ProgressContainer,
  ProgressTick,
  ThresholdText,
  TopContainer,
  UniqueProgress,
} from 'components/Product/PromoGroup/PrizeRow/PrizeProgress/styles'
import { formatPrice } from 'utils'
import { CheckV3 } from 'components/Icons'
import theme from 'theme'
import SplittableProgressBar from 'components/SplittableProgressBar'

const PrizeProgress = ({ reward }) => (
  <ProgressContainer>
    {reward.maxApplicationsReached && !reward.toCollect ? (
      <MaxIconContainer>
        <CheckV3 color={theme.colors.white} />
      </MaxIconContainer>
    ) : (
      <>
        <TopContainer>
          {reward.uniqueProductsThreshold > 1 && (
            <UniqueProgress>
              {Array(reward.uniqueProductsThreshold)
                .fill(null)
                .map((tick, index) => (
                  <ProgressTick
                    data-test-id="unique-progress-tick"
                    key={index}
                    filled={index < reward.uniqueProductsCount}
                  />
                ))}
            </UniqueProgress>
          )}
          <ThresholdText>
            {formatPrice(reward.threshold, {
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
          </ThresholdText>
        </TopContainer>
        <SplittableProgressBar
          progress={reward.thresholdProgression}
          totalProgress={reward.thresholdDelta}
          threshold={reward.threshold}
          maxReached={reward.maxApplicationsReached}
          toCollect={reward.toCollect}
        />
      </>
    )}
  </ProgressContainer>
)

export default PrizeProgress
