import React from 'react'
import { useIntl } from 'react-intl'

import ContactFormModal from 'components/ContactForm/ContactFormModal'
import { sendFeedbackFormActions } from 'containers/Settings/actions'
import { useClaimForm } from 'components/ContactForm/utils'

import messages from '../messages'

const ContactFormModalWrapper = ({
  children,
  isContactFormOpen,
  onContactFormClose,
}) => {
  const { formatMessage } = useIntl()

  const [isSubmitting, onSubmit, submitError] = useClaimForm({
    isContactFormOpen,
    onSubmitSuccess: onContactFormClose,
    actionsGroup: sendFeedbackFormActions,
  })

  return (
    <>
      <ContactFormModal
        isOpen={isContactFormOpen}
        onClose={onContactFormClose}
        headingText={formatMessage(messages.contactFormTitle)}
        descriptionText={formatMessage(messages.contactFormDesc)}
        isTopicField
        onSubmit={onSubmit}
        submitDisabled={isSubmitting}
        submitError={submitError}
        hasFileSelector
      />

      {children}
    </>
  )
}

export default ContactFormModalWrapper
