import React from 'react'
import { useIntl } from 'react-intl'

import {
  useLazyGetRecommendationsQuery,
  useGetRecommendationsState,
} from 'containers/Products/rtkApi'
import ProductCarousel from 'views/ProductDetails/ProductCarousel'
import { useCarouselItems } from 'views/ProductDetails/utils'

import messages from './messages'
import { RecommendedBoxContainer } from './styles'

const RecommendedBox = ({
  categoryId,
  groupId,
  subgroupId,
  pageName,
  Container = RecommendedBoxContainer,
  itemListId,
  itemListName,
}) => {
  const { formatMessage } = useIntl()

  const [fetchRecommendations] = useLazyGetRecommendationsQuery()
  const recommendationsState = useGetRecommendationsState({
    categoryId,
    groupId,
    subgroupId,
    pageName,
  })

  const {
    setCarouselElement,
    carouselItems,
    isCarouselFetching,
    itemModelId,
    attributionToken,
  } = useCarouselItems({
    recommendationsState,
    fetchRecommendations: () =>
      fetchRecommendations({ categoryId, groupId, subgroupId, pageName }),
  })

  return (
    <ProductCarousel
      ref={setCarouselElement}
      isLoading={isCarouselFetching}
      products={carouselItems}
      itemModelId={itemModelId}
      attributionToken={attributionToken}
      itemListName={itemListName}
      itemListId={itemListId}
      title={formatMessage(messages.title)}
      Container={Container}
      testId="recommended-section"
    />
  )
}

export default RecommendedBox
