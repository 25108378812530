import { ROUTES } from 'consts'
import messages from 'components/FilteredPageTile/messages'

export const FILTERED_PAGE_MAP = {
  BESTSELLERS: 'BESTSELLERS',
  NOVELTIES: 'NOVELTIES',
  CLEARANCE: 'CLEARANCE',
  PROMOTIONS: 'PROMOTIONS',
}

export const BASE_FILTERED_PAGE_VARIANTS = {
  [FILTERED_PAGE_MAP.BESTSELLERS]: {
    route: ROUTES.BESTSELLER_PRODUCTS,
    message: messages.bestsellersTile,
  },
  [FILTERED_PAGE_MAP.NOVELTIES]: {
    route: ROUTES.NOVELTY_PRODUCTS,
    message: messages.noveltyTile,
  },
  [FILTERED_PAGE_MAP.PROMOTIONS]: {
    route: ROUTES.PROMO_PRODUCTS,
    message: messages.promoTile,
  },
  [FILTERED_PAGE_MAP.CLEARANCE]: {
    route: ROUTES.CLEARANCE_PRODUCTS,
    message: messages.clearanceTile,
  },
}
