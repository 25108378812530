import React from 'react'
import { Flex } from '@rebass/grid'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  reduxForm,
  getFormSubmitErrors,
  Form,
  Field,
} from 'redux-form/immutable'
import { connect } from 'react-redux'
import { allCountries } from 'country-telephone-data'
import { createStructuredSelector } from 'reselect'
import { compose } from 'lodash/fp'

import { RECAPTCHA_KEY, ROUTES } from 'consts'
import { wrapSagaWithSubmissionErrorsThrow } from 'utils/redux-form-utils'
import { withSpinner, withModal } from 'hocs'
import authContainer, {
  SIGN_UP_FORM_NAME,
  SIGN_UP_REDUCER_NAME,
  AUTH_REDUCER_NAME,
  CHECK_LOGIN_REDUCER_NAME,
} from 'containers/Auth'
import { SIGN_IN_MODAL } from 'containers/App/modalTypes'
import { PrimaryBtn } from 'components/Button'
import {
  CheckBoxComponent,
  SelectComponent,
  Recaptcha,
} from 'components/Fields'
import BackButton from 'components/Back'

import { useRichFormatMessage } from 'utils'
import messages from './messages'
import {
  CONTACT_FIELDS,
  ADDRESS_FIELDS,
  MARKETING_CHECKBOXES,
  Input,
} from './fields'
import {
  PageLayout,
  Description,
  Title,
  FormSection,
  FormSectionHeading,
  FormWrapper,
  LoginTxt,
  Href,
} from './styledComponents'
import Succeed from './Succeed'
import ErrorModal from './ErrorModal'
import { validate, scrollToFirstError, asyncValidate } from './utils'
import CheckBoxText from './CheckBoxText'

const countriesList = allCountries.map(({ iso2, dialCode, name }) => ({
  value: iso2.toUpperCase(),
  label: `+${dialCode} ${name}`,
  iso2,
  dialCode,
  name,
}))

const SignUp = ({
  isSignUpFetching,
  intl: { formatMessage },
  signUp,
  submitSucceeded,
  submitFailed,
  submitting,
  signUpStepEmail,
  handleSubmit,
}) => {
  const formatRichMessage = useRichFormatMessage()
  const onSubmit = () => wrapSagaWithSubmissionErrorsThrow(signUp)()

  if (submitSucceeded && !submitting && !submitFailed) {
    return <Succeed email={signUpStepEmail} />
  }

  return (
    <PageLayout>
      {window.history.length > 2 && (
        <BackButton data-test-id="reg_back_button" />
      )}

      <FormWrapper>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title data-test-id="reg_title">
            <FormattedMessage {...messages.header} />
          </Title>
          <Description>
            <FormattedMessage {...messages.description} />
          </Description>
          <FormSection>
            <FormSectionHeading>
              <FormattedMessage {...messages.login} />
            </FormSectionHeading>
            <LoginTxt>{formatRichMessage(messages.loginTxt)}</LoginTxt>

            <Input
              dataAttr="reg_login"
              label={<FormattedMessage {...messages.login} />}
              name="login"
              required
            />
          </FormSection>
          <FormSection>
            <FormSectionHeading>
              <FormattedMessage {...messages.contactData} />
            </FormSectionHeading>
            {CONTACT_FIELDS.map(({ name, ...fieldProps }) => (
              <Input
                key={name}
                dataAttr={`reg_${name}`}
                label={<FormattedMessage {...messages[name]} />}
                {...{ name }}
                {...fieldProps}
              />
            ))}
            <Flex
              width="100%"
              alignItems={['center', 'flex-start']}
              flexDirection="row"
            >
              <Field
                component={SelectComponent}
                name="deliveryCountryCode"
                options={countriesList}
                placeholder={formatMessage(messages.deliveryCountryCode)}
                formatValueLabel={valueObj => `+${valueObj.dialCode}`}
              />
              <Input
                name="phoneNo"
                dataAttr="reg_phoneNo"
                label={<FormattedMessage {...messages.phoneNo} />}
              />
            </Flex>
          </FormSection>
          <FormSection>
            <FormSectionHeading>
              <FormattedMessage {...messages.deliveryAddress} />
            </FormSectionHeading>
            {ADDRESS_FIELDS.map(({ name, ...fieldProps }) => (
              <Input
                dataAttr={`reg_${name}`}
                key={name}
                label={<FormattedMessage {...messages[name]} />}
                {...{ name }}
                {...fieldProps}
              />
            ))}
          </FormSection>
          <FormSection>
            <Field
              key="agreement_1"
              name="agreement_1"
              data-test-id="reg_agreement_required"
              required
              component={CheckBoxComponent}
              alignItems="flex-start"
              title={
                <FormattedMessage
                  {...messages.agreement_1}
                  values={{
                    termsLink: (
                      <Href href={ROUTES.TERMS}>
                        <FormattedMessage {...messages.termsLink} />
                      </Href>
                    ),
                    privacyLink: (
                      <Href href={ROUTES.PRIVACY}>
                        <FormattedMessage {...messages.privacyLink} />
                      </Href>
                    ),
                  }}
                />
              }
            />
            <Field
              key="agreement_2"
              name="agreement_2"
              component={CheckBoxComponent}
              alignItems="flex-start"
              title={<CheckBoxText name="agreement_2" />}
            />
            {MARKETING_CHECKBOXES.map(({ name, required }) => (
              <Field
                key={name}
                component={CheckBoxComponent}
                {...{ name, required }}
                alignItems="center"
                title={<CheckBoxText {...{ name, required }} />}
              />
            ))}
            <Recaptcha name="recaptcha" siteKey={RECAPTCHA_KEY} />
          </FormSection>
          <PrimaryBtn
            type="submit"
            disabled={isSignUpFetching}
            data-test-id="register_submit"
          >
            <FormattedMessage {...messages.submit} />
          </PrimaryBtn>
        </Form>
      </FormWrapper>
    </PageLayout>
  )
}

const mapStateToProps = createStructuredSelector({
  submitErrors: getFormSubmitErrors(SIGN_UP_FORM_NAME),
})

export default compose(
  authContainer,
  injectIntl,
  reduxForm({
    form: SIGN_UP_FORM_NAME,
    validate,
    asyncValidate,
    asyncBlurFields: ['login'],
    onSubmitFail: scrollToFirstError,
    initialValues: {
      agreement_2: false,
      agreement_3: false,
      agreement_4: false,
      deliveryCountryCode: countriesList.find(({ value }) => value === 'PL'),
      customerNo: null,
      phoneNo: null,
    },
  }),
  withSpinner([
    [AUTH_REDUCER_NAME, SIGN_UP_REDUCER_NAME],
    [AUTH_REDUCER_NAME, CHECK_LOGIN_REDUCER_NAME],
  ]),
  connect(mapStateToProps),
  withModal(ErrorModal, SIGN_IN_MODAL),
)(SignUp)
