import styled from 'styled-components'

import theme from 'theme'
import { APP_BREAKPOINTS } from 'consts'

export const NotificationCounter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 9px;
  width: 9px;

  top: 10px;
  left: 28px;
  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    left: 20px;
  }
  border-radius: 50%;
  border: 1px ${theme.colors.white} solid;
  background-color: ${theme.colors.secondary};
  font-size: 9px;
  font-weight: 600;
  line-height: 6px;
  color: ${theme.colors.white};
`

export const NotificationsBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 40px;
  cursor: pointer;
  position: relative;
  border-radius: ${theme.borderRadius.base};

  &[data-state='open'],
  :hover {
    background-color: ${theme.colors.lightGrey};
    ${NotificationCounter} {
      border-color: ${theme.colors.lightGrey};
    }
  }

  @media screen and (min-width: ${APP_BREAKPOINTS.DESKTOP_START}px) {
    width: 40px;
  }
`
