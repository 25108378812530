import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePrevious from 'use-previous'
import { compose, get, prop, identity } from 'lodash/fp'
import { FormattedMessage } from 'react-intl'

import { withModal } from 'hocs'
import { MODAL_OUT_OF_STOCK } from 'containers/App/modalTypes'
import {
  withPageView,
  trackOpenedTemplate,
  trackOutOfOfferProducts,
  SCREENS,
} from 'services/analytics'
import TemplateProductRow from 'components/Product/TemplateProductRow'
import {
  selectTemplateDetailsData,
  selectTemplateDetailsPagination,
  selectTemplateDetailsFinished,
  selectSelectedFilters,
  selectedFiltersParamsSelector,
  selectTemplateSorting,
} from 'containers/Templates/selectors'
import {
  getTemplateFiltersActions,
  templateItemsActions,
  clearTemplateFilters,
} from 'containers/Templates/actions'

import { isProductUnavailable } from 'components/Product/utils'
import Loader from 'components/Loader'
import { GoToTopButton } from 'views/Templates/styles'
import { GoTo } from 'components/Icons'
import theme from 'theme'
import _ from 'lodash'
import { isAutomatic } from '../utils'

import Search from './Search'
import TemplateSorting from './TemplateSorting'
import TemplateFilters from './TemplateFilters'
import messages from '../messages'
import { GO_TO_TOP_OFFSET, TEMPLATE_TYPES } from '../consts'
import {
  TemplateDetailsWrapper,
  TemplateItemsContainer,
  TemplateNotAvailableContainer,
  TemplateItem,
  NoLongerActiveContainer,
  NoLongerActiveRemainingContainer,
  NoLongerActive,
  NoLongerActiveRemaining,
  NoLongerActiveCount,
  NoLongerActiveRemainingCount,
  ToolBarContainer,
  SearchSortingContainer,
  TemplateProductListWrapper,
} from './styles'
import Header from './Header'
import EmptyTemplate from './EmptyTemplate'
import OutOfStockModal from './OutOfStockModal'

const GO_TO_TOP_BUTTON_STYLES = {
  VISIBLE: {
    zIndex: theme.zIndex.base,
    opacity: 1,
  },
  INVISIBLE: {
    zIndex: -1,
    opacity: 0,
  },
}

const TemplateDetails = ({
  trackPageView = identity,
  isWideScreen,
  templateDetails: { id, name, type, itemsCount } = {},
  isMobile,
}) => {
  const dispatch = useDispatch()

  const [searchValue, setSearchValue] = useState('')

  const goToTopRef = useRef(null)

  const setGoToTopButtonStyle = style => {
    if (goToTopRef.current) {
      goToTopRef.current.style.opacity = style.opacity
      goToTopRef.current.style.zIndex = style.zIndex
    }
  }

  const prevId = usePrevious(id)
  const isSameId = prevId === id
  const isItemsFinished = useSelector(selectTemplateDetailsFinished)
  const items = useSelector(selectTemplateDetailsData)
  const currentSorting = useSelector(selectTemplateSorting)
  const {
    totalPages,
    totalCount,
    inactiveProductsCount: inactiveNumber,
    currentPage,
  } = useSelector(selectTemplateDetailsPagination)
  const selectedFilters = useSelector(selectSelectedFilters)
  const selectedFiltersParams = useSelector(selectedFiltersParamsSelector)
  const isSelectedFilters = Object.keys(selectedFilters).length

  const headProduct = get([0, 'product', 'active'], items)
  const hasInactiveProduct = items.length
    ? prop('active', headProduct) === false || !headProduct
    : false
  const isAllUnavailable = items.every(item => isProductUnavailable(item))
  const isTemplateAutomatic = isAutomatic(type)

  const scrollToTopOfList = () => {
    const offset = isMobile ? GO_TO_TOP_OFFSET.MOBILE : GO_TO_TOP_OFFSET.DESKTOP
    if ((window.scrollY || window.pageYOffset) > offset) {
      window.scrollTo({ top: offset, behavior: 'smooth' })
    }
  }

  const getTemplateItems = ({
    current,
    discardPrevItems,
    search,
    filters,
    sortBy,
  }) => {
    if (id) {
      dispatch(
        templateItemsActions.delta(
          {
            id,
            current,
            search: search || undefined,
            sortBy: sortBy || undefined,
            ...filters,
          },
          { discardPrevItems, type },
        ),
      )
    }
  }

  const getTemplateFilters = filters => {
    if (id) {
      dispatch(
        getTemplateFiltersActions.delta({ id, selectedFiltersParams: filters }),
      )
    }
  }

  const getSortedItems = sortBy => {
    scrollToTopOfList()
    getTemplateItems({
      current: 1,
      discardPrevItems: true,
      search: searchValue,
      filters: selectedFiltersParams,
      sortBy,
    })
  }

  const clearSearch = () => {
    setSearchValue('')
  }
  const clearFilters = () => {
    dispatch(clearTemplateFilters())
  }

  const resetFilters = ({ resetSorting }) => {
    scrollToTopOfList()
    clearSearch()
    clearFilters()
    getTemplateItems({
      current: 1,
      discardPrevItems: true,
      sortBy: resetSorting ? null : currentSorting,
    })
    getTemplateFilters({})
  }

  const searchTemplateItems = newSearchValue => {
    scrollToTopOfList()
    if (isSelectedFilters) {
      clearFilters()
      getTemplateFilters({})
    }

    setSearchValue(newSearchValue)
    getTemplateItems({
      current: 1,
      discardPrevItems: true,
      search: newSearchValue || '',
      sortBy: currentSorting,
    })
  }

  useEffect(
    () => {
      if ((currentPage === null && id) || !isSameId) {
        resetFilters({ resetSorting: true })
      }
    },
    [id, currentPage],
  )

  useEffect(
    () => {
      if (isSelectedFilters) {
        if (searchValue) {
          clearSearch()
        }
        getTemplateItems({
          current: 1,
          discardPrevItems: true,
          filters: selectedFiltersParams,
          sortBy: currentSorting,
        })
        getTemplateFilters(selectedFiltersParams)
      }
    },
    [selectedFilters],
  )

  useEffect(
    () => {
      const debouncedSetGoToTop = _.debounce(setGoToTopButtonStyle, 100)
      const onScroll = () => {
        const {
          scrollHeight,
          scrollTop,
          clientHeight,
        } = document.documentElement
        // directly changing styles for performance reasons
        if ((!isMobile && scrollTop > 160) || (isMobile && scrollTop > 324)) {
          debouncedSetGoToTop(GO_TO_TOP_BUTTON_STYLES.VISIBLE)
        } else {
          debouncedSetGoToTop(GO_TO_TOP_BUTTON_STYLES.INVISIBLE)
        }

        const distanceFromBottom = scrollHeight - scrollTop - clientHeight
        if (
          distanceFromBottom <= 1000 &&
          currentPage < totalPages &&
          isItemsFinished
        ) {
          getTemplateItems({
            current: currentPage + 1,
            discardPrevItems: false,
            search: searchValue,
            filters: selectedFiltersParams,
            sortBy: currentSorting,
          })
        }
      }

      window.removeEventListener('scroll', onScroll)
      window.addEventListener('scroll', onScroll, { passive: true })
      return () => window.removeEventListener('scroll', onScroll)
    },
    [currentPage, totalPages, isItemsFinished],
  )

  useEffect(
    () => {
      if (prevId && !isSameId) {
        trackPageView()
        dispatch(templateItemsActions.clear())
      }
    },
    [id],
  )

  useEffect(
    () => {
      if (type && id && !isSameId) {
        trackOpenedTemplate(type, id)
      }
    },
    [type, id],
  )

  useEffect(
    () => {
      if (hasInactiveProduct && currentPage === 1) {
        const products = items
          .filter(({ product: { active } }) => !active)
          .map(({ product }) => product.id)
        if (products.length) {
          trackOutOfOfferProducts(products, id)
        }
      }
    },
    [hasInactiveProduct, currentPage],
  )

  // handles refetching template details in case of items count change
  const prevItemsCount = usePrevious(itemsCount)
  useEffect(
    () => {
      if (isSameId && prevItemsCount && prevItemsCount !== itemsCount) {
        getTemplateItems({
          current: 1,
          discardPrevItems: true,
          filters: selectedFiltersParams,
          sortBy: currentSorting,
          search: searchValue,
        })
        getTemplateFilters(selectedFiltersParams)
      }
    },
    [itemsCount],
  )

  return (
    <TemplateDetailsWrapper>
      <Header
        editable={type === TEMPLATE_TYPES.CUSTOMER}
        {...{ name, id, totalCount, type, isAllUnavailable, clearSearch }}
      />
      {!!itemsCount && (
        <ToolBarContainer id="template-list-toolbar">
          <TemplateFilters
            {...{
              id,
              selectedFilters,
              resetFilters,
              scrollToTopOfList,
              isTemplateAutomatic,
            }}
          />
          {!isMobile && (
            <SearchSortingContainer>
              <TemplateSorting
                {...{
                  id,
                  type,
                  getSortedItems,
                  currentSorting,
                  isTemplateAutomatic,
                }}
              />
              <Search
                searchItems={searchTemplateItems}
                lastSearchedValue={searchValue}
                {...{ id, type }}
              />
            </SearchSortingContainer>
          )}
        </ToolBarContainer>
      )}
      {/* prettier-ignore */}
      {/* eslint-disable */}
      {!!itemsCount &&
        isMobile && (
          <SearchSortingContainer>
            <TemplateSorting
              {...{
                id,
                type,
                getSortedItems,
                currentSorting,
                isTemplateAutomatic,
              }}
            />
            <Search
              searchItems={searchTemplateItems}
              lastSearchedValue={searchValue}
              {...{ id, type }}
            />
          </SearchSortingContainer>
        )}
      {/* eslint-enable */}
      {isItemsFinished && !totalCount ? (
        <EmptyTemplate
          withFilters={!!searchValue || isSelectedFilters}
          resetFilters={resetFilters}
        />
      ) : (
        <TemplateProductListWrapper>
          {hasInactiveProduct && (
            <TemplateNotAvailableContainer>
              <NoLongerActiveContainer>
                <NoLongerActive>
                  <FormattedMessage {...messages.noLongerActive} />
                </NoLongerActive>
                <NoLongerActiveCount>{inactiveNumber}</NoLongerActiveCount>
              </NoLongerActiveContainer>

              {items.map((product, index) => {
                if (product.product.active) {
                  return null
                }
                return (
                  <TemplateItem
                    key={product.id}
                    data-test-id="template-item-inactive"
                  >
                    <TemplateProductRow
                      {...product}
                      templateType={type}
                      templateId={id}
                      {...{ index, isTemplateAutomatic }}
                    />
                  </TemplateItem>
                )
              })}
            </TemplateNotAvailableContainer>
          )}
          <TemplateItemsContainer>
            {inactiveNumber !== 24 && (
              <NoLongerActiveRemainingContainer>
                <NoLongerActiveRemaining>
                  <FormattedMessage {...messages.noLongerActiveRemaining} />
                </NoLongerActiveRemaining>
                {isItemsFinished && (
                  <NoLongerActiveRemainingCount>
                    {totalCount - inactiveNumber}
                  </NoLongerActiveRemainingCount>
                )}
              </NoLongerActiveRemainingContainer>
            )}
            {items.map((product, index) => {
              if (!product.product.active) {
                return null
              }
              return (
                <TemplateItem
                  key={product.id}
                  data-test-id="template-item-active"
                >
                  <TemplateProductRow
                    {...product}
                    templateType={type}
                    templateId={id}
                    {...{ index, isTemplateAutomatic }}
                  />
                </TemplateItem>
              )
            })}
          </TemplateItemsContainer>
          {!isItemsFinished && <Loader />}
        </TemplateProductListWrapper>
      )}
      <GoToTopButton
        ref={goToTopRef}
        isWideScreen={isWideScreen}
        onClick={() =>
          window.scrollTo({ top: isMobile ? 294 : 149, behavior: 'smooth' })
        }
      >
        <GoTo color={theme.colors.primary} />
      </GoToTopButton>
    </TemplateDetailsWrapper>
  )
}

export default compose(
  withPageView(SCREENS.TEMPLATE_DETAILS),
  withModal(OutOfStockModal, MODAL_OUT_OF_STOCK),
)(TemplateDetails)
