import React from 'react'
import _toNumber from 'lodash/toNumber'

import theme from 'theme'

import { ProgressOuter, ProgressInner } from './styledComponents'
import { PROGRESS_MODE } from './consts'

const CONFIG = {
  [PROGRESS_MODE.DELIVERY]: {
    INNER_COLOR: theme.colors.primary,
    OUTER_COLOR: theme.colors.warning,
  },
  [PROGRESS_MODE.DELIVERY_SUCCESS]: {
    OUTER_COLOR: theme.colors.status.success,
  },
  [PROGRESS_MODE.SALE]: {
    OUTER_COLOR: 'rgba(66, 207, 0, 0.18)',
    INNER_COLOR: theme.colors.secondary,
  },
  [PROGRESS_MODE.SALE_BIG]: {
    OUTER_COLOR: 'rgba(173, 220, 177, 0.7)',
    INNER_COLOR: theme.colors.secondary,
    SIZE: 4,
  },
}

const ProgressBar = ({ value, maxValue, mode }) => {
  if (!mode) return null

  const outterBg = CONFIG[mode].OUTER_COLOR
  const innerBg = CONFIG[mode].INNER_COLOR
  const size = CONFIG[mode].SIZE || 2

  return (
    <ProgressOuter
      width="100%"
      bg={outterBg}
      height={size}
      data-test-id="sale-progress-bar"
    >
      <ProgressInner
        width={`${(100 * _toNumber(value)) / _toNumber(maxValue)}%`}
        bg={innerBg}
      />
    </ProgressOuter>
  )
}

export default ProgressBar
