import React from 'react'

import { makeNumberLengthNormalizer } from 'utils/redux-form-utils'

import { GenericField } from 'components/Fields'

export const CONTACT_FIELDS = [
  {
    name: 'customerNo',
    maxLength: 20,
  },
  { name: 'customerName', maxLength: 250, required: true },
  {
    name: 'customerVatId',
    required: true,
    maxLength: 20,
    normalize: makeNumberLengthNormalizer(),
  },
  { name: 'customerFirstName', maxLength: 50, required: true },
  { name: 'customerLastName', maxLength: 50, required: true },
  { name: 'emailAddress', required: true },
]

export const ADDRESS_FIELDS = [
  { name: 'deliveryAddress', maxLength: 50, required: true },
  { name: 'deliveryPostCode', maxLength: 8, required: true },
  { name: 'deliveryCity', maxLength: 30, required: true },
]

export const MARKETING_CHECKBOXES = [{ name: 'agreement_3' }]

export const Input = ({ movePlaceholder = true, big = true, ...rest }) => (
  <GenericField movePlaceholder={movePlaceholder} big={big} {...rest} />
)
