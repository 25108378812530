import React, { useMemo } from 'react'

import { NotificationBell } from 'components/Icons'
import { APP_BREAKPOINTS } from 'consts'
import { useOfMinWidth } from 'hooks'
import { Popover, PopoverContent, PopoverTrigger } from 'components/Popup'
import {
  HEADER_DROPDOWN_MOBILE_TRANSITIONS,
  HEADER_DROPDOWN_TRANSITIONS,
} from 'components/Popup/transitions'

import { useNotificationFeed } from './utils'
import NotificationCenterModal from './NotificationCenterModal'
import { NotificationCounter, NotificationsBtn } from './styles'

const NotificationCenter = () => {
  const isTablet = useOfMinWidth(APP_BREAKPOINTS.TABLET_START)
  const isDesktop = useOfMinWidth(APP_BREAKPOINTS.DESKTOP_START)

  const { markAsRead, items, metadata } = useNotificationFeed()

  // need to align header close button with trigger button
  const offsetValues = useMemo(
    () => {
      if (isDesktop) {
        return { main: 20, cross: 16 }
      }

      if (isTablet) {
        return { main: 12, cross: 8 }
      }

      return { main: 8 }
    },
    [isDesktop, isTablet],
  )

  return (
    <Popover disableArrow placement="bottom-end" offsetValues={offsetValues}>
      <PopoverTrigger asChild>
        <NotificationsBtn data-test-id="notification-center-btn">
          {!!metadata.unread_count && (
            <NotificationCounter data-test-id="unread-counter" />
          )}
          <NotificationBell />
        </NotificationsBtn>
      </PopoverTrigger>
      <PopoverContent
        style={{
          width: !isTablet && '100%',
        }}
        withOverlay={isTablet}
        transitions={
          isTablet
            ? HEADER_DROPDOWN_TRANSITIONS
            : HEADER_DROPDOWN_MOBILE_TRANSITIONS
        }
      >
        <NotificationCenterModal items={items} markAsRead={markAsRead} />
      </PopoverContent>
    </Popover>
  )
}

export default NotificationCenter
