import React from 'react'

const LogisticLimitError = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#FF4C00" fillOpacity="0.11" />
    <path
      d="M10.2772 13.2468C10.3035 13.1518 10.3483 13.063 10.4091 12.9853C10.4698 12.9077 10.5452 12.8427 10.631 12.7943C10.7168 12.7458 10.8113 12.7147 10.9092 12.7028C11.007 12.6909 11.1062 12.6983 11.2012 12.7248L12.9042 13.1968C13.3555 13.3194 13.7674 13.5568 14.0997 13.8859C14.432 14.215 14.6733 14.6246 14.8002 15.0748L16.9512 22.8608L17.1092 23.4078C17.7469 23.6428 18.2844 24.0902 18.6312 24.6748L18.9412 24.5788L27.8112 22.2738C27.9065 22.249 28.0058 22.2432 28.1034 22.2567C28.201 22.2703 28.2949 22.3029 28.3799 22.3528C28.4649 22.4026 28.5392 22.4688 28.5986 22.5473C28.658 22.6259 28.7014 22.7154 28.7262 22.8108C28.751 22.9061 28.7568 23.0054 28.7433 23.103C28.7297 23.2006 28.6971 23.2945 28.6472 23.3795C28.5973 23.4645 28.5312 23.5388 28.4526 23.5982C28.374 23.6576 28.2845 23.701 28.1892 23.7258L19.3522 26.0218L19.0222 26.1238C19.0162 27.3938 18.1392 28.5558 16.8122 28.8998C15.2222 29.3138 13.5872 28.3978 13.1612 26.8558C12.7352 25.3128 13.6792 23.7268 15.2692 23.3138C15.3475 23.2936 15.4266 23.2762 15.5062 23.2618L13.3542 15.4738C13.2963 15.2737 13.1875 15.092 13.0386 14.9464C12.8896 14.8008 12.7055 14.6962 12.5042 14.6428L10.8002 14.1698C10.7052 14.1435 10.6164 14.0988 10.5387 14.0382C10.461 13.9776 10.396 13.9023 10.3475 13.8166C10.2989 13.7309 10.2677 13.6365 10.2556 13.5387C10.2436 13.4409 10.2509 13.3417 10.2772 13.2468Z"
      fill="#E45A4F"
    />
    <path
      d="M17.564 16.73L18.079 18.593C18.564 20.348 18.806 21.226 19.519 21.625C20.232 22.025 21.137 21.789 22.947 21.319L24.867 20.819C26.677 20.349 27.582 20.114 27.994 19.423C28.406 18.731 28.164 17.853 27.678 16.098L27.164 14.236C26.679 12.48 26.436 11.602 25.724 11.203C25.01 10.803 24.105 11.039 22.295 11.51L20.375 12.008C18.565 12.478 17.66 12.714 17.249 13.406C16.837 14.097 17.079 14.975 17.564 16.73Z"
      fill="#E9B9B6"
    />
  </svg>
)

export default LogisticLimitError
