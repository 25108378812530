import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { ROUTES } from 'consts'
import { useRichFormatMessage } from 'utils'
import StatusScreen from 'components/StatusScreen'
import OrderIcon from './assets/order-icon.svg'

import messages from '../messages'

const NotFound = ({ notifOrderNumber, notifCustomerNo }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const formatRichMessage = useRichFormatMessage()

  const descText = formatRichMessage(messages.notFoundDesc, {
    orderNumber: notifOrderNumber,
    customerNo: notifCustomerNo,
  })

  return (
    <StatusScreen
      iconImg={OrderIcon}
      headerText={formatMessage(messages.notFoundHeader)}
      descText={descText}
      primaryBtnConfig={{
        text: formatMessage(messages.changeLocation),
        handleClick: () => navigate(ROUTES.CHANGE_LOCATION),
      }}
      secondaryBtnConfig={{
        text: formatMessage(messages.home),
        handleClick: () => navigate(ROUTES.DASHBOARD),
      }}
    />
  )
}

export default NotFound
