/* eslint-disable prefer-promise-reject-errors */
import PhoneNumber from 'awesome-phonenumber'
import { omit, intersection, trim } from 'lodash'

import { checkLoginActions } from 'containers/Auth/actions'

import messages from './messages'

const EMAIL_REGEX = new RegExp(
  '^([a-zA-Z0-9_\\-\\.\\+]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
)

export const isEmailValid = email =>
  EMAIL_REGEX.test(String(email).toLowerCase())
const USERNAME_REGEX = /^[a-zA-Z0-9_.\-@]{4,100}$/
export const isUsernameValid = login => USERNAME_REGEX.test(login)

export const validate = values => {
  const errors = {}
  const login = trim(values.get('login') || '')
  const email = values.get('emailAddress') || ''
  const recaptcha = values.get('recaptcha') || ''
  const requiredCheckbox = values.get('agreement_1')
  const phoneNo = values.get('phoneNo')
  const phoneCountryCode = values.getIn(['deliveryCountryCode', 'value'])

  if (login.length) {
    if (!isUsernameValid(login)) {
      errors.login = messages.loginInvalid
    }
  } else {
    errors.login = messages.blank
  }

  if (phoneNo) {
    const pn = new PhoneNumber(phoneNo, phoneCountryCode)
    if (!pn.isValid()) errors.phoneNo = messages.phoneInvalid
  }

  if (!recaptcha.length) {
    errors.recaptcha = messages.required
  }

  if (!isEmailValid(email)) {
    errors.emailAddress = messages.invalidEmail
  }
  if (!requiredCheckbox) {
    errors.agreement_1 = messages.required
  }

  return errors
}

export const scrollToFirstError = errors => {
  const nodesNames = Array.from(document.querySelectorAll('[name]')).map(
    ({ name }) => name,
  )
  const errorsNames = Object.keys(omit(errors, ['recaptcha']))
  const [firstError] = intersection(nodesNames, errorsNames)
  const el = document.querySelector(`[name="${firstError}"]`)

  if (!el) return

  const scrollTop =
    window.scrollY ||
    window.pageYOffset ||
    document.body.scrollTop +
      ((document.documentElement && document.documentElement.scrollTop) || 0)
  const position = el.getBoundingClientRect().top + scrollTop
  const offset = 100

  window.scrollTo({ top: position - offset, behavior: 'smooth' })
}

export const asyncValidate = (values, dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(
      checkLoginActions.delta(
        {
          login: values.get('login'),
        },
        {
          onSuccess: () => resolve(),
          onError: () => reject({ login: messages.loginAlreadyTaken }),
        },
      ),
    )
  })

export const validatePassword = values => {
  const errors = {}
  const password = values.get('password') || ''

  if (!password) {
    errors.password = messages.emptyField
  } else if (password.length < 8) {
    errors.password = messages.tooShort
  }

  return errors
}
